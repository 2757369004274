import React, { useState } from "react";
import Chart from "../../../components/charts/Chart";

const PhosaRecommendation = ({ chartData }) => {
  const colors = [
    "#80cbc333",
    "#80cbc34d",
    "#80cbc366",
    "#80cbc380",
    "#80cbc399",
    "#80cbc3b3",
    "#80cbc3cc",
    "#80cbc3e6",
    "#DAE085",
    "#80cbc3",
  ];
  const chartConfig = {
    chart: {
        type: 'waterfall',
        backgroundColor: 'transparent',
        marginTop: 20,
        plotBackgroundColor: chartData.length ? '#00000000' :'#15151533'
    },

    title: {
      text: "",
    },

    xAxis: {
      type: 'category',
      labels: {
        enabled: false
      },
      plotBands: chartData.map((item, index) => {
        return {
          color: '#15151533',
          from: index - 0.3,
          to: index + 0.3
        }
      })
    },

    yAxis: {
      title: {
          text: '$M',
          align: 'high',
          rotation: 0,
          y: -10,
          x: 15,
          offset: 0,
          style: {
            color: '#fff'
          }
      },
      opposite: true,
      labels: {
        style: {
          color: '#fff'
        }
      },
      gridLineColor: "#ffffff1f",
      tickInterval: 5,
      tickPositions: chartData.length ? undefined : [0, 50, 100, 150, 200]
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      pointFormat: "<b>${point.y:,.2f}</b> USD",
    },
    plotOptions: {
      waterfall: {
        lineColor: "#fff",
        lineWidth: 2,
        dashStyle: "ShortDot",
      },
    },
    series: [
      {
        data: chartData.map((item, index) => {
          return {
            name: item.name,
            y: item.value,
            color: item.name === 'Integrated Operations' ? '#DAE085': colors[index],
            isSum: chartData.length === index + 1 ? true : false
          }
        }),
        dataLabels: {
          enabled: true,
          style: {
            color: "#fff",
            textOutline: "none",
          },
        },
        pointPadding: 0,
        borderColor: "#00000000",
      },
    ],
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };

  return <Chart data={chartConfig} />;
};

export default PhosaRecommendation;
