import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Footer from "../components/Footer/Footer";
import { APP_MAX_WIDTH } from "../constants";

const BasePage = (props) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        padding: "0 30px",
        width: "100%",
        height: "100%"
      }}
    >
      <Box
        sx={{
          maxWidth: `${APP_MAX_WIDTH}px`,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "30px 0",
            flex: "1 0 auto"
          }}
        >
          {props.children && props.children}
        </Box>
        <Footer />
      </Box>
    </Stack>
  );
};

export default BasePage;
