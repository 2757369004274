import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { metricStyles, metricAmountStyles } from "./CardMetrics.styles";
import { gray1 } from "../../constants/style";

export const CardMetrics = ({ metric, metricAmount, metricUnit, tooltip }) => {
  return (
    <Stack sx={{ marginLeft: "10px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <Typography sx={metricStyles}>{metric}</Typography>
        {tooltip && (
          <Tooltip title={tooltip}>
            <IconButton sx={{ margin: "0 0 10px 5px", padding: 0 }}>
              <HelpOutlineIcon
                sx={{ width: "18px", height: "18px", color: gray1 }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Typography sx={metricAmountStyles}>
        {metricAmount} {metricUnit}
      </Typography>
    </Stack>
  );
};
