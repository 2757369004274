import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import SiteConfigProvider from './_shared/context/siteChangeTester.context'
import { BrowserRouter } from 'react-router-dom';
import BaseTheme from './theme/BaseTheme';
import UserInformationProvider from './_shared/context/userInfo.context';
import LoaderProvider from './_shared/context/loader.context';
import TimestampProvider from './_shared/context/timestamp.context ';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserInformationProvider>
    <SiteConfigProvider>
      <LoaderProvider>
        <TimestampProvider>
          <BrowserRouter>
            <BaseTheme>
              <App />
            </BaseTheme>
          </BrowserRouter>
        </TimestampProvider>
      </LoaderProvider>
    </SiteConfigProvider>
  </UserInformationProvider>
);

