import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  cardBorder,
  dataPillBackground,
  gray2,
  gray5,
  lightgreen,
} from "../../../constants/style";
import { getIcon } from "../../../utils";
import ProcessCardDetails from "./ProcessCardDetails";

const ProcessCard = ({ data, renderLine = true, isCoreProcess }) => {
  const optimal = data.optimal_net || data.optimal_gross;
  return (
    <Stack
      sx={{
        position: "relative",
        padding: "0 10px",
        flex: "1 1 auto",
        width: "100%",
      }}
    >
      <Stack
        alignItems="center"
        flex="1 0 auto"
        sx={{
          opacity: 1,
          background: dataPillBackground,
          borderRadius: "20px",
          padding: "10px",
          border: cardBorder,
          zIndex: 1,
        }}
      >
        <ProcessCardDetails data={data} isCoreProcess={isCoreProcess} />
      </Stack>
      <Stack
        sx={{
          padding: "30px 0",
          width: "100%",
          position: "relative",
        }}
        alignItems="center"
      >
        <Box
          sx={{
            border: "3px solid",
            borderColor: optimal ? lightgreen : gray2,
            borderRadius: "50%",
            background: gray5,
            height: "100px",
            width: "100px",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            filter: optimal
              ? `drop-shadow(0px 0px 10px ${lightgreen})`
              : "drop-shadow(0px 0px 10px rgba(147, 147, 147, 0.5))",
          }}
        >
          {optimal
            ? getIcon(data.display_name, 2)
            : getIcon(data.display_name, 4)}
        </Box>
        {renderLine && (
          <span
            style={{
              position: "absolute",
              border: `2px solid ${gray2}`,
              top: "50%",
              left: "50%",
              zIndex: 1,
              transform: "translateY(-50%)",
              width: "100%",
            }}
          ></span>
        )}
      </Stack>
    </Stack>
  );
};

export default ProcessCard;
