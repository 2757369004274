export const totalBusinessRealized = (data, height) => {
  return {
    title: {
      text: "Accumulated operating value",
      style: { color: "white", fontSize: "16px" },
      align: "left",
    },
    chart: {
      height: height,
      backgroundColor: "transparent",
    },
    tooltip: {
      enabled: true,
      borderColor: "#363636",
      backgroundColor: "#363636",
      style :{
        color: "#fff"
      }
    },
    legend: {
      itemStyle: {
        color: "#fff",
      },
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 3,
      align: "center",
    },
    xAxis: {
      title: {
        text: undefined,
      },
      alignTicks: false,
      labels: {
        style: {
          color: "white",
        }
      },
      tickLength: 0,
      categories: data.categories
    },
    yAxis: {
      opposite: true,
      title: {
        text: '$M',
        align: 'high',
        rotation: 0,
        y: -10,
        x: 15,
        offset: 0,
        style: {
          color: '#fff'
        }
      },
      labels: {
        style: {
          color: "white",
        },
      },
      gridLineColor: "#5e616f",
      tickInterval: 5,
      tickPositions: data.withPhosa.length ? undefined : [0, 50, 100, 150, 200]
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0.15,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      }
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Without PHOSA",
        showInLegend: true,
        data: data.withPhosa.length ? data.baseline.map((val, i) => {
          return {'y': val, 'category': data.categories[i]}
        }) : [],
        color: "#FCBB86",
      },
      {
        name: "With PHOSA",
        showInLegend: true,
        data: data.withPhosa.map((val, i) => {
          return {'y': val, 'category': data.categories[i]}
        }),
        color: "#80CBC3",
      }
    ],
  };
};
