import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { GreenArrowUp } from "../../../icons/GreenArrowUp";
import { RedArrowDown } from "../../../icons/RedArrowDown";
import { lightgreen, red, white } from "../../../constants/style";

export const DataPillMetric = ({ item }) => {
  return (
    <>
      <Stack direction="row">
        <Typography
          sx={{
            color: white,
            fontSize: "16px",
            paddingRight: "10px",
            lineHeight: "16px",
          }}
        >
          {item.Display_Name}
        </Typography>
        {item.Metric_Status ? (
          item.Metric_Status === "Good" ? (
            <GreenArrowUp />
          ) : (
            <RedArrowDown />
          )
        ) : null}
      </Stack>
      <Typography
        sx={{
          color: white,
          fontWeight: 700,
          fontSize: "25px",
          lineHeight: "28px",
          paddingRight: "10px",
        }}
      >
        {item.Metric_Value} {item.Display_UOM}
      </Typography>
      <Typography
        sx={{
          fontSize: "10px",
          lineHeight: "13px",
          color: item.Metric_Status === "Good" ? lightgreen : red,
        }}
      >
        {item.Metric_Status_Message}
      </Typography>
    </>
  );
};

const DataPill = ({ data }) => {
  return (
    <Stack
      sx={{
        background: "#2e2f37",
        borderRadius: "10px",
        padding: "5px",
        flex: "1 1 auto",
      }}
    >
      <Box
        sx={{
          borderRadius: "6px",
          padding: "6px 10px",
          background:
            "linear-gradient(180deg, #F2F3F6 37.5%, #DDDEE2 51.73%, #A6ABC1 122.86%)",
        }}
      >
        <Typography
          sx={{ color: "#1e1e1e", fontSize: "16px", fontWeight: 700 }}
        >
          PHOSA recommendation
        </Typography>
      </Box>
      <Stack direction="row" sx={{ alignItems: "center", padding: "18px" }}>
        {data.Metrics.map((item, index) => {
          return (
            <Stack key={index} sx={{ flex: "1 1 auto" }}>
              <DataPillMetric item={item} />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DataPill;
