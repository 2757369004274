import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TopStatus from "./TopStatus.jsx";
import {
  CardMetrics,
  CardTitle,
  StatusCard,
} from "../../components/StatusCard";
import { DataTable, RateCell } from "../../components/DataTable";
import { CardGrid, CardGridItem } from "../../components/CardGrid";
import { getLastUpdateDate, getIcon } from "../../utils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { appConstants } from "../../_shared/config/app.config";
import networkService from "../../_shared/services/network.service";

const getRows = (data) => {
  let formattedRows = [];
  let leftColumn = data["Overall"]["Columns"]["LeftColName"];
  let rightColumn = data["Overall"]["Columns"]["RightColName"];

  for (let i = 0; i < data["Detail"].length; i++) {
    let newRow = {};

    newRow.id = i;
    newRow[leftColumn] = data["Detail"][i]["Name"];
    newRow[rightColumn] =
      data["Detail"][i]["Metric"]["Metric Value"] +
      data["Detail"][i]["Metric"]["Metric Units"];
    newRow.status = data["Detail"][i]["Status"];
    newRow.units = data["Detail"][i]["Metric"]["Metric Units"];
    formattedRows.push(newRow);
  }

  return formattedRows;
};

const renderRateCell = (data) => {
  const rateCellProps = {
    value: data.value,
    units: data.row.units,
    status: data.row.status,
  };
  return <RateCell {...rateCellProps} />;
};

const getColumns = (data) => {
  if (!data && !data["Overall"] && !data["Overall"]["Columns"]) return;
  const columnData = Object.values(data["Overall"]["Columns"]);
  let formattedColumns = [];

  for (let i = 0; i < columnData.length; i++) {
    const newColumn = {};
    newColumn.field = columnData[i] ? columnData[i] : "null";
    newColumn.headerName = columnData[i] ? columnData[i] : " ";
    newColumn.sortable = false;

    if (i !== 0) {
      newColumn.renderCell = (data) => renderRateCell(data, i);
    }

    formattedColumns.push(newColumn);
  }

  return formattedColumns;
};

const MillStatus = () => {
  const [data, setData] = useState(undefined);
  const [site, setActiveSite] = React.useState("");
  const [sites, setSites] = React.useState([]);

  const handleChange = (event) => {
    setActiveSite(event.target.value);
    getSummary(event.target.value);
  };

  const getSummary = (site = "Bihar-India") => {
    const url = appConstants.urls.getMillStatus + `?site-name=${site}`;
    networkService.get(url).then(
      (response) => {
        setData(response);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getSites = () => {
    const url = appConstants.urls.getSites;
    networkService.get(url).then(
      (response) => {
        setSites(response.data);
        setActiveSite(response.data[0].site_name);
        getSummary(response.data[0].site_name);
      },
      (error) => {
        getSummary();
        console.error(error);
      }
    );
  };

  useEffect(() => {
    getSites();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <TopStatus />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0 22px 0 ",
        }}
      >
        <span>Operations Status</span>
        <div className="d-flex flex-row align-items-center">
          {site && (
            <Box sx={{ width: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select site
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={site}
                  label="Select site"
                  onChange={handleChange}
                >
                  {sites.map((item) => {
                    return (
                      <MenuItem key={item.site_name} value={item.site_name}>
                        {item.site_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          )}
          <span
            style={{
              color: "#15806B",
              textDecorationLine: "underline",
              marginLeft: "20px",
            }}
          >
            Status Symbol Legends
          </span>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CardGrid>
          {data &&
            data.data[0]["Mill Status Summary"]["Systems"].map(
              (item, index) => {
                if (index === 0) return undefined;
                return (
                  <CardGridItem key={index}>
                    <StatusCard>
                      <CardTitle
                        name={item["Name"]}
                        icon={getIcon(item["Name"])}
                      />
                      <CardMetrics
                        metric={
                          item["Status"]["Overall"]["Metric"]["Metric Name"]
                        }
                        metricAmount={
                          item["Status"]["Overall"]["Metric"]["Metric Value"]
                        }
                        metricUnit={
                          item["Status"]["Overall"]["Metric"]["Metric Units"]
                        }
                      />
                      <DataTable
                        rows={getRows(item["Status"])}
                        columns={getColumns(item["Status"])}
                      />
                    </StatusCard>
                  </CardGridItem>
                );
              }
            )}
        </CardGrid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "32px 0 22px 0 ",
        }}
      >
        <span>Last Update: {getLastUpdateDate()}</span>
      </Box>
    </>
  );
};

export default MillStatus;
