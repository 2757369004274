import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { CardTitle, StatusCard } from "../../../components/StatusCard";
import { gray1, gray4, white } from "../../../constants/style";
import { getIcon } from "../../../utils";

const UpstreamCard = ({ data }) => {
  return (
    <StatusCard cardStyles={{ background: gray4, padding: "10px" }}>
      <Stack
        sx={{
          justifyContent: "space-between",
          flexGrow: 1,
          padding: "10px",
        }}
        divider={
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ background: "#D0D0D0" }}
          />
        }
      >
        <CardTitle name="Upstream" icon={getIcon("Upstream")} />
        {data.map((key, index) => {
          return (
            <Box key={index}>
              <Typography
                sx={{ fontSize: "14px", lineHeight: "15px", color: gray1 }}
              >
                {key["display_name"]}
              </Typography>
              <Typography
                sx={{ fontSize: "25px", fontWeight: 700, color: white }}
              >
                {key["display_name"].toLowerCase() === "hardness"
                  ? key["attribute_value"].toFixed(2)
                  : key["attribute_value"].toLocaleString()}{" "}
                {key["display_uom"]}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </StatusCard>
  );
};

export default UpstreamCard;
