import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const DataPoint = ({
  containerStyles,
  label,
  labelStyles,
  value,
  valueStyles,
  metric,
  metricStyles,
}) => {
  return (
    <Stack sx={{ ...containerStyles }}>
      <Typography sx={{ ...labelStyles }}>{label}</Typography>
      <Stack direction="row">
        <Typography sx={{ ...valueStyles }}>{value}</Typography>
        <Typography sx={{ ...metricStyles }}>{metric}</Typography>
      </Stack>
    </Stack>
  );
};

export default DataPoint;
