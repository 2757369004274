import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { gray2, smallBorderRadius } from "../../../constants/style";

const ProcessValueFPV = ({ value, valueLabel, children, backgroundColor, fontColor }) => {

  const getBackgroundColor = () => {
    if (backgroundColor) {
      return backgroundColor;
    } else {
      return "linear-gradient(180deg, #F2F3F6 37.5%, #DDDEE2 51.73%, #A6ABC1 122.86%)";
    }
  }

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          textAlign: "center",
          background: getBackgroundColor(),
          border: `3px solid ${gray2}`,
          borderRadius: smallBorderRadius,
          padding: "5px 5px",
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>{valueLabel}</Typography>
        <Typography sx={{ fontSize: "24px", fontWeight: 700, color: fontColor }}>
          {value}
        </Typography>
      </Stack>
      {children && children}
    </>
  );
};

export default ProcessValueFPV;
