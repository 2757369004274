import React from "react";
import Chip from "@mui/material/Chip";
import { black, smallBorderRadius } from "../../../constants/style";

const colorMap = {
  Good: {
    background: "#EEF7F0",
    color: "#008215",
  },
  Warning: {
    background: "#FDF5E9",
    color: "#A26100",
  },
  Warn: {
    background: "#FDF5E9",
    color: "#A26100",
  },
  Issue: {
    background: "#FDECEC",
    color: "#E20909",
  },
  Green: {
    background: "#EEF7F0",
    color: "#008215",
  },
  Yellow: {
    background: "#FDF5E9",
    color: "#A26100",
  },
  Red: {
    background: "#FDECEC",
    color: "#E20909",
  },
  Error: {
    background: "#FDECEC",
    color: "#E20909",
  },
  Gray: {
    background: "#FDECEC",
    color: "#E20909",
  },
  Limit: {
    background: "#FDECEC",
    color: "#E20909",
  },
  null: {
    background: "#FDECEC",
    color: "#E20909",
  },
  "No Data": {
    background: "#FDECEC",
    color: "#E20909",
  },
  default: {
    color: black,
    background: "transparent",
  },
};

const getValue = (value, units = "") => {
  return value;
};

export const RateCell = (props) => {
  const { value, units, status = "default" } = props;

  // if(status === 'Limit') {
  //   return <div>Limit <PriorityHighIcon sx={{color: 'red'}}/></div>;
  // }

  // if(status === 'null') {
  //   return <div><WarningAmberIcon /> <span>N/A</span></div>;
  // }

  return (
    <Chip
      label={getValue(value, units)}
      sx={{
        borderRadius: smallBorderRadius,
        padding: "5px 10px",
        background: colorMap[status].background,
        color: colorMap[status].color,
        "& .MuiChip-label": { padding: 0 },
      }}
    />
  );
};
