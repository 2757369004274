import React from "react";
import {
  cardBorder,
  dataPillBackground,
  gray1,
  gray2,
  gray5,
  gray4,
  IntenseRed1,
  IntenseRed2,
  IntenseRed3,
  lightgreen,
  smallBorderRadius,
  white,
} from "../../../constants/style";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getIcon } from "../../../utils";
import ProcessValueFPV from "./ProcessValue_AS";

const colorMap = {
  1: IntenseRed1,
  2: IntenseRed2,
  3: IntenseRed3,
};

const stringSwitcher = (string) => {
  if (string.toLowerCase().match(new RegExp("integ"))) {
    return "Integrated operations";
  }

  return string;
};

const ProcessCardAS = ({ data, renderLine = true, isReadOnly }) => {
  const getRefinedValue = (val) => {
    // if (val > 9999) {
    //   return `$${val/1000000}M`
    // }
    return `$${val}M`;
  };

  const switchFieldStyles = {
    ".MuiSwitch-track": {
      backgroundColor: "#9E9E9E",
    },
    ".MuiSwitch-thumb": {
      backgroundColor: "#fff",
    },
    ".MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
      backgroundColor: "#80CBC3",
    },
    ".MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#5A8182",
    },
  };

  const getValue = () => {
    return !data.potential_value
      ? getRefinedValue(0)
      : getRefinedValue(data.potential_value);
  };

  const getValueColor = () => {
    return data.potential_value ? "#000000" : "#9E9E9E";
  };

  const getBackgroundColor = () => {
    return data.name === "Integrated planning"
      ? "linear-gradient(180deg, #FAFCDA 37.5%, #F6FAC9 51.73%, #DAE085 122.86%)"
      : null;
  };

  return (
    <Stack sx={{ position: "relative", padding: "0 10px", width: "100%" }}>
      <Stack
        alignItems="center"
        flex="1 0 auto"
        sx={{
          opacity: 1,
          background: dataPillBackground,
          borderRadius: "25px",
          padding: "10px",
          border: cardBorder,
          zIndex: 1,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          gap="5px"
          sx={{
            background: gray4,
            borderRadius: smallBorderRadius,
            padding: "5px 5px",
            width: "100%",
            flex: "1 0 auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: gray1,
            }}
          >
            Value captured
          </Typography>
          {isReadOnly && (
            <Typography
              sx={{
                fontSize: "24px",
                color: white,
                fontWeight: 700,
              }}
            >
              {getRefinedValue(data.value_Captured)}
            </Typography>
          )}
          {data.is_phosa_activated && !isReadOnly && (
            <Typography
              sx={{
                fontSize: "24px",
                color: white,
                fontWeight: 700,
              }}
            >
              {getRefinedValue(data.value_Captured)}
            </Typography>
          )}
          {!data.is_phosa_activated && !isReadOnly && (
            <Typography
              sx={{
                fontSize: "14px",
                color: lightgreen,
                textAlign: "center",
              }}
            >
              PHOSA not yet activated
            </Typography>
          )}
        </Stack>
        <ProcessValueFPV
          value={getValue()}
          fontColor={getValueColor()}
          backgroundColor={getBackgroundColor()}
          valueLabel={
            data.is_phosa_activated ? "Remaining potential" : "Potential value"
          }
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: gray1,
              textAlign: "center",
              marginTop: "10px",
              lineHeight: "18px",
            }}
          >
            {stringSwitcher(data.name)}
          </Typography>
        </ProcessValueFPV>
      </Stack>
      <Stack
        sx={{
          padding: "30px 0",
          width: "100%",
          position: "relative",
        }}
        alignItems="center"
      >
        <Box
          sx={{
            border: "3px solid",
            borderColor: data.bottleNeckIntensity
              ? `${colorMap[data.bottleNeckIntensity]}`
              : data.name === "Integrated planning" && data.potential_value
              ? "#DAE085"
              : "#626579",
            borderRadius: "50%",
            background: gray5,
            height: "100px",
            width: "100px",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 0px 10px rgba(147, 147, 147, 0.5)",
          }}
        >
          {getIcon(data.name, 2)}
        </Box>
        {renderLine && (
          <span
            style={{
              position: "absolute",
              border: `2px solid ${gray2}`,
              top: "50%",
              left: "50%",
              zIndex: 1,
              transform: "translateY(-50%)",
              width: "100%",
            }}
          ></span>
        )}
      </Stack>
    </Stack>
  );
};

export default ProcessCardAS;
