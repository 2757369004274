import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { white } from "../../constants/style";
import { Pill } from "../../components/Pill";
import TopSummary from "./components/TopSummary";
import assetPerfData from "../../mockData/asset_performance.json";
import Card from "./components/Card";
import Chart from "../../components/charts/Chart";
import { copperProd } from "./GraphConfigs/CopperProductionConfig";
import { copperProdForecast } from "./GraphConfigs/CopperProductionForecastConfig";
import { getStatusColor, reflowHighcharts } from "../../utils";
import GraphTitle from "./components/GraphTitle";
import "./chartStyles.scss";
import ViewTopBar from "../../components/ViewTopBar/ViewTopBar";

const AssetPerformance = () => {
  const [graphWidth, setGraphWidth] = useState(0);
  const lossMetricRef = useRef();
  const graphOne = assetPerfData.data.Graphs[0];
  const graphTwo = assetPerfData.data.Graphs[1];

  useEffect(() => {
    window.addEventListener("resize", reflowHighcharts);
    if (lossMetricRef) {
      setGraphWidth(lossMetricRef.current.offsetWidth);
    }
  }, []);

  return (
    <Grid
      className="AssetPerformance"
      container
      columns={20}
      spacing="20px"
      sx={{ width: "100%" }}
    >
      <Grid item xs={20}>
        <ViewTopBar>
          <Typography
            sx={{
              color: white,
              fontSize: "21px",
              fontWeight: 700,
              marginRight: "5px",
            }}
          >
            {assetPerfData.data.Display_Name}
          </Typography>
          <Typography sx={{ color: white, fontSize: "14px" }}>
            ({assetPerfData.data.Display_Detail})
          </Typography>
        </ViewTopBar>
      </Grid>
      <Grid item xs={20}>
        <TopSummary data={assetPerfData.data} />
      </Grid>
      <Grid item xs={10}>
        <Pill pillStyles={{ padding: "20px" }}>
          <Stack>
            <GraphTitle data={graphOne} />
            <Stack direction="row" ref={lossMetricRef}>
              <Stack
                sx={{
                  width: "max-content",
                  flex: "0 0 auto",
                  maxWidth: "110px",
                }}
              >
                <Typography
                  sx={{
                    color: white,
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  {graphOne.Totals.Display_Name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "33px",
                    fontWeight: 700,
                    lineHeight: "33px",
                    color: getStatusColor(graphOne.Totals.Status).color,
                  }}
                >
                  {graphOne.Totals.Metric_Value}
                </Typography>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: `calc(100% - ${graphWidth}px)`,
                  flex: "1 1 auto",
                }}
              >
                <div style={{ width: "100%", height: "235px" }}>
                  <Chart
                    data={copperProdForecast(
                      235,
                      graphOne.Series,
                      graphOne.Target.Metric_Value
                    )}
                  />
                </div>
              </Box>
            </Stack>
          </Stack>
        </Pill>
      </Grid>
      <Grid item xs={10}>
        <Pill pillStyles={{ padding: "20px" }}>
          <Stack>
            <GraphTitle data={graphTwo} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flex: "1 1 auto",
              }}
            >
              <div style={{ width: "100%", height: "235px" }}>
                <Chart data={copperProd(235, graphTwo.Series)} />
              </div>
            </Box>
          </Stack>
        </Pill>
      </Grid>
      {assetPerfData.data.Cards.map((item, index) => {
        return (
          <Grid item xs={6.6666666667} key={index}>
            <Pill
              pillStyles={{
                ...(item.Header === "Environment" && {
                  background: "#232631",
                }),
                height: "100%",
              }}
            >
              <Card
                header={item.Header}
                subheader={item.Subheader}
                loss={item.Inefficiency_Loss}
                downTime={item["Downtime Summary"]}
                emissions={item["Emissions"]}
                tooltip={item.Tooltip}
                drillDownLink={item.Drilldown_Location}
              />
            </Pill>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AssetPerformance;
