import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { white } from "../../../constants/style";
import Chart from "../../../components/charts/Chart";
import { horizontalColumnConfig, rangedColumnConfig } from "../GraphConfigs";
import { DataPillMetric } from "./DataPill";
import Divider from "../../../components/Divider/Divider";

const DetailSection = ({ data }) => {
  const [height, setHeight] = useState(100);
  const chartContainerRef = useRef();
  const isMetricsSection = data.hasOwnProperty("Metrics");

  useEffect(() => {
    if (chartContainerRef && chartContainerRef.current) {
      setHeight(chartContainerRef.current.offsetHeight);
    }
  }, []);

  return (
    <Stack
      sx={{
        flex: "0 1 auto",
        width: "100%",
      }}
    >
      <Typography sx={{ color: white }}>{data.Display_Name}</Typography>
      {isMetricsSection ? (
        <Stack
          direction="row"
          sx={{
            marginTop: "20px",
            flexGrow: 1,
            justifyContent: "space-around",
          }}
        >
          {data.Metrics.map((item, index) => {
            return (
              <Stack key={index}>
                <DataPillMetric item={item} />
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <Stack
          direction="row"
          ref={chartContainerRef}
          sx={{ width: "100%", alignItems: "center" }}
        >
          {data.Total_Loss && (
            <Stack sx={{ minWidth: "80px" }}>
              <Typography sx={{ color: white }}>Total Loss</Typography>
              <Stack direction="row" sx={{ alignItems: "flex-end" }}>
                <Typography
                  sx={{ color: white, fontSize: "25px", fontWeight: 700 }}
                >
                  {data.Total_Loss.Metric_Value}
                </Typography>
                <Typography
                  sx={{ color: white, fontWeight: 700, marginBottom: "5px" }}
                >
                  {data.Total_Loss.Display_UOM}
                </Typography>
              </Stack>
            </Stack>
          )}
          <Box sx={{ width: "100%" }}>
            {data.Graph.Type === "bar" ? (
              <Chart data={horizontalColumnConfig(height, data.Graph)} />
            ) : (
              <Chart data={rangedColumnConfig(height, data.Graph)} />
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

const AccordionDetails = ({ data }) => {
  return (
    <Stack
      sx={{
        background: "#1E1E1E",
        padding: "10px 15px",
      }}
      divider={
        <Divider orientation="vertical" dividerStyles={{ margin: "0 20px" }} />
      }
    >
      <Stack
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            dividerStyles={{ margin: "0 20px" }}
          />
        }
      >
        {data.Details.map((item, index) => {
          return <DetailSection data={item} key={index} />;
        })}
      </Stack>
    </Stack>
  );
};

export default AccordionDetails;
