// Color Palette
export const white = "#FFFFFF";
export const black = "#000000";

export const magenta = "#DC267F";
export const azul = "#5984FF";
export const gold = "#FFB000";
export const green = "#3ADF95";
export const lightgreen = "#80CBC3";
export const red = "#EF8F8F";

export const gray1 = "#B3B4BA";
export const gray2 = "#626579";
export const gray3 = "#525566";
export const gray4 = "#292B37";
export const gray5 = "#191D24";
export const gray6 = "#12171D";
export const gray7 = "#8d8f97";
export const gray8 = "#1E1E1E";

export const yellow1 = "#DAE085";

export const purple1 = "#BB86FC";

export const IntenseRed1 = "#700000";
export const IntenseRed2 = "#990000";
export const IntenseRed3 = "#FF0000";

// Border Radii
export const largeBorderRadius = "45px";
export const mediumBorderRadius = "30px";
export const smallBorderRadius = "20px";

// Border
export const cardBorder = `1px solid ${gray2}`;
export const warnBorder = `4px solid ${gold}`;
export const successBorder = `4px solid ${green}`;
export const errorBorder = `4px solid ${magenta}`;

// Backgrounds
export const successGradient =
  "linear-gradient(180deg, #3ADF96 0%, #009955 100%)";
export const pageBackground =
  "linear-gradient(122.8deg, #404252 30.4%, #10151B 100.73%)";
export const pillBackground =
  "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)";
export const dataPillBackground =
  "linear-gradient(180deg, rgba(92, 95, 114, 1) 0%, rgba(23, 28, 35, 1) 100%);";
export const reverseDataPillBackground =
  "linear-gradient(180deg, #292B37 0%, rgba(41, 43, 55, 0) 174.67%);";
export const smallDataPillBackground =
  "linear-gradient(180deg, #F2F3F6 37.5%, #E7E7E7 51.73%, #E3E7F7 122.86%);";
export const statusCardBackground =
  "linear-gradient(180deg, rgba(92, 95, 114, 0.8) 0%, rgba(16, 21, 27, 0.8) 100%)";
export const reverseStatusCardBackground =
  "linear-gradient(147.5deg, #292B37 19.46%, rgba(41, 43, 55, 0) 135.3%)";

// Box Shadows
export const elevation1 = "0px 10px 10px rgba(0, 0, 0, 0.1)";

// Drawer States
export const drawerIdle =
  "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)";
export const drawerActive =
  "linear-gradient(180deg, #3C3E48 0%, rgba(41, 43, 55, 0) 154.27%)";
export const drawerHover =
  "linear-gradient(180deg, #FBFBFB 0%, #5C5F72 20.09%, rgba(41, 43, 55, 0) 154.27%)";

// Tab States (large)
export const tabIdle = drawerIdle;
export const tabActive =
  "linear-gradient(180deg, #8A8D9F 0%, rgba(41, 43, 55, 0) 89.99%)";
export const tabHover =
  "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0.99) 154.27%)";

// Tab States (small)
export const tabSmallIdle =
  "linear-gradient(180deg, rgba(41, 43, 55, 0) 48.33%, #757787 100%)";
export const tabSmallActive =
  "linear-gradient(174.66deg, rgba(41, 43, 55, 0) 4.27%, #A6ABC1 95.73%)";
export const tabSmallHover =
  "linear-gradient(180deg, rgba(41, 43, 55, 0.49) 0%, #757787 100%)";
