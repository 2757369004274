import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { white } from "../../../constants/style";

const GridRow = ({ children, rowTitle, rowTitleStyles, gridItemStyles }) => {
  return (
    <Grid container sx={{ padding: "15px", alignItems: "center" }}>
      <Grid
        item
        sx={{
          marginRight: "32px",
          ...gridItemStyles,
        }}
      >
        <Typography sx={{ color: white, fontSize: "21px", ...rowTitleStyles }}>
          {rowTitle}
        </Typography>
      </Grid>
      {children && children}
    </Grid>
  );
};

export default GridRow;
