import { white } from "../../../constants/style";
const baseConfiguration = {
  data: [
    {
      color: "rgba(218, 224, 133, 0.5)",
      borderColor: "rgba(218, 224, 133, 0.5)",
    },
    {
      color: "rgba(218, 224, 133, 0.75)",
      borderColor: "rgba(218, 224, 133, 0.75)",
    },
    {
      color: "#DAE085",
      borderColor: "#DAE085",
    },
    { color: "#DAE085", borderColor: "#DAE085" },
  ],
  categories: ["Gross", "Net", "Potential gain", "Phosa net optimal"],
};

const transformDataPoints = (dataPoints) => {
  return baseConfiguration.data.map((point, index) => {
    const shouldInsertCustomDataLabel =
      index === baseConfiguration.data.length - 2;
    let difference;

    if (shouldInsertCustomDataLabel) {
      difference = Math.abs(
        dataPoints[dataPoints.length - 2][1] - dataPoints[index - 1][1]
      );
    }

    return {
      ...point,
      low: dataPoints[index][0],
      high: dataPoints[index][1],
      itemStyle: {
        color: "red",
      },
      ...(shouldInsertCustomDataLabel && {
        dataLabels: {
          formatter: function () {
            return difference;
          },
        },
      }),
    };
  });
};

export const rangedColumnConfig = (height, data) => {
  return {
    chart: {
      height: height,
      backgroundColor: "transparent",
      type: "columnrange",
    },

    title: {
      text: "",
    },

    xAxis: {
      categories: baseConfiguration.categories,
      lineColor: "#bbbbbb",
      labels: {
        style: {
          color: white,
        },
        formatter: function () {
          if (baseConfiguration.categories.indexOf(this.value) > 1) {
            return `<span style="font-weight: 700">${this.value}</span>`;
          }
          return this.value;
        },
      },
    },

    yAxis: {
      title: {
        text: "",
      },
      opposite: true,
      tickPositions: data.yAxis.tickPositions,
      gridLineColor: "#323232",
      labels: {
        format: "{value}",
        style: {
          color: white,
        },
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          color: white,
          style: {
            textOutline: 0,
          },
        },
        maxPointWidth: 70,
      },
      enableMouseTracking: false,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        showInLegend: true,
        data: transformDataPoints(data.Data),
        color: "#DAE085",
        borderColor: "#DAE085",
      },
    ],
  };
};
