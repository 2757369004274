import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

const Error = ({message, buttonLabel, redirectTo}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(redirectTo);
  }

  return(
    <Stack gap="20px" alignItems="center" sx={{width: "100%", height: "100%", paddingTop: "150px" }}>
      <Typography sx={{color:"#fff", fontSize: "32px"}}>{message}</Typography>
      <Button
        onClick={handleClick}
        variant="outlined"
        sx={{backgroundColor: '#fff6', borderRadius: '8x', color: '#151515', textTransform: 'capitalize', border: 'none', fontWeight: '700', ':hover': {border: 'none', backgroundColor: '#fff6'}}}
      >
        {buttonLabel}
      </Button>
    </Stack>
  )
};

export default Error;
