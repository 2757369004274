import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { white } from "../../../constants/style";
import { Pill } from "../../../components/Pill";
import StackSection from "./StackSection";
import DataPill from "./DataPill";

const TopSummary = (props) => {
  return (
    <Pill>
      <Stack
        direction="row"
        sx={{
          padding: "15px 20px",
        }}
      >
        <Stack
          sx={{
            flex: "0 1 auto",
            width: "100%",
          }}
        >
          <Typography sx={{ color: white, margin: "10px 0", fontWeight: 700 }}>
            Throughput rate
          </Typography>
          <StackSection data={props["Throughput Rate"]} />
        </Stack>
        <Box
          sx={{
            flex: "0 1 auto",
            width: "100%",
            marginLeft: "30px",
          }}
        >
          <DataPill data={props["Recommendation"]} />
        </Box>
        <Stack
          sx={{
            flex: "0 1 auto",
            width: "100%",
            marginLeft: "30px",
          }}
        >
          <Typography sx={{ color: white, margin: "10px 0", fontWeight: 700 }}>
            Loss due to downtime
          </Typography>
          <StackSection data={props["Loss"]} />
        </Stack>
      </Stack>
    </Pill>
  );
};

export default TopSummary;
