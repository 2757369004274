import React from "react";
import Grid from "@mui/material/Grid";

export const CardGridItem = ({ children, gridItemStyles }) => {
  return (
    <Grid item sx={{ ...gridItemStyles }}>
      {children && children}
    </Grid>
  );
};
