import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const GreenArrowUp = ({ width = 18, height = 15 }) => {
  return (
    <MuiIconWrapper size={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 0L17.6603 15L0.339746 15L9 0Z" fill="#80CBC3" />
      </svg>
    </MuiIconWrapper>
  );
};
