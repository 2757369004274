import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const IntegratedDim = ({ width = 60, height = 60 }) => {
  return (
    <MuiIconWrapper size={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="-0.5"
          x2="13.1271"
          y2="-0.5"
          transform="matrix(-0.12815 -0.991755 0.991443 -0.130539 30.2803 58.8682)"
          stroke="url(#paint0_linear_2723_13462)"
          strokeOpacity="0.8"
        />
        <path
          d="M49.0656 50.6604L30.5609 59.151L6.72916 46.6982M49.0656 50.6604L56.3553 41.6038M49.0656 50.6604L28.5983 46.6982M56.3553 41.6038L58.8787 28.302M56.3553 41.6038L46.8226 32.8303M56.3553 41.6038L28.5983 46.6982M58.8787 28.302L52.9908 11.8869M58.8787 28.302L46.8226 32.8303M52.9908 11.8869L38.2119 1.30835M52.9908 11.8869L46.8226 32.8303M52.9908 11.8869L37.5703 9.05667M38.2119 1.30835L23.2712 0.849121M38.2119 1.30835L19.9067 9.05667M38.2119 1.30835L37.5703 9.05667M23.2712 0.849121L5.88804 13.868M23.2712 0.849121L19.9067 9.05667M5.88804 13.868L0.841309 29.434M5.88804 13.868L19.9067 25.4718M5.88804 13.868L19.9067 9.05667M0.841309 29.434L6.72916 46.6982M0.841309 29.434L19.9067 25.4718M0.841309 29.434L21.589 43.3019L28.5983 46.6982M6.72916 46.6982H28.5983M28.5983 46.6982L46.8226 32.8303M28.5983 46.6982L19.9067 25.4718M46.8226 32.8303L37.5703 9.05667M46.8226 32.8303L19.9067 25.4718M19.9067 25.4718V9.05667M19.9067 25.4718L37.5703 9.05667M19.9067 9.05667H37.5703"
          stroke="url(#paint1_linear_2723_13462)"
          strokeOpacity="0.8"
        />
        <path
          d="M48.8458 32.8301C48.8458 33.8088 48.0611 34.5942 47.1029 34.5942C46.1446 34.5942 45.3599 33.8088 45.3599 32.8301C45.3599 31.8513 46.1446 31.0659 47.1029 31.0659C48.0611 31.0659 48.8458 31.8513 48.8458 32.8301Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M48.8458 32.8301C48.8458 33.8088 48.0611 34.5942 47.1029 34.5942C46.1446 34.5942 45.3599 33.8088 45.3599 32.8301C45.3599 31.8513 46.1446 31.0659 47.1029 31.0659C48.0611 31.0659 48.8458 31.8513 48.8458 32.8301Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M59.4998 28.302C59.4998 28.6555 59.2172 28.9341 58.8783 28.9341C58.5395 28.9341 58.2568 28.6555 58.2568 28.302C58.2568 27.9485 58.5395 27.6699 58.8783 27.6699C59.2172 27.6699 59.4998 27.9485 59.4998 28.302Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M23.6122 1.13208C23.6122 1.4856 23.3295 1.76415 22.9907 1.76415C22.6518 1.76415 22.3692 1.4856 22.3692 1.13208C22.3692 0.778553 22.6518 0.5 22.9907 0.5C23.3295 0.5 23.6122 0.778553 23.6122 1.13208Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M20.8084 9.05639C20.8084 9.40991 20.5258 9.68847 20.1869 9.68847C19.8481 9.68847 19.5654 9.40991 19.5654 9.05639C19.5654 8.70287 19.8481 8.42432 20.1869 8.42432C20.5258 8.42432 20.8084 8.70287 20.8084 9.05639Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M1.74299 28.8679C1.74299 29.2214 1.46032 29.5 1.1215 29.5C0.782669 29.5 0.5 29.2214 0.5 28.8679C0.5 28.5144 0.782669 28.2358 1.1215 28.2358C1.46032 28.2358 1.74299 28.5144 1.74299 28.8679Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M30.9017 58.8679C30.9017 59.2214 30.619 59.5 30.2802 59.5C29.9414 59.5 29.6587 59.2214 29.6587 58.8679C29.6587 58.5144 29.9414 58.2358 30.2802 58.2358C30.619 58.2358 30.9017 58.5144 30.9017 58.8679Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M38.7523 1.69799C38.7523 2.05152 38.4696 2.33007 38.1308 2.33007C37.7919 2.33007 37.5093 2.05152 37.5093 1.69799C37.5093 1.34447 37.7919 1.06592 38.1308 1.06592C38.4696 1.06592 38.7523 1.34447 38.7523 1.69799Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M20.8084 24.9055C20.8084 25.259 20.5258 25.5376 20.1869 25.5376C19.8481 25.5376 19.5654 25.259 19.5654 24.9055C19.5654 24.552 19.8481 24.2734 20.1869 24.2734C20.5258 24.2734 20.8084 24.552 20.8084 24.9055Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M49.9671 50.3772C49.9671 50.7307 49.6844 51.0093 49.3456 51.0093C49.0068 51.0093 48.7241 50.7307 48.7241 50.3772C48.7241 50.0237 49.0068 49.7451 49.3456 49.7451C49.6844 49.7451 49.9671 50.0237 49.9671 50.3772Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M57.2572 41.3206C57.2572 41.6741 56.9745 41.9526 56.6357 41.9526C56.2968 41.9526 56.0142 41.6741 56.0142 41.3206C56.0142 40.967 56.2968 40.6885 56.6357 40.6885C56.9745 40.6885 57.2572 40.967 57.2572 41.3206Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M8.47182 46.9809C8.47182 47.9597 7.68704 48.7451 6.72883 48.7451C5.77062 48.7451 4.98584 47.9597 4.98584 46.9809C4.98584 46.0022 5.77062 45.2168 6.72883 45.2168C7.68704 45.2168 8.47182 46.0022 8.47182 46.9809Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M55.0138 11.3208C55.0138 12.2995 54.229 13.0849 53.2708 13.0849C52.3126 13.0849 51.5278 12.2995 51.5278 11.3208C51.5278 10.342 52.3126 9.55664 53.2708 9.55664C54.229 9.55664 55.0138 10.342 55.0138 11.3208Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M7.35024 14.1509C7.35024 15.1296 6.56546 15.915 5.60725 15.915C4.64904 15.915 3.86426 15.1296 3.86426 14.1509C3.86426 13.1721 4.64904 12.3867 5.60725 12.3867C6.56546 12.3867 7.35024 13.1721 7.35024 14.1509Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M39.3131 9.62255C39.3131 10.6013 38.5284 11.3867 37.5701 11.3867C36.6119 11.3867 35.8271 10.6013 35.8271 9.62255C35.8271 8.6438 36.6119 7.8584 37.5701 7.8584C38.5284 7.8584 39.3131 8.6438 39.3131 9.62255Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M30.9019 46.4151C30.9019 47.7065 29.8661 48.7453 28.5982 48.7453C27.3303 48.7453 26.2944 47.7065 26.2944 46.4151C26.2944 45.1238 27.3303 44.085 28.5982 44.085C29.8661 44.085 30.9019 45.1238 30.9019 46.4151Z"
          fill="#626579"
          stroke="#626579"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2723_13462"
            x1="6.56355"
            y1="0"
            x2="6.56355"
            y2="1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5C5F72" />
            <stop offset="1" stopColor="#10151B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2723_13462"
            x1="29.86"
            y1="0.849121"
            x2="29.86"
            y2="59.151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5C5F72" />
            <stop offset="1" stopColor="#10151B" />
          </linearGradient>
        </defs>
      </svg>
    </MuiIconWrapper>
  );
};
