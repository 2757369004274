import React, { createContext, useContext, useState } from 'react';

const defaultValue = null;

export const UserInfo = createContext(defaultValue);

export const useUserInfo = () => useContext(UserInfo);

const UserInformationProvider = ({ children }) => {

  const [userDetails, setUserDetails] = useState(defaultValue);

  const value = {
    userDetails,
    setUserDetails
  }

  return (
    <UserInfo.Provider value={value}>{children && children}</UserInfo.Provider>
  )
}

export default UserInformationProvider;