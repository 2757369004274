import { lightgreen, purple1, white } from "../../../constants/style";

const lineMarker = {
  marker: {
    enabled: true,
    radius: 5,
    fillColor: "#383838",
    lineColor: "#FCBB86",
    lineWidth: 2,
  },
};

const targetMarker = {
  marker: {
    enabled: true,
    symbol:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHrSURBVHgBdVNNSFtBEJ7ZEDzUJ2mh0BQP77UkbT0ltuc29GjbS7GlpbRVT3oQ0aMiqPhz8ODfQUFQD3oTBRERDxr14kW9KQbkKWgieEjQeDCat86s2eQZcGDfzpvdb76Zb1iEIovZPTWA8J/cEC0frZQEjAqZHQpY7VH3XdTOvt1netCZJDcCjxniwJWT6QpbHSn+FS7gmht4eXkNsdi52vMmZfMT9M49YD6wu6cQkUuFROICejpXYHf7JI+p+loBTS2foNQoUSkciS1vrdZBzLHaGthYPwuJ+IUCGXRZM1d+KIeR0WqdLxk0254JDzgRHWE2Bvr9ZTC7UAfLqw0wOf1HMfKZqxofCRsSEhxTR5YW99T+43dYJWALBp9D1ZcKahdgI3roUi8bEm4xjdKSB7tqkFY6fQ3F5nhEUiCIIx0IEAvbxPgWxHN971Cpm+uHNCWA8PvyPFhk8Rhtu8N3g94kB9IkTu3fGYif3gP9L8vy4lUScGSsWldjvzHbXgmLBy6dQQ6yMMOk6MfIa8WkgT9/haG3/1uhE8ROdtScmT2D3h36sfQNHtEZje4FCWcYBQ0QcD5gtn5XpfOH2R0pPnM5OY0UgDVwA6WUU2mZqSskKrJ9ehgelP/oiB/GU1opBBm9lXLoXdHDuAPCnMByNeCqJwAAAABJRU5ErkJggg==)",
  },
};

const getDateStrings = (month, days = 0) => {
  let counter = 1;
  let dateStringArray = [];

  while (counter < days) {
    dateStringArray.push(`${month}\\${counter}`);
    counter++;
  }

  return dateStringArray;
};

/**
 * TODO:
 * - Get current month instead of "9"
 */
const tickPositions = [
  "11\\1",
  "11\\7",
  "11\\13",
  "11\\19",
  "11\\25",
  "11\\30",
];

const isToday = (date) => {
  // const current = new Date();
  // const currentDayOfMonth = current.getDate();
  // const currentMonth = current.getMonth() + 1;
  const currentDayOfMonth = "16";
  const currentMonth = "11";
  const dateMonth = date.split("\\")[0];
  const dateDay = date.split("\\")[1];

  if (dateDay === currentDayOfMonth && currentMonth === dateMonth) {
    return true;
  }

  return false;
};

const formatChartData = (data, type, dateStrings) => {
  let dataSet = undefined;

  data.forEach((item) => {
    if (item.Name.toLowerCase().match(new RegExp(type))) {
      dataSet = item.Data;
    }
  });

  const augmentedDataSet = [];

  dateStrings.forEach((date, index) => {
    augmentedDataSet.push({
      y: dataSet[index],
      category: date,
      ...(isToday(date) && type === "historical" && lineMarker),
    });
  });

  return augmentedDataSet;
};

const targetArray = (targetPoint, dateStrings) => {
  const empty = [];

  dateStrings.forEach((date, index) => {
    if (index === dateStrings.length - 1) {
      empty.push({ y: targetPoint, category: date, ...targetMarker });
    } else {
      empty.push({ y: 0, category: date });
    }
  });

  return empty;
};

export const copperProdForecast = (height, data, target) => {
  /**
   * TODO:
   * - Get current month instead passing "9". Keeping for demo sake
   */
  const dateStrings = getDateStrings(11, data[0].Data.length);

  return {
    title: {
      text: undefined,
    },
    chart: {
      height: height || 235,
      backgroundColor: "transparent",
    },
    tooltip: {
      enabled: true,
      borderColor: "#363636",
      backgroundColor: "#363636",
      formatter: function () {
        return `<span style='color: white; display:flex; align-items: center; justify-content: center'><span style='display: block;'>${
          isToday(this.point.category) ? "Today" : "Date"
        }</span></br><span style='display: block;'>${
          this.point.category
        }</span></span>`;
      },
    },
    legend: {
      itemStyle: {
        color: white,
      },
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 3,
      align: "left",
      margin: 20,
    },
    xAxis: {
      title: {
        text: undefined,
      },
      alignTicks: false,
      labels: {
        enabled: true,
        style: {
          color: white,
        },
        formatter: function () {
          if (tickPositions.includes(this.value)) {
            return this.value;
          }
          if (this.isFirst) {
            return this.value;
          }
          if (this.isLast) {
            return this.value;
          }
        },
      },
      tickLength: 0,
      categories: dateStrings,
      plotLines: [
        {
          color: white,
          dashStyle: "dash",
          value: dateStrings.length - 1,
          width: 1,
        },
        {
          color: white,
          dashStyle: "dash",
          value: 0,
          width: 1,
        },
      ],
    },
    yAxis: {
      opposite: true,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          color: white,
        },
      },
      gridLineColor: "#5e616f",
      tickPositions: [0, 4000, 8000, 12000, 16000],
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0.15,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      line: {
        marker: {
          symbol: "circle",
        },
      },
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        name: "PHOSA Optimal Forecast",
        showInLegend: true,
        data: formatChartData(data, "optimal", dateStrings),
        color: lightgreen,
      },
      {
        name: "Forecast",
        showInLegend: true,
        data: [],
        color: purple1,
      },
      {
        name: "Historical",
        showInLegend: true,
        data: formatChartData(data, "historical", dateStrings),
        color: "#FCBB86",
        zoneAxis: "x",
        zones: [
          { value: dateStrings.indexOf("11\\16") },
          { value: data[0].Data.length - 1, color: purple1 },
        ],
      },
      {
        name: "Target",
        showInLegend: true,
        data: targetArray(target, dateStrings),
        color: "#DAE085",
        zoneAxis: "x",
        zones: [
          { value: 0 },
          { value: data[0].Data.length - 1, color: "transparent" },
        ],
      },
    ],
  };
};
