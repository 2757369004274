import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GreenArrowUp } from "../../icons/GreenArrowUp";
import { RedArrowDown } from "../../icons/RedArrowDown";
import { GreenArrowDown } from "../../icons/GreenArrowDown";
import { RedArrowUp } from "../../icons/RedArrowUp";
import { lightgreen, red } from "../../constants/style";

const StatusArrow = ({ flipStatusArrows, status }) => {
  return (
    <>
      {status ? (
        status === "Good" ? (
          flipStatusArrows ? (
            <GreenArrowDown />
          ) : (
            <GreenArrowUp />
          )
        ) : flipStatusArrows ? (
          <RedArrowUp />
        ) : (
          <RedArrowDown />
        )
      ) : null}
    </>
  );
};

const Metric = ({ showSkeleton, data }) => {
  const {
    containerStyles,
    status,
    statusMessage,
    statusMessagStyles,
    showStatusArrow = true,
    statusArrowPosition = "title",
    title,
    titleStyles,
    value,
    valueMetric,
    valueStyles,
    flipStatusArrows = true,
  } = data;

  return (
    <Stack
      sx={{
        width: "100%",
        ...containerStyles,
      }}
    >
      {title && (
        <Stack
          direction="row"
          sx={{ alignItems: "center", marginBottom: "5px" }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "16px",
              paddingRight: "5px",
              ...titleStyles,
            }}
          >
            {title}
          </Typography>
          {showStatusArrow && statusArrowPosition === "title" && (
            <StatusArrow flipStatusArrows={flipStatusArrows} status={status} />
          )}
        </Stack>
      )}
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <Stack direction="row" sx={{ alignItems: "flex-end" }}>
          {value && (
            <Typography
              sx={{
                fontSize: "25px",
                lineHeight: "25px",
                paddingRight: "5px",
                ...valueStyles,
              }}
            >
              {value}
            </Typography>
          )}
          {valueMetric && (
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "16px",
                fontWeight: 700,
                paddingRight: "5px",
                marginBottom: "3px",
                ...valueStyles,
              }}
            >
              {valueMetric}
            </Typography>
          )}
        </Stack>
        {showStatusArrow && statusArrowPosition !== "title" && (
          <StatusArrow flipStatusArrows={flipStatusArrows} status={status} />
        )}
      </Stack>
      {statusMessage && (
        <Typography
          sx={{
            color: status === "Good" ? lightgreen : red,
            fontSize: "10px",
            ...statusMessagStyles,
          }}
        >
          {statusMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default Metric;
