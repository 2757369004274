import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DownArrow } from "../../../icons";
import { getIcon, getStatusColor } from "../../../utils";
import { StatusCard } from "../../../components/StatusCard";
import { CardMetrics, CardTitle } from "../../../components/StatusCard";
import {
  black,
  gray1,
  gray4,
  tabSmallActive,
  tabSmallHover,
  tabSmallIdle,
  white,
} from "../../../constants/style";
import { paths } from "../../../constants/paths";
import Divider from "../../../components/Divider/Divider";
import DataPoint from "./DataPoint";

const containerStyles = {
  width: "50%",
  flex: "0 0 auto",
  justifyContent: "space-between",
};

const valueStyles = {
  fontSize: "25px",
};

const labelStyles = {
  fontSize: "10px",
};

const metricStyles = {
  fontSize: "16px",
  alignSelf: "flex-end",
  marginLeft: "2px",
  marginBottom: "3px",
};

const tooltipStrings = {
  grinding: "Total tonnage through grinding over the hours in the period",
  flotation:
    "Percentage of copper input to flotation circuit that is converted to copper concentrate",
  copper_production:
    "Product of copper head grade, gross grinding rate, and copper recovery",
};

const DrillDownButton = ({
  handleDrillDownClick,
  drillDownLabel = "Drill Down",
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        background: tabSmallIdle,
        borderTop: "1px solid #868992",
        cursor: "pointer",
        justifyContent: "center",
        userSelect: "none",
        "&:hover": {
          background: tabSmallHover,
        },
        "&:active": {
          background: tabSmallActive,
        },
      }}
      onClick={handleDrillDownClick}
    >
      <DownArrow />
      <Typography
        sx={{
          color: white,
          fontSize: "14px",
          marginLeft: "10px",
          padding: "5px 0",
        }}
      >
        {drillDownLabel}
      </Typography>
    </Stack>
  );
};

const StatusGridCard = ({ data, hasDrillDown, cardkey }) => {
  const history = useHistory();
  const metricAmount = data["throughput_actual_gross"]
    ? data["throughput_actual_gross"]["attribute_value"].toLocaleString("en-US")
    : data["recovery_actual_gross"]["attribute_value"].toLocaleString("en-US");
  const metricUnit = data["throughput_actual_gross"]
    ? data["throughput_actual_gross"]["display_uom"].toLocaleString("en-US")
    : data["recovery_actual_gross"]["display_uom"].toLocaleString("en-US");
  const actualRateKey = data["throughput_actual_net"]
    ? data["throughput_actual_net"]
    : data["recovery_actual_net"];
  const optimalRateKey = data["throughput_optimal_net"]
    ? data["throughput_optimal_net"]
    : data["recovery_optimal_net"];
  const recommendedKey = data["recommendation_adherence_actual_net"];
  const recommendedOptimalKey = data["recommendation_adherence_optimal_net"];
  const netRateStatus = data["optimal_threshold"]["Status"];
  const netRateMessage = `${netRateStatus === "Issue" ? "Below" : "Above"} ${
    data["optimal_threshold"]["attribute_value"]
  }${data["optimal_threshold"]["display_uom"]} of optimal`;

  const handleDrillDownClick = () => {
    if (cardkey.toLowerCase().match(new RegExp("grin"))) {
      history.push(paths["Grinding"]);
    }
    if (cardkey.toLowerCase().match(new RegExp("flot"))) {
      history.push(paths["Flotation"]);
    }
  };

  const getCardTitle = () => {
    if (cardkey.toLowerCase().match(new RegExp("grin"))) {
      return "Primary grinding";
    }
    if (cardkey.toLowerCase().match(new RegExp("flot"))) {
      return "Bulk flotation";
    }
    return "Copper production";
  };

  return (
    <StatusCard cardStyles={{ alignItems: "space-between" }}>
      <Stack sx={{ padding: "10px", width: "100%" }}>
        <Box sx={{ padding: "10px", marginBottom: "25px" }}>
          <CardTitle name={getCardTitle()} icon={getIcon(cardkey)} />
        </Box>
        <Box
          sx={{
            marginBottom: "25px",
          }}
        >
          <CardMetrics
            metric={
              data.throughput_actual_gross
                ? data.throughput_actual_gross.display_name
                : data.recovery_actual_gross.display_name
            }
            metricAmount={metricAmount}
            metricUnit={metricUnit}
            tooltip={tooltipStrings[cardkey]}
          />
        </Box>
        <Stack
          sx={{
            background: gray4,
            borderRadius: "10px",
            color: gray1,
            padding: "10px",
          }}
          divider={<Divider />}
        >
          <Stack
            direction="row"
            sx={{
              width: "100%",
              display: "flex",
              marginBottom: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ color: white, fontSize: "13px", fontWeight: 700 }}
            >
              {data["optimal_threshold"]["display_name"]}
            </Typography>
            <Typography
              sx={{
                color: getStatusColor(netRateStatus).color,
                fontSize: "13px",
                fontWeight: 700,
              }}
            >
              {netRateMessage}
            </Typography>
          </Stack>
          <Stack>
            <Stack
              direction="row"
              sx={{
                color: white,
                background: "transparent",
                borderRadius: "6px",
                padding: "10px",
              }}
              divider={
                <Divider
                  orientation="vertical"
                  dividerStyles={{ background: "#8B8D95", width: "1px" }}
                />
              }
            >
              <DataPoint
                containerStyles={containerStyles}
                label="Actual"
                labelStyles={labelStyles}
                value={actualRateKey["attribute_value"].toLocaleString("en-US")}
                valueStyles={{
                  ...valueStyles,
                  fontWeight: 700,
                  color: getStatusColor(netRateStatus).color,
                }}
                metric={actualRateKey["display_uom"]}
                metricStyles={{
                  ...metricStyles,
                  fontWeight: 700,
                  color: getStatusColor(netRateStatus).color,
                }}
              />
              <DataPoint
                containerStyles={{ ...containerStyles, marginLeft: "20px" }}
                label="Recommendation adherence"
                labelStyles={labelStyles}
                value={recommendedKey["attribute_value"].toLocaleString(
                  "en-US"
                )}
                valueStyles={valueStyles}
                metric={recommendedKey["display_uom"]}
                metricStyles={metricStyles}
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                color: black,
                borderRadius: "6px",
                padding: "10px",
                background:
                  "linear-gradient(180deg, #F2F3F6 37.5%, #DDDEE2 51.73%, #A6ABC1 122.86%)",
              }}
              divider={
                <Divider
                  orientation="vertical"
                  dividerStyles={{ background: "#8B8D95", width: "1px" }}
                />
              }
            >
              <DataPoint
                containerStyles={containerStyles}
                label="PHOSA optimal"
                labelStyles={labelStyles}
                value={optimalRateKey["attribute_value"].toLocaleString(
                  "en-US"
                )}
                valueStyles={valueStyles}
                metric={optimalRateKey["display_uom"]}
                metricStyles={metricStyles}
              />
              <DataPoint
                containerStyles={{ ...containerStyles, marginLeft: "20px" }}
                label="Recommendation adherence"
                labelStyles={labelStyles}
                value={recommendedOptimalKey["attribute_value"].toLocaleString(
                  "en-US"
                )}
                valueStyles={valueStyles}
                metric={recommendedOptimalKey["display_uom"]}
                metricStyles={metricStyles}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {hasDrillDown && (
        <DrillDownButton
          handleDrillDownClick={handleDrillDownClick}
          drillDownLabel={`${getCardTitle()} drill down`}
        />
      )}
    </StatusCard>
  );
};

export default StatusGridCard;
