import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ProcessCardContainer from "./ProcessCardContainer";

const ProcessCardRegister = ({
  processes,
  coreProcess,
  renderProcess,
  renderCoreProcess,
}) => {
  return (
    <Stack gap="10px" sx={{ width: "100%" }}>
      <Stack direction="row" sx={{ position: "relative" }}>
        <Box
          sx={{
            border: `1px solid #8E8E8E`,
            borderRadius: "45px",
            position: "absolute",
            top: 60,
            bottom: 0,
            left: `${100 / (processes.length + 1) / 2}%`,
            right: 0,
            zIndex: 1,
            background:
              "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          }}
        />
        <Stack direction="row" sx={{ width: "100%" }}>
          <ProcessCardContainer direction="row" data={processes}>
            {renderCoreProcess
              ? renderCoreProcess(coreProcess)
              : renderProcess(coreProcess)}
          </ProcessCardContainer>
          {processes.map((process, index) => {
            return (
              <ProcessCardContainer key={index} data={processes}>
                {renderProcess(process, index)}
              </ProcessCardContainer>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProcessCardRegister;
