import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

const fillColor = "#8b8d91";

export const LoadingDim = ({ width = 57, height = 37 }) => {
  return (
    <MuiIconWrapper size={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.3839 23.9312H7.59316C4.55493 23.9312 2.09196 26.4528 2.09196 29.5634C2.09196 32.6739 4.55493 35.1955 7.59316 35.1955H30.3839C33.4221 35.1955 35.8851 32.6739 35.8851 29.5634C35.8851 26.4528 33.4221 23.9312 30.3839 23.9312ZM29.598 26.345H8.37905C6.64292 26.345 5.23551 27.7859 5.23551 29.5634C5.23551 31.3408 6.64292 32.7817 8.37905 32.7817H29.598C31.3341 32.7817 32.7415 31.3408 32.7415 29.5634C32.7415 27.7859 31.3341 26.345 29.598 26.345Z"
          fill={fillColor}
        />
        <path
          d="M27.0345 29.5634C27.0345 30.8965 28.1152 31.9771 29.4483 31.9771C30.7814 31.9771 31.8621 30.8965 31.8621 29.5634C31.8621 28.2303 30.7814 27.1496 29.4483 27.1496C28.1152 27.1496 27.0345 28.2303 27.0345 29.5634Z"
          fill={fillColor}
        />
        <path
          d="M6.51725 29.5634C6.51725 30.8965 7.59793 31.9771 8.93104 31.9771C10.2641 31.9771 11.3448 30.8965 11.3448 29.5634C11.3448 28.2303 10.2641 27.1496 8.93104 27.1496C7.59793 27.1496 6.51725 28.2303 6.51725 29.5634Z"
          fill={fillColor}
        />
        <path
          d="M12.5517 29.1611C12.5517 29.8276 13.0921 30.368 13.7586 30.368C14.4252 30.368 14.9655 29.8276 14.9655 29.1611C14.9655 28.4945 14.4252 27.9542 13.7586 27.9542C13.0921 27.9542 12.5517 28.4945 12.5517 29.1611Z"
          fill={fillColor}
        />
        <path
          d="M19.7931 29.1611C19.7931 29.8276 20.3335 30.368 21 30.368C21.6666 30.368 22.2069 29.8276 22.2069 29.1611C22.2069 28.4945 21.6666 27.9542 21 27.9542C20.3335 27.9542 19.7931 28.4945 19.7931 29.1611Z"
          fill={fillColor}
        />
        <path
          d="M16.1724 29.1611C16.1724 29.8276 16.7128 30.368 17.3793 30.368C18.0459 30.368 18.5862 29.8276 18.5862 29.1611C18.5862 28.4945 18.0459 27.9542 17.3793 27.9542C16.7128 27.9542 16.1724 28.4945 16.1724 29.1611Z"
          fill={fillColor}
        />
        <path
          d="M23.4138 29.1611C23.4138 29.8276 23.9541 30.368 24.6207 30.368C25.2872 30.368 25.8276 29.8276 25.8276 29.1611C25.8276 28.4945 25.2872 27.9542 24.6207 27.9542C23.9541 27.9542 23.4138 28.4945 23.4138 29.1611Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.7931 24.7358L46.3448 2.20703L44.3333 2.60933L43.1264 6.63232L42.3218 5.82772L27.8391 15.0806L24.2184 6.63232H17.3793V16.2875H14.9655V14.276C14.9655 13.6804 14.6419 13.1604 14.1609 12.8821V9.44841H13.9216C13.6726 8.53538 12.9245 7.82802 11.9885 7.63807V8.73869C12.3219 8.88425 12.599 9.1349 12.7783 9.44841H12.5517V12.8821C12.0707 13.1604 11.7471 13.6804 11.7471 14.276V16.2875H6.11494C4.10345 16.2875 4.10345 16.9312 4.10345 17.8967V23.5289H27.8391L36.538 15.5353L34.8697 19.2961L34.4128 19.0934L31.4764 25.7126L33.6829 26.6915L36.6192 20.072L35.973 19.7854L38.7012 13.6352L38.6368 13.6067L42.7241 9.85071L51.1724 24.3335C50.0996 25.6745 46.9081 27.7127 42.7241 25.1381L42.3218 25.5404L40.7126 23.9312L39.908 24.3335L42.3218 27.5519L42.7241 27.1496C44.3333 29.1611 48.1954 33.2646 50.7701 33.5863C53.3448 33.9081 54.7931 29.1611 55.1954 26.7473L53.9885 25.5404L54.7931 24.7358ZM24.9374 14.4854C25.4096 14.3744 25.6768 13.8739 25.5065 13.4197L23.6096 8.36134C23.4918 8.04724 23.1916 7.83922 22.8562 7.83922H19.7931C19.3487 7.83922 18.9885 8.19948 18.9885 8.64381V14.8692C18.9885 15.3882 19.4723 15.7713 19.9774 15.6524L24.9374 14.4854Z"
          fill={fillColor}
        />
        <path
          d="M43.6828 3.4542L43.1211 2.53547L37.999 5.66723L37.7663 5.28654L32.2534 8.65717L33.377 10.4948L38.8899 7.12419L38.5607 6.58596L43.6828 3.4542Z"
          fill={fillColor}
        />
        <path
          d="M35.0805 25.1379L37.8966 19.5057L37.092 19.1034L39.5057 13.8736L42.7241 11.4598L49.9655 23.931C48.7586 24.7356 46.7471 26.7471 42.7241 23.931L42.3218 24.7356L40.7126 23.1264L38.7011 23.931L42.3218 28.7586L42.7241 28.3563C44.3333 30.3678 47.938 34.3908 51.1724 34.3908C54.3908 34.3908 55.5977 29.1609 56 26.3448L55.1954 25.5402L56 24.7356L46.7471 1L44.3333 1.8046L43.5287 1L37.8966 4.21839H36.6897L30.6552 7.83908L32.4655 10.6552L28.3621 13.4713L24.6207 5.82759H16.5747V15.4828H15.7701C15.7701 14.5441 15.7701 13.069 14.9655 12.6667M10.9425 15.4828H6.11494C6.11494 15.4828 3.29885 15.3034 3.29885 17.092C3.29885 18.8805 3.29885 21.5172 3.29885 23.5287C3.29885 23.5287 3.29885 24.3333 4.10345 24.3333"
          stroke={fillColor}
          strokeLinecap="round"
        />
        <path
          d="M14.9655 12.6668V8.64382H14.5632C13.7586 7.03462 12.5517 6.63232 11.3448 6.63232V9.04612L11.7471 9.44842"
          stroke={fillColor}
          strokeLinecap="round"
        />
        <path
          d="M11.7471 9.44824V12.6666C10.9425 13.4712 10.9425 13.4712 10.9425 15.4827"
          stroke={fillColor}
          strokeLinecap="round"
        />
        <path
          d="M35.0805 25.1381C36.2873 25.9427 36.8684 28.7588 36.6897 30.368C36.2874 33.9887 32.6667 36.0002 31.0575 36.0002H6.91956C4.90807 36.0002 1.28738 33.9887 1.28738 29.5634C1.28738 26.927 2.89658 24.4676 4.10347 24.3335"
          stroke={fillColor}
          strokeLinecap="round"
        />
      </svg>
    </MuiIconWrapper>
  );
};
