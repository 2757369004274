import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Divider from "../../../components/Divider/Divider";
import Typography from "@mui/material/Typography";
import {
  black,
  gray1,
  tabSmallActive,
  tabSmallHover,
  tabSmallIdle,
  white,
} from "../../../constants/style";
import { DownArrow } from "../../../icons";
import Metric from "../../../components/Metric/Metric";
import { getIcon } from "../../../utils";

const Card = ({
  header,
  subheader,
  drillDownLink,
  downTime,
  tooltip,
  emissions,
}) => {
  const cardRef = useRef(null);
  const history = useHistory();

  const handleDrillDownClick = () => {
    if (drillDownLink) {
      history.push({
        pathname: drillDownLink,
      });
    }
  };

  const isEnvCard = header === "Environment";

  return (
    <Stack
      divider={
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ background: "#D0D0D0" }}
        />
      }
      sx={{ minHeight: "395px", height: "100%" }}
      ref={cardRef}
    >
      <Stack
        direction="row"
        sx={{ padding: "20px 25px 15px", alignItems: "center" }}
      >
        <Box
          sx={{
            height: "44px",
            width: "44px",
            borderRadius: "50%",
            background: "#D9D9D9",
            marginRight: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {getIcon(header, 1)}
        </Box>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              color: white,
              fontSize: "20px",
              lineHeight: "22px",
              paddingRight: "5px",
            }}
          >
            {header}:
          </Typography>
          <Typography
            sx={{ color: white, fontSize: "20px", lineHeight: "22px" }}
          >
            {subheader}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <IconButton sx={{ marginLeft: "5px", padding: 0 }}>
                <HelpOutlineIcon
                  sx={{ width: "18px", height: "18px", color: gray1 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      {downTime && (
        <Stack
          sx={{
            padding: "10px 20px 20px",
            flexGrow: 1,
            justifyContent: isEnvCard ? "flex-end" : "flex-start",
          }}
        >
          {!isEnvCard && (
            <Typography
              sx={{
                color: white,
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {downTime.Name}
            </Typography>
          )}
          <Stack
            sx={{
              background: isEnvCard ? "transparent" : "#2e2f37",
              minHeight: "216px",
              borderRadius: "10px",
              padding: "7px",
            }}
            divider={
              isEnvCard && <Divider dividerStyles={{ margin: "15px 0 0" }} />
            }
          >
            {isEnvCard && emissions && (
              <Metric
                data={{
                  containerStyles: {
                    color: white,
                    marginLeft: "30px",
                  },
                  title: emissions.Display_Name,
                  titleStyles: {
                    fontSize: "14px",
                    marginBottom: "3px",
                  },
                  value: emissions.Metric_Value,
                  valueMetric: emissions.Display_UOM,
                  valueStyles: {
                    fontWeight: 700,
                    fontSize: "25px",
                    paddingRight: "5px",
                    alignSelf: "center",
                    marginBottom: 0,
                  },
                }}
              />
            )}
            {downTime.Metrics_Total && (
              <Stack
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  padding: "8px 0 12px 0",
                  background:
                    "linear-gradient(180deg, #F2F3F6 37.5%, #DDDEE2 51.73%, #A6ABC1 122.86%)",
                }}
              >
                <Metric
                  data={{
                    containerStyles: {
                      alignItems: "center",
                      color: black,
                    },
                    title: downTime.Metrics_Total.Display_Name,
                    titleStyles: {
                      fontSize: "14px",
                    },
                    statusArrowPosition: "value",
                    status: downTime.Metrics_Total.Metric_Status,
                    value: downTime.Metrics_Total.Metric_Value,
                    valueMetric: downTime.Metrics_Total.Display_UOM,
                    valueStyles: {
                      fontWeight: 700,
                      fontSize: "25px",
                      paddingRight: "5px",
                      alignSelf: "center",
                      marginBottom: 0,
                    },
                  }}
                />
              </Stack>
            )}
            <Grid container>
              <Grid
                item
                sx={{
                  width: "100%",
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  {downTime.Metrics &&
                    downTime.Metrics.map((item, index) => {
                      return (
                        <Metric
                          key={index}
                          data={{
                            containerStyles: {
                              maxWidth: "120px",
                              padding: "12px 0 20px 0",
                              color: white,
                              justifyContent: "flex-end",
                            },
                            title: item.Display_Name,
                            status: item.Metric_Status,
                            value: item.Metric_Value,
                            valueMetric: item.Display_UOM,
                            statusMessage: item.Metric_Message,
                          }}
                        />
                      );
                    })}
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      )}
      <Box sx={{ cursor: "pointer" }} onClick={handleDrillDownClick}>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 0",
            background: tabSmallIdle,
            "&:hover": {
              background: tabSmallHover,
            },
            "&:active": {
              background: tabSmallActive,
            },
          }}
        >
          <Box
            sx={{
              paddingRight: "10px",
            }}
          >
            <DownArrow />
          </Box>
          <Typography sx={{ color: white, fontSize: "14px" }}>
            {header} drill down
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Card;
