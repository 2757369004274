import React from "react";
import Box from "@mui/material/Box";
import { cardBorder, pillBackground } from "../../constants/style";

const Pill = ({ children, pillStyles }) => {
  return (
    <Box
      sx={{
        background: pillBackground,
        border: cardBorder,
        borderRadius: "25px",
        width: "100%",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        overflow: "hidden",
        ...pillStyles,
      }}
    >
      {children && children}
    </Box>
  );
};

export default Pill;
