import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const DownStream = ({ size = 34, height = 18 }) => {
  return (
    <MuiIconWrapper size={size} height={height}>
      <svg
        width={size}
        height={height}
        viewBox={`0 0 ${size} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.892425 1.97279C0.789503 1.65024 1.03019 1.3208 1.36876 1.3208H31.9314C32.27 1.3208 32.5107 1.65024 32.4078 1.9728L27.7333 16.6222C27.6672 16.8295 27.4746 16.9702 27.257 16.9702H6.04322C5.82564 16.9702 5.63303 16.8295 5.56688 16.6222L0.892425 1.97279Z"
          stroke="#191D24"
        />
        <path
          d="M4.64796 9.42953C4.43918 8.71029 5.06599 8.02524 5.8009 8.16947L8.86947 8.7717C8.98123 8.79363 9.09593 8.79631 9.2086 8.77962L15.3419 7.87098C15.4207 7.85931 15.5006 7.85709 15.58 7.86439L20.4969 8.31652C20.5879 8.32488 20.6795 8.32075 20.7694 8.30423L25.6529 7.40611C25.7553 7.38728 25.86 7.38455 25.9632 7.39803L28.2475 7.69634C28.8709 7.77776 29.2655 8.40758 29.0667 9.00411L27.0356 15.0981C26.8995 15.5064 26.5173 15.7819 26.0869 15.7819H7.24297C6.79805 15.7819 6.40665 15.4879 6.28261 15.0606L4.64796 9.42953Z"
          fill="#191D24"
        />
      </svg>
    </MuiIconWrapper>
  );
};
