import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const NavigationArrow = () => {
  return (
    <MuiIconWrapper size={69} height={6}>
      <svg
        width="69"
        height="6"
        viewBox="0 0 69 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 3L5 5.88675L5 0.113249L0 3ZM69 3L64 0.113249V5.88675L69 3ZM4.5 3.5L64.5 3.5V2.5L4.5 2.5V3.5Z"
          fill="#B3B4BA"
        />
      </svg>
    </MuiIconWrapper>
  );
};
