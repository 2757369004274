import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { titleStyles } from "./CardTitle.styles";

export const CardTitle = ({ name, icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {icon && icon}
      {name && <Typography sx={titleStyles}>{name}</Typography>}
    </Box>
  );
};
