import React, { createContext, useContext, useState } from 'react';

const defaultState = {
  showSiteConfig: false,
  siteConfig: {
    authMasterData: [],
    clients: [],
    selectedClient: '',
    sites: [],
    selectedSite: ''
  },
  millPerformanceData: undefined
};

export const SiteConfig = createContext(defaultState);

export const useSiteConfig = () => useContext(SiteConfig);

const SiteConfigProvider = ({ children }) => {

  const [showSiteConfig, setShowSiteConfig] = useState(defaultState.showSiteConfig)
  const [siteConfig, setSiteConfig] = useState(defaultState.siteConfig)

  const value = {
    showSiteConfig,
    setShowSiteConfig,
    siteConfig,
    setSiteConfig
  }

  return (
    <SiteConfig.Provider value={value}>{children && children}</SiteConfig.Provider>
  )
}

export default SiteConfigProvider;