class AppStorageTypes {
  local = "localStorage";
  session = "sessionStorage";
}

class AppKeys {
  storageType = "localStorage";
}

class StaticValues {
  roles = {
    admin: "bcg_admin",
    executive: "executive",
    siteManager: "site_manager",
    mineManager: "mine_manager",
    millManager: "mill_manager",
  };

  roleBasedLandingScreens = {
    [this.roles.admin]: "/",
    [this.roles.executive]: "/app/asset_performance",
    [this.roles.siteManager]: "/app/mill_performance",
    [this.roles.mineManager]: "/app/mill_performance",
    [this.roles.millManager]: "/app/mill_performance",
  };
}

class AppUrls {
  /* Local Docker*/
  protocol = `${window.location.protocol}`;
  origin = `${
    window.location.hostname.indexOf("localhost") >= 0
      ? "127.0.0.1:8000"
      : window.location.hostname
  }`;
  APIServer = `${
    window.location.hostname.indexOf("localhost") >= 0
      ? `${this.origin}`
      : `${this.origin}/api/v1`
  }`;
  baseUrl = `${this.protocol}//${this.APIServer}/`;

  getMillStatus = `${this.protocol}//${this.APIServer}/mill-status/summary/`;
  getUserInfo = `${this.protocol}//${this.APIServer}/phosa-auth/user-info/`;
  getMillPerformanceOld = `${this.protocol}//${this.APIServer}/mill-performance/summary/`;
  // getMillPerformance = (client) => `${this.protocol}//${this.APIServer}/clients/${client}/mill-performance/summary/`;
  // getMillPerformance = (client) => `${this.protocol}//${this.APIServer}/clients/${client}/mill-performance/summaryTest/`;
  getMillPerformance = (client) =>
    `${this.protocol}//${this.APIServer}/clients/${client}/mill-performance/summary-v3/`;
  getLanding = (client) =>
    `${this.protocol}//${this.APIServer}/clients/${client}/potential_value/`;
  getSites = `${this.protocol}//${this.APIServer}/mill-status/sites/`;

  getClients = `${this.protocol}//${this.APIServer}/phosa-ref/customers/`;
  getTimestamps = (client, site) =>
    `${this.protocol}//${this.APIServer}/clients/${client}/timestamp/?site_name=${site}`;
  getClientSites = (client) =>
    `${this.protocol}//${this.APIServer}/clients/${client}/sites/`;
  getFpvData = (client) =>
    `${this.protocol}//${this.APIServer}/clients/${client}/fpv/fpv-detail/`;
}

class SocketRoomNames {
  fileUpload = "upload";
  modelRun = "modelRun";
}

/**
 * Application constants
 */
class AppConstants {
  static _instance;

  keys = new AppKeys();
  urls = new AppUrls();
  storageTypes = new AppStorageTypes();
  socketRoomNames = new SocketRoomNames();
  staticValues = new StaticValues();

  static getInstance() {
    if (!AppConstants._instance) {
      AppConstants._instance = new AppConstants();
    }

    return AppConstants._instance;
  }
}

export const appConstants = AppConstants.getInstance();
