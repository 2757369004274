import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { IntenseRed1,
  IntenseRed2,
  IntenseRed3,
  black,
  white,
  tabSmallActive,
  tabSmallHover,
  tabSmallIdle } from "../../constants/style";
import ProcessCardFPV from "./components/processCard_AS";
import { getViewData } from "../../utils/getViewData";
import { useSiteConfig } from "../../_shared/context/siteChangeTester.context";
import { useOktaAuth } from "@okta/okta-react";
import { useLoaderContext } from "../../_shared/context/loader.context";
import { DownArrow } from "../../icons";
import data from "../../mockData/asset_strategy.json"
import { useHistory } from "react-router-dom";
import ViewTopBar from "../../components/ViewTopBar/ViewTopBar";

const colorMap = {
  1: IntenseRed1,
  2: IntenseRed2,
  3: IntenseRed3,
};

const AssetStrategy = () => {
  // const [data, setData] = useState();
  const { siteConfig } = useSiteConfig();
  const { oktaAuth } = useOktaAuth();
  const { startLoader, endLoader } = useLoaderContext();
  const history = useHistory();
  const dropDownValues = [{
    value: "Next 12 months",
    label: "Next 12 months",
  }];

  useEffect(() => {
    if (siteConfig.selectedClient) {
    }
  }, [siteConfig]);

  const handleDrillDownClick = (screen) => {
    if (screen === 'fullPotentialValue') {
      history.push("/app/full_potential_value");
    } else {
      history.push("/app/field_tested_value");
    }
  }

  const box1Styles = {
    background: "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
    border: "1px solid #464959",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: "15px",
    flex: "1 0 auto",
    overflow: "hidden"
  };

  const updateBottleNeckProcess = (data) => {
    const topThreeProcesses = [...data.processes].sort((x, y) => y.value - x.value).slice(0, 3).map(item => data.processes.findIndex((process) => process.name === item.name));
    topThreeProcesses.reverse().forEach((item, index) => {
      data.processes[item].bottleNeckIntensity = index + 1;
    });
    return data;
  }

  const bottleNeckLegends = () => {
    const bottleNeckLegends = [...data.processes].filter(item => item.bottleNeckIntensity).sort((a,b) => b.value - a.value)
    const legends = bottleNeckLegends.map(item => {
      return (
        <Box sx={{width: '10px', height: '10px', borderRadius: '50%', border: '3px solid', borderColor: colorMap[item.bottleNeckIntensity]}}></Box>
      )
    })
    return legends;
  }

  const getRefinedValue = (val) =>{
    return `$${Math.round(val)}M`
  }
  
  return (
    <>
      <Stack gap="30px">
        <Stack direction="row" alignItems="center">
          <ViewTopBar dataTimeRanges={dropDownValues}>
            <Stack direction="row" gap="1rem" alignItems="center">
              <Typography sx={{color: '#fff', fontSize: '21px', fontWeight: '700'}}>Asset strategy</Typography>
              {data && <Typography sx={{color: '#fff', fontSize: '16px'}}>&#40; Copper price at ${data.summary.copper_price}/tonne &#41;</Typography>}
            </Stack>
          </ViewTopBar>
        </Stack>
        {
          data && <Stack gap="30px">
          <Stack direction="row" gap="15px">
            <Stack sx={box1Styles}>
              <Stack sx={{padding: '20px' }}>
                <Typography sx={{color: '#fff', fontSize: '14px'}}>Potential incremental value</Typography>
                <Typography sx={{color: '#fff', fontSize: '35px', fontWeight: '700'}}>{getRefinedValue(data.summary.potential_incremental_value)}</Typography>
                <Typography sx={{color: '#80CBC3', fontSize: '14px'}}>Calculated by PHOSA</Typography>
              </Stack>
              <Box sx={{ cursor: "pointer" }} onClick={() => handleDrillDownClick('fullPotentialValue')}>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0",
                    borderTop: "1px solid #8E8E8E",
                    background: tabSmallIdle,
                    "&:hover": {
                      background: tabSmallHover,
                    },
                    "&:active": {
                      background: tabSmallActive,
                    },
                  }}
                >
                  <Box
                    sx={{
                      paddingRight: "10px",
                    }}
                  >
                    <DownArrow />
                  </Box>
                  <Typography sx={{ color: white, fontSize: "14px" }}>
                    Drill down to Full Potential Value
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <Stack sx={box1Styles}>
              <Stack sx={{padding: '20px', flex:"1 0 auto"}}>
                <Typography sx={{color: '#fff', fontSize: '14px'}}>Value captured with PHOSA</Typography>
                <Typography sx={{color: '#fff', fontSize: '35px', fontWeight: '700'}}>{getRefinedValue(data.summary.value_captured)}</Typography>
              </Stack>
              <Box sx={{ cursor: "pointer" }} onClick={() => handleDrillDownClick('fieldTestedValue')}>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0",
                    borderTop: "1px solid #8E8E8E",
                    background: tabSmallIdle,
                    "&:hover": {
                      background: tabSmallHover,
                    },
                    "&:active": {
                      background: tabSmallActive,
                    },
                  }}
                >
                  <Box
                    sx={{
                      paddingRight: "10px",
                    }}
                  >
                    <DownArrow />
                  </Box>
                  <Typography sx={{ color: white, fontSize: "14px" }}>
                    Drill down to field tested value
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
          <Stack gap="10px">
            <Stack direction="row" sx={{ position: "relative", marginTop: "20px" }}>
              <Box
                sx={{
                  border: `1px solid #8E8E8E`,
                  borderRadius: "45px",
                  position: "absolute",
                  top: 60,
                  bottom: 0,
                  left: `${(100 / (data.processes.length + 1)) / 2}%`,
                  right: 0,
                  zIndex: 1,
                  background: 'linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)',
                  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
                }}
              />
              <Stack direction="row" sx={{width: '100%'}}>
                <Stack direction="row"
                  sx={{
                    width: `${100 / (data.processes.length)}%`,
                    position: "relative",
                    zIndex: 2
                  }}
                >
                    <ProcessCardFPV
                      data={data.processes[0]}
                      renderLine={false}
                      isReadOnly = {true}
                    />
                </Stack>
                {
                  data.processes.map((process, index) => {
                    if (index === 0) {
                      return null;
                    }
                    return (
                      <Stack direction="row" sx={{width: `${100 / (data.processes.length)}%`, position: "relative", zIndex: 2}}>
                        <ProcessCardFPV
                          id={`processCard-${index}`}
                          data={process}
                          renderLine={(data.processes.length - 1 === index) ? false : true}
                          isReadOnly = {false}
                        />
                      </Stack>
                    );
                  })
                }
              </Stack>
            </Stack>
            <Stack direction="row" gap="20px" alignItems="center" justifyContent="end">
                { data.processes[0].value_Captured > 0 && 
                <Stack direction="row" gap="10px" alignItems="center">
                  <Box sx={{width: '10px', height: '10px', borderRadius: '50%', border: '3px solid', borderColor: '#DAE085'}}></Box>
                  <Typography sx={{color: '#fff', fontSize: '14px'}}>Additional value received by activating two or more</Typography>
                </Stack>}
                <Stack direction="row" gap="10px" alignItems="center">
                  {bottleNeckLegends()}
                  <Typography sx={{color: '#fff', fontSize: '14px'}}>Most to least intense bottleneck</Typography>
                </Stack>
            </Stack>
          </Stack>
        </Stack>
        }
      </Stack>
    </>
  );
};

export default AssetStrategy;
