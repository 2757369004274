export const abbreviateNumber = (
  num,
  toFixedDigits = 1,
  shouldAppendNumeric = false
) => {
  const lookup = [
    { value: 0, symbol: "" },
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  let formattedNumber = num > 0 ? (num / item.value).toFixed(toFixedDigits) : 0;

  if (shouldAppendNumeric)
    formattedNumber = `${formattedNumber.replace(rx, "$1")}${item.symbol}`;

  return item ? formattedNumber : 0;
};

export const abbreviateNumberUpdated = (
  num,
  toFixedDigits = 1,
  shouldAppendNumeric = false
) => {
  const lookup = [
    { value: 0, symbol: "" },
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  let formattedNumber = (num / item.value).toFixed(toFixedDigits);

  if (shouldAppendNumeric)
    formattedNumber = `${formattedNumber.replace(rx, "$1")} ${item.symbol}`;

  return item ? +formattedNumber : 0;
};
