import React from "react";
import Box from "@mui/material/Box";
import DataPoint from "./DataPoint";
import { white } from "../../../constants/style";
import { Pill } from "../../../components/Pill";

const containerStyles = {
  color: white,
};

const valueStyles = {
  fontSize: "35px",
  lineHeight: "35px",
  fontWeight: 700,
};

const labelStyles = {
  fontSize: "14px",
  marginBottom: "25px",
};

const metricStyles = {
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "20px",
  alignSelf: "flex-end",
  marginLeft: "2px",
};

const PillSection = ({ data }) => {
  return (
    <Box sx={{ width: "276px" }}>
      <Pill
        pillStyles={{
          background: "#252933",
          height: "100%",
          display: "flex",
          padding: "24px",
          border: "none",
        }}
      >
        <DataPoint
          containerStyles={{ ...containerStyles }}
          label="Calendar Hours"
          labelStyles={labelStyles}
          value={data.toLocaleString("en-US")}
          valueStyles={valueStyles}
          metric="hours"
          metricStyles={metricStyles}
        />
      </Pill>
    </Box>
  );
};

export default PillSection;
