import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { gray4, white } from "../../../constants/style";

const addDecimal = (name, value) => {
  if (name.toLowerCase().match(new RegExp("flot"))) {
    return value.toFixed(1);
  }

  if (name.toLowerCase().match(new RegExp("stock"))) {
    return value.toFixed(2);
  }

  return value.toLocaleString();
};

const ContentContainer = ({ data, opposite = false, isOptimal, name }) => {
  return (
    <Stack
      sx={{
        background: opposite
          ? "linear-gradient(180deg, #F2F3F6 37.5%, #DDDEE2 51.73%, #A6ABC1 122.86%)"
          : gray4,
        color: opposite ? gray4 : white,
        flex: "1 1 auto",
        width: "100%",
        height: "100%",
        padding: "0 8px",
        borderRadius: "10px",
        alignItems: "center",
        ...(opposite && { border: "3px solid #626579" }),
      }}
    >
      <Typography sx={{ fontSize: "11px", marginTop: "5px" }}>
        {isOptimal ? "PHOSA Optimal" : data.display_name}
      </Typography>
      <Stack direction="row">
        {data.attribute_value ? (
          <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
            {addDecimal(name, data.attribute_value)}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: 700,
              color: "#9C9CA0",
              marginTop: "10px",
            }}
          >
            {isOptimal ? "Not yet activated" : ""}
          </Typography>
        )}
        <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
          {data.display_uom}
        </Typography>
      </Stack>
    </Stack>
  );
};

const ProcessCardDetails = ({ data, isCoreProcess }) => {
  const actual = data.actual_gross || data.actual_net;
  const optimal = data.optimal_net || data.optimal_gross;

  return (
    <Stack
      sx={{
        minHeight: "184px",
        alignItems: "center",
        textAlign: "center",
        flex: "1 1 auto",
        width: "100%",
      }}
    >
      {!isCoreProcess && actual && (
        <ContentContainer data={actual} name={data.display_name} />
      )}
      {!isCoreProcess && optimal && (
        <ContentContainer
          data={optimal}
          opposite={true}
          isOptimal={true}
          name={data.display_name}
        />
      )}
      {!isCoreProcess && !optimal && (
        <ContentContainer
          data={{}}
          opposite={true}
          isOptimal={true}
          name={data.display_name}
        />
      )}
      {isCoreProcess && actual && (
        <ContentContainer data={actual} name={data.display_name} />
      )}
      {isCoreProcess && !optimal && (
        <div
          style={{ height: "100%", flex: "1 1 auto" }}
          name={data.display_name}
        />
      )}
      <Typography sx={{ color: white, fontSize: "14px", margin: "10px 0" }}>
        {data.display_name}
      </Typography>
    </Stack>
  );
};

export default ProcessCardDetails;
