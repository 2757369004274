import React from "react";
import Box from "@mui/material/Box";
import { gray1, gray2, gray6, white } from "../../constants/style";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";

const DOT_SIZE = "16px";
const DOT_GLOW_ACTIVE_SIZE = "12px";
const DOT_GLOW_INACTIVE_SIZE = "5px";
const DOT_GLOW_COLOR = "#255EFF";

const NavigationDot = ({ isActive, link, label, labelStyles }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(link);
  };

  return (
    <Box
      sx={{
        background: gray1,
        border: `2px solid ${gray2}`,
        borderRadius: "50%",
        height: DOT_SIZE,
        width: DOT_SIZE,
        position: "relative",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          background: isActive ? white : gray2,
          height: isActive ? DOT_GLOW_ACTIVE_SIZE : DOT_GLOW_INACTIVE_SIZE,
          width: isActive ? DOT_GLOW_ACTIVE_SIZE : DOT_GLOW_INACTIVE_SIZE,
          boxShadow: isActive ? `0px 0px 10px ${DOT_GLOW_COLOR}` : "none",
          borderRadius: "50%",
          position: "absolute",
          top: isActive ? 0 : "50%",
          left: isActive ? 0 : "50%",
          transform: isActive ? "translate(0, 0)" : "translate(-50%, -50%)",
        }}
      />
      <Typography
        sx={{
          border: "3px solid #353846",
          background: "#353846",
          position: "absolute",
          top: "25px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 5,
          maxWidth: "60px",
          fontSize: "10px",
          lineHeight: "10px",
          textAlign: "center",
          color: gray1,
          ...labelStyles,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default NavigationDot;
