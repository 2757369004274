import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CardGrid, CardGridItem } from "../../components/CardGrid";
import TopSummary from "./components/TopSummary";
import UpstreamCard from "./components/UpstreamCard";
import StatusGridCard from "./components/StatusGridCard";
import ViewTopBar from "../../components/ViewTopBar/ViewTopBar";
import { useSiteConfig } from "../../_shared/context/siteChangeTester.context";
import { white } from "../../constants/style";
import { getViewData } from "../../utils/getViewData";
import { useLoaderContext } from "../../_shared/context/loader.context";
import { useTimestampContext } from "../../_shared/context/timestamp.context ";

const cardRenderController = (key, data) => {
  switch (key.toLowerCase()) {
    case "upstream":
      return <UpstreamCard data={data[key]} />;
    default:
      return (
        <StatusGridCard
          data={data[key]}
          hasDrillDown={hasDrillDown(key)}
          cardkey={key}
        />
      );
  }
};

/**
 * TODO:
 * - Confirm what pages have drill downs and create
 * drillDownMap / hasDrillDown attribute on view data
 */
const hasDrillDown = (data) => {
  if (data.toLowerCase().match(new RegExp("grin"))) {
    return true;
  }
  if (data.toLowerCase().match(new RegExp("flot"))) {
    return true;
  }
  return false;
};

const MillPerformance = () => {
  const [data, setData] = useState(undefined);
  const { siteConfig } = useSiteConfig();
  const [isLoading, setIsLoading] = useState(false);
  const { startLoader, endLoader } = useLoaderContext();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const { setTimestamp, timestampCount } = useTimestampContext();

  useEffect(() => {
    getMillPerformanceData();
  }, [siteConfig]);
    
  useEffect(() => {
    if (!data) { return};
    getMillPerformanceData();
  }, [timestampCount]);


  const getMillPerformanceData = () => {
    if (siteConfig.selectedClient) {
      setIsLoading(true);
      startLoader();
      getViewData(
        "getMillPerformance",
        "post",
        siteConfig.selectedClient,
        siteConfig.selectedSite,
        "MTD"
      )
        .then((res) => {
          setIsLoading(false);
          setData(res.data);
          setTimestamp(res.data.timestamp);
          endLoader();
        })
        .catch((error) => {
          endLoader();
          console.log(error);
          if (error.status === 500) {
            history.push("/error");
          } else if (error.status === 401) {
            oktaAuth.signOut();
          } else if (error.status === 403) {
            history.push("/unauthorized");
          }
        });
    }
  }

  return (
    <Grid
      className="MillPerformance"
      container
      columns={20}
      spacing="20px"
      sx={{ width: "100%" }}
    >
      <Grid item xs={20}>
        <ViewTopBar>
          <Typography
            sx={{
              color: white,
              fontSize: "21px",
              fontWeight: 700,
              marginRight: "5px",
            }}
          >
            Copper concentrate production
          </Typography>
        </ViewTopBar>
      </Grid>
      <Grid item xs={20} sx={{ marginBottom: "20px" }}>
        {data && <TopSummary data={data["mill_performance_summary"]} />}
      </Grid>
      <Grid item xs={20}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 700, color: white }}
            >
              Mill Performance
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={20}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <CardGrid showSkeleton={isLoading} showNavigation>
            <CardGridItem key="upstream">
              {data && cardRenderController("upstream", data)}
            </CardGridItem>
            {data &&
              Object.keys(data["cards"]).map((key, index) => {
                return (
                  <CardGridItem key={index}>
                    {data && cardRenderController(key, data["cards"])}
                  </CardGridItem>
                );
              })}
          </CardGrid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MillPerformance;
