import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import { gray1, green } from "../../constants/style";
import { useTimestampContext } from "../../_shared/context/timestamp.context ";

const getFormattedDate = (timestamp) => {
  if (!timestamp) return "Loading...";
  let date = new Date(timestamp);
  let day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(date);
  let weekday = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
    date
  );
  let month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
  let time = new Intl.DateTimeFormat("en-US", { timeStyle: "long" })
    .format(date)
    .split(" ")[0];
  let timeZoneName = new Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
    .format(date)
    .split(",")[1];
  return `${weekday}, ${month} ${day} - ${time} ${timeZoneName}`;
};

const UpdateSiteData = () => {
  const { timestamp, updateTimestamp } = useTimestampContext();

  const handleRefreshDataClick = () => {
    updateTimestamp();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        margin: "32px 0 22px 0 ",
      }}
    >
      <Typography sx={{ color: gray1 }}>
        Last Update: {getFormattedDate(timestamp)}
      </Typography>
      <LoadingButton
        size="small"
        onClick={handleRefreshDataClick}
        loadingPosition="start"
        loadingIndicator={<CircularProgress sx={{ color: gray1 }} size={12} />}
        startIcon={<RefreshIcon />}
        sx={{
          "&.MuiLoadingButton-loading": {
            color: gray1,
          },
          color: green,
          background: "transparent",
          padding: 0,
          marginLeft: "15px",
          textTransform: "capitalize",
          "& .MuiButton-startIcon": {
            marginRight: "3px",
          },
          "& .MuiLoadingButton-loadingIndicator": {
            left: "3px",
          },
          "&: hover": {
            background: "transparent",
          },
        }}
      >
        {"Update"}
      </LoadingButton>
    </Box>
  );
};

export default UpdateSiteData;
