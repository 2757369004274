import React from "react";
import Stack from "@mui/material/Stack";
import { Pill } from "../../../components/Pill";
import Divider from "../../../components/Divider/Divider";
import PillSection from "./PillSection";
import GridRow from "./GridRow";
import GridItem from "../../../components/TopSummary/GridItem";
import { lightgreen } from "../../../constants/style";

const fakeTargetProductionValue = 6.75;

const returnfakeTargetProdValue = (item, data) => {
  if (item === "target_production") {
    return 6.75;
  }

  return data[item].attribute_value;
};

const TopSummary = ({ data }) => {
  const valueBasedOnAttributeUom = (value, uom) => {
    if (uom.toLowerCase() === "kt") {
      return value.toLocaleString("en-US") + "K";
    }
    return value;
  };

  return (
    <Pill pillStyles={{ padding: "4px" }}>
      <Stack
        direction="row"
        sx={{ height: "100%", justifyContent: "space-between" }}
      >
        <Stack
          sx={{
            width: "100%",
            padding: "0 36px 0 15px",
          }}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <GridRow
            rowTitle="Actual"
            rowTitleStyles={{ fontWeight: 700, marginRight: "12px" }}
          >
            {Object.keys({
              production_actual_gross: data.production_actual_gross,
              throughput_actual_gross: data.throughput_actual_gross,
              target_production: data.target_production,
            }).map((item, index) => {
              return (
                <GridItem
                  key={index}
                  fontWeight={700}
                  title={data[item].display_name}
                  titleLocation="top"
                  value={valueBasedOnAttributeUom(
                    returnfakeTargetProdValue(item, data),
                    data[item].display_uom
                  )}
                  units={
                    data[item].display_uom.toLowerCase() === "kt"
                      ? "Tonnes"
                      : "tph"
                  }
                  gridItemProps={{
                    sx: {
                      flex: "1 1 0",
                    },
                  }}
                />
              );
            })}
          </GridRow>
          <GridRow rowTitle="Optimal" gridItemStyles={{ marginRight: "26px" }}>
            {Object.keys({
              production_optimal_gross: data.production_optimal_gross,
              throughput_optimal_gross: data.throughput_optimal_gross,
              space: {},
            }).map((item, index) => {
              return (
                <GridItem
                  key={index}
                  fontWeight={300}
                  title={index === 1 ? "PHOSA Optimal" : ""}
                  titleLocation="bottom"
                  titleStyles={{ color: lightgreen }}
                  value={
                    data[item]
                      ? valueBasedOnAttributeUom(
                          data[item].attribute_value,
                          data[item].display_uom
                        )
                      : ""
                  }
                  units={data[item] ? (index ? "tph" : "Tonnes") : ""}
                  gridItemProps={{
                    sx: {
                      flex: "1 1 0",
                    },
                  }}
                />
              );
            })}
          </GridRow>
        </Stack>
        <PillSection data={data.calendar_hours} />
      </Stack>
    </Pill>
  );
};

export default TopSummary;
