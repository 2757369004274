import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import NavigationDot from "./NavigationDot";
import { NavigationArrow, NavigationArrowVertical } from "../../icons";
import { getNavMiniMapState } from "../../utils/getNavMiniMapState";
import { paths } from "../../constants/paths";
import { NAVDRAWER_MAX_WIDTH } from "../../constants";
import { NAV_MINIMAP_ZINDEX } from "../../constants/appZindexLayers";
import { gray6, white } from "../../constants/style";
import { Typography } from "@mui/material";

const centerLabelStyles = {
  top: 25,
};

const labelStyles = {
  top: 20,
};

const NavMiniMap = ({ alignmentGuideNode }) => {
  const [positionOffset, setPositionOffset] = useState(NAVDRAWER_MAX_WIDTH);

  const getPositionOffset = () => {
    const guideNode = document.querySelector(alignmentGuideNode);
    const leftOffset = guideNode.getBoundingClientRect().left;
    setPositionOffset(leftOffset);
  };

  let location = useLocation();
  const [mapState, setMapState] = useState({});

  const recalculatePosition = () => {
    window.addEventListener("resize", getPositionOffset);
    return () => window.removeEventListener("resize", getPositionOffset);
  };

  useEffect(() => {
    if (location.pathname) {
      const pageName = paths[location.pathname];

      const { currentNode, downstreamNode, upstreamNode, parentNode } =
        getNavMiniMapState(pageName);

      setMapState({
        currentNode,
        downstreamNode,
        upstreamNode,
        parentNode,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (alignmentGuideNode) {
      getPositionOffset();
      recalculatePosition();
    } else {
      setPositionOffset(NAVDRAWER_MAX_WIDTH);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [alignmentGuideNode]);

  return (
    <Box
      sx={{
        height: "290px",
        width: "300px",
        background: "#353846",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: NAV_MINIMAP_ZINDEX,
        borderBottomLeftRadius: "50px",
        "&::after": {
          content: "''",
          position: "absolute",
          backgroundColor: "transparent",
          top: 0,
          left: -50,
          height: "100px",
          width: "50px",
          borderTopRightRadius: "50px",
          boxShadow: `0 -50px 0 0 #353846`,
        },
        // top: 30,
        // left: positionOffset - NAVDRAWER_MAX_WIDTH + 32,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -40%)",
        }}
      >
        <Typography
          sx={{
            color: white,
            textAlign: "center",
            marginBottom: "38px",
          }}
        >
          Drill down viewer
        </Typography>
        <Grid container sx={{ width: "200px" }}>
          <Grid xs={12} visibility>
            <Stack
              direction="column"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                visibility:
                  mapState.parentNode && mapState.parentNode.name
                    ? "visible"
                    : "hidden",
              }}
            >
              <NavigationDot
                label={mapState.parentNode && mapState.parentNode.name}
                link={mapState.parentNode && paths[mapState.parentNode.name]}
                labelStyles={centerLabelStyles}
                isActive={
                  mapState.parentNode &&
                  mapState.currentNode &&
                  mapState.parentNode.name === mapState.currentNode.name
                }
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  visibility:
                    mapState.parentNode &&
                    mapState.currentNode &&
                    mapState.parentNode.name !== mapState.currentNode.name
                      ? "visible"
                      : "hidden",
                }}
              >
                <NavigationArrowVertical />
              </Box>
            </Stack>
          </Grid>
          <Grid xs={12}>
            <Stack
              direction="row"
              sx={{
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  visibility:
                    mapState.parentNode && mapState.downstreamNode
                      ? "visible"
                      : "hidden",
                }}
              >
                <NavigationDot
                  label={
                    mapState.downstreamNode && mapState.downstreamNode.name
                  }
                  link={
                    mapState.downstreamNode &&
                    paths[mapState.downstreamNode.name]
                  }
                  labelStyles={labelStyles}
                />
                <NavigationArrow />
              </Stack>
              <Box
                sx={{
                  visibility:
                    mapState.parentNode &&
                    mapState.currentNode &&
                    mapState.parentNode.name !== mapState.currentNode.name
                      ? "visible"
                      : "hidden",
                }}
              >
                <NavigationDot
                  label={mapState.currentNode && mapState.currentNode.name}
                  link={
                    mapState.currentNode && paths[mapState.currentNode.name]
                  }
                  labelStyles={labelStyles}
                  isActive={
                    mapState.parentNode &&
                    mapState.currentNode &&
                    mapState.parentNode.name !== mapState.currentNode.name
                  }
                />
              </Box>
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  visibility:
                    mapState.parentNode && mapState.upstreamNode
                      ? "visible"
                      : "hidden",
                }}
              >
                <NavigationArrow />
                <NavigationDot
                  label={mapState.upstreamNode && mapState.upstreamNode.name}
                  link={
                    mapState.upstreamNode && paths[mapState.upstreamNode.name]
                  }
                  labelStyles={labelStyles}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NavMiniMap;
