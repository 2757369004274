import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const MuiIconWrapper = ({ children, size = 44, height = 44 }) => {
  return (
    <SvgIcon
      viewBox={`0 0 ${size} ${height}`}
      width={size}
      height={height}
      fill="none"
      sx={{
        height: `${height}px`,
        width: `${size}px`,
      }}
    >
      {children && children}
    </SvgIcon>
  );
};

export default MuiIconWrapper;
