import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const RedArrowDown = ({ size = 18, height = 15 }) => {
  return (
    <MuiIconWrapper size={size} height={height}>
      <svg
        width={size}
        height={height}
        viewBox={`0 0 ${size} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 15L0.339746 -1.63133e-06L17.6603 -1.17124e-07L9 15Z"
          fill="#EF6A6B"
        />
      </svg>
    </MuiIconWrapper>
  );
};
