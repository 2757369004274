import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { white } from "../../constants/style";

const Title = ({ title, titleStyles }) => {
  return (
    <Typography
      sx={{
        color: white,
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: 400,
        paddingBottom: "8px",
        ...titleStyles,
      }}
    >
      {title}
    </Typography>
  );
};

const GridItem = ({
  value,
  title,
  titleStyles,
  units,
  fontWeight = 700,
  titleLocation = "top",
  gridItemProps,
}) => {
  return (
    <Grid item {...gridItemProps}>
      <Stack>
        {title && titleLocation === "top" && (
          <Title title={title} titleStyles={titleStyles} />
        )}
        <Stack direction="row" sx={{ alignItems: "flex-end" }}>
          {value && (
            <Typography
              sx={{
                color: white,
                fontSize: "32px",
                lineHeight: "30px",
                paddingBottom: "8px",
                fontWeight: fontWeight,
              }}
            >
              {value}
            </Typography>
          )}
          {units && (
            <Typography
              sx={{
                color: white,
                paddingLeft: "8px",
                fontSize: "20px",
                lineHeight: "16px",
                paddingBottom: "10px",
                fontWeight: fontWeight,
              }}
            >
              {units}
            </Typography>
          )}
        </Stack>
        {title && titleLocation !== "top" && (
          <Title title={title} titleStyles={titleStyles} />
        )}
      </Stack>
    </Grid>
  );
};

export default GridItem;
