import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const RedArrowUp = ({ size = 18, height = 15 }) => {
  return (
    <MuiIconWrapper size={size} height={height}>
      <svg
        width={size}
        height={height}
        viewBox={`0 0 ${size} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#EF6A6B" />
      </svg>
    </MuiIconWrapper>
  );
};
