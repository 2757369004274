import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useSiteConfig } from "../../_shared/context/siteChangeTester.context";
import networkService from "../../_shared/services/network.service";
import { appConstants } from "../../_shared/config/app.config";
import { useLoaderContext } from "../../_shared/context/loader.context";


const siteConfigLabels = ["Client", "Site", "Timestamp"];
const siteConfigPools = ["clients", "sites", "timestamps"];
const OVERLAY_LAYER = 1;
const FORM_LAYER = 2;

const SiteConfig = () => {
  const {startLoader, endLoader} = useLoaderContext();
  const { showSiteConfig, setShowSiteConfig, siteConfig, setSiteConfig } =
    useSiteConfig();

  const handleRequestClick = () => {
    setShowSiteConfig(!showSiteConfig);
  };

  const handleChangeClient = (e) => {
    const newClientInfo = siteConfig.authMasterData.find(client => client.clientName === e.target.value);
    const newClientSpecificSites = newClientInfo.sites.map(sites => sites.site);

    const newSiteConfig = { ...siteConfig, ...{
      selectedClient: e.target.value,
      sites: newClientSpecificSites,
      selectedSite: newClientSpecificSites[0]
    } };
    setSiteConfig(newSiteConfig);
    // getTimestamps(newSiteConfig);
  };

  const handleChangeSite = (e) => {
    const newSiteConfig = { ...siteConfig, ...{
      selectedSite: e.target.value
    } };
    setSiteConfig(newSiteConfig);
    // getTimestamps(newSiteConfig);
  };

  const handleChangeTimestamp = (e) => {
    setSiteConfig({ ...siteConfig, selectedTimestamp: e.target.value });
  };

  // const getTimestamps = (config) => {
  //   startLoader();
  //   networkService.get(appConstants.urls.getTimestamps(config.selectedClient, config.selectedSite)).then((response) => {
  //     config.timestamps = response.data;
  //     config.selectedTimestamp = response.data[response.data.length - 1];
  //     setSiteConfig(config);
  //     endLoader();
  //   })
  //   .catch(error => {
  //     config.timestamps = [];
  //     config.selectedTimestamp = "";
  //     setSiteConfig(config);
  //     endLoader();
  //   });
  // };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          background: "black",
          opacity: 0.5,
          position: "absolute",
          zIndex: OVERLAY_LAYER,
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
      ></Box>
      <Paper
        sx={{
          width: "500px",
          padding: "40px",
          position: "absolute",
          zIndex: FORM_LAYER,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Stack>
          <TextField
            key={0}
            select
            sx={{ marginBottom: "20px" }}
            label={siteConfigLabels[0]}
            variant="outlined"
            value={siteConfig.selectedClient}
            onChange={handleChangeClient}
          >
            {siteConfig[siteConfigPools[0]].map((option) => (
              <MenuItem key={option} value={option} id={siteConfigLabels[0]}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            key={1}
            select
            sx={{ marginBottom: "20px" }}
            label={siteConfigLabels[1]}
            variant="outlined"
            value={siteConfig.selectedSite}
            onChange={handleChangeSite}
          >
            {siteConfig[siteConfigPools[1]].map((option) => (
              <MenuItem key={option} value={option} id={siteConfigLabels[1]}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            key={2}
            select
            sx={{ marginBottom: "20px" }}
            label={siteConfigLabels[2]}
            variant="outlined"
            value={siteConfig.selectedTimestamp}
            onChange={handleChangeTimestamp}
          >
            {siteConfig[siteConfigPools[2]].map((option) => (
              <MenuItem key={option} value={option} id={siteConfigLabels[2]}>
                {option}
              </MenuItem>
            ))}
          </TextField> */}
          <Button onClick={handleRequestClick} variant="contained">
            Close Config
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default SiteConfig;
