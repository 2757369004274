/**
 * TODO: write logic to generate this per the mockData navigation JSON
 * (which should be generated serverside)
 */
const assetStrategyPosition = 0;
const integratedPlanningPosition = 1;
const assetPerformancePosition = 2;

const viewHierarchyLegend = {
  Home: {
    location: [],
    parent: "",
  },
  "Integrated Ops": {
    location: [integratedPlanningPosition],
    parent: "",
  },
  "Mine Plan Optimization": {
    location: [integratedPlanningPosition, "children", 0],
    parent: "Integrated Ops",
  },
  "Maintenance Plan Optimization": {
    location: [integratedPlanningPosition, "children", 1],
    parent: "Integrated Ops",
  },
  "Labor Optimization": {
    location: [integratedPlanningPosition, "children", 2],
    parent: "Integrated Ops",
  },
  "Asset Performance": {
    location: [assetPerformancePosition],
    parent: "",
  },
  "Mine Performance": {
    location: [assetPerformancePosition, "children", 0],
    parent: "Asset Performance",
  },
  "Mill Performance": {
    location: [assetPerformancePosition, "children", 1],
    parent: "Asset Performance",
    link: "",
  },
  Grinding: {
    location: [assetPerformancePosition, "children", 1, "children", 0],
    parent: "Mill Performance",
    link: "",
  },
  "Grinding Recommendation": {
    location: [
      assetPerformancePosition,
      "children",
      1,
      "children",
      0,
      "children",
      0,
    ],
    parent: "Grinding",
    link: "",
  },
  "Grinding Model Performance": {
    location: [
      assetPerformancePosition,
      "children",
      1,
      "children",
      0,
      "children",
      1,
    ],
    parent: "Grinding",
    link: "",
  },
  "Grinding Downtime Prediction": {
    location: [
      assetPerformancePosition,
      "children",
      1,
      "children",
      0,
      "children",
      2,
    ],
    parent: "Grinding",
    link: "",
  },
  Flotation: {
    location: [assetPerformancePosition, "children", 1, "children", 1],
    parent: "Mill Performance",
    link: "",
  },
  "Flotation Recommendations": {
    location: [
      assetPerformancePosition,
      "children",
      1,
      "children",
      1,
      "children",
      0,
    ],
    parent: "Flotation",
    link: "",
  },
  "Flotation Model Performance": {
    location: [
      assetPerformancePosition,
      "children",
      1,
      "children",
      1,
      "children",
      1,
    ],
    parent: "Flotation",
    link: "",
  },
  "Flotation Downtime Prediction": {
    location: [
      assetPerformancePosition,
      "children",
      1,
      "children",
      1,
      "children",
      2,
    ],
    parent: "Flotation",
    link: "",
  },
  Environment: {
    location: [assetPerformancePosition, "children", 2],
    parent: "Asset Performance",
    link: "",
  },
  "Asset Strategy": {
    location: [assetStrategyPosition],
    parent: "",
    link: "",
  },
  "Field Tested Value": {
    location: [assetStrategyPosition, "children", 0],
    parent: "Asset Strategy",
    link: "",
  },
  "Full Potential Value": {
    location: [assetStrategyPosition, "children", 1],
    parent: "Asset Strategy",
    link: "",
  },
};

export default viewHierarchyLegend;
