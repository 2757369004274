import { lightgreen, white, yellow1, purple1 } from "../../../constants/style";

/**
 * TODO:
 * - Change the hardcoded "9" to current date month. Keeping for demo sake
 */
const getDateStrings = () => {
  let counter = 1;
  let dateStringArray = [];

  while (counter < 31) {
    dateStringArray.push(`11\\${counter}`);
    counter++;
  }

  return dateStringArray;
};

const dateStrings = getDateStrings();

const seriesConfig = [
  {
    data: [],
    showInLegend: true,

    color: lightgreen,
    borderColor: lightgreen,
    borderRadiusTopLeft: 1,
    borderRadiusTopRight: 1,
  },
  {
    data: [],
    showInLegend: true,
    color: "#FCBB86",
    borderColor: "#FCBB86",
  },
  {
    data: [],
    showInLegend: true,
    color: purple1,
    borderColor: purple1,
  },
  {
    data: [],
    showInLegend: true,
    type: "line",
    color: yellow1,
    borderColor: yellow1,
  },
];

const currentDate = "11\\16";

const isFutureDate = (currentDate, date) => {
  const day = currentDate.split("\\")[1];
  return date >= day;
};

const tickPositions = [
  "11\\1",
  "11\\7",
  "11\\13",
  "11\\19",
  "11\\25",
  "11\\29",
];

const constructSeries = (data) => {
  data.forEach((series, index) => {
    seriesConfig[index].name = series.Name;
    const newData = series.Data.map((item, i) => {
      return {
        y: item,
        category: dateStrings[i],
        ...(series.Name.toLowerCase().startsWith("opt") &&
          isFutureDate(currentDate, i) && {
            color: purple1,
            borderColor: purple1,
          }),
      };
    });

    seriesConfig[index].data = newData;
  });
  return seriesConfig;
};

export const copperProd = (height, data) => {
  return {
    title: {
      text: undefined,
    },
    chart: {
      height: height || 235,
      backgroundColor: "transparent",
      type: "column",
    },
    legend: {
      itemStyle: {
        color: white,
        pointerEvents: "none",
      },
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 6,
      align: "left",
    },
    xAxis: {
      title: {
        text: undefined,
      },
      categories: dateStrings,
      alignTicks: false,
      labels: {
        enabled: true,
        style: {
          color: white,
        },
        formatter: function () {
          if (tickPositions.includes(this.value)) {
            return this.value;
          }
          if (this.isFirst) {
            return this.value;
          }
        },
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      opposite: true,
      labels: {
        style: {
          color: white,
        },
      },
      tickPositions: [0, 200, 400, 600, 800],
      gridLineColor: "#5e616f",
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0.15,
        stacking: "normal",
        enableMouseTracking: false,
        pointWidth: 10,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: constructSeries(data),
  };
};
