import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { white } from "../../../constants/style";
import { getStatusColor } from "../../../utils";

const GraphTitle = ({ data }) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        marginBottom: "10px",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: 700, color: white }}>
        {data.Display_Name}
      </Typography>
      {data.Forecast && data.Forecast.Status_Message && (
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            color: getStatusColor(data.Forecast.Status).color,
          }}
        >
          {data.Forecast.Status_Message}
        </Typography>
      )}
    </Stack>
  );
};

export default GraphTitle;
