import React from "react";
import { useHistory, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Grid from "@mui/material/Grid";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Router from "./router/Router";
import Login from "./views/Login/Login";
import { Error403, Error404, Error500 } from "./views/Error";
import Loader from "./components/loader/loader";

const App = () => {

  // Okta configuration //.
  const config = {
    issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}${process.env.REACT_APP_OKTA_REDIRECT_URI}`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
  };
  const oktaAuthInstance = new OktaAuth(config);


  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  
  // Redirect to login screen if not authenticated//
  const customAuthHandler = () => {
    history.push("/login");
  };

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        columns={28}
        sx={{ width: "100%", height: "100%" }}
      >
        <Security
          oktaAuth={oktaAuthInstance}
          restoreOriginalUri={restoreOriginalUri}
          onAuthRequired={customAuthHandler}
        >
          <Switch>
            <Route path="/login" exact render={() => <Login />} />
            <Route path="/login/callback" exact component={LoginCallback} />
            <Route path="/unauthorized" exact render={() => <Error403 />} />
            <Route path="/error" exact render={() => <Error500 />} />
            <Router />
            <Route path="*" exact render={() => <Error404 />} />
          </Switch>
        </Security>
      </Grid>
      <Loader/>
    </>
  );
};

export default App;
