import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { CardGridItem } from "./CardGridItem";

const CardGrid = ({ children, showSkeleton, showNavigation }) => {
  return (
    <Grid
      wrap="wrap"
      direction="row"
      container
      rowSpacing="36px"
      sx={{
        position: "relative",
        justifyContent: "space-between",
      }}
      className="CardGrid-root"
    >
      {showSkeleton ? (
        <>
          {[1, 2, 3, 4].map((item) => {
            return (
              <CardGridItem key={item}>
                <Skeleton
                  variant="rectangular"
                  width={280}
                  height={420}
                  sx={{ borderRadius: "8px", padding: "20px" }}
                />
              </CardGridItem>
            );
          })}
        </>
      ) : (
        <>{children && children}</>
      )}
    </Grid>
  );
};

export default CardGrid;
