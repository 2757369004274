import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SecureRoute } from "@okta/okta-react";
import Grid from "@mui/material/Grid";
import routeConfig from "./routeConfig";
import { NavDrawer } from "../components/Navigation";
import { NavMiniMap } from "../components/NavMiniMap";
import { useSiteConfig } from "../_shared/context/siteChangeTester.context";
import { SiteConfig } from "../components/SiteConfig";
import { useOktaAuth } from "@okta/okta-react";
import networkService from "../_shared/services/network.service";
import { useUserInfo } from "../_shared/context/userInfo.context";
import { appConstants } from "../_shared/config/app.config";
import { Redirect, useHistory } from "react-router-dom";
import { useLoaderContext } from "../_shared/context/loader.context";
import BasePage from "../views/BasePage";

const Router = () => {
  const { setSiteConfig, showSiteConfig } = useSiteConfig();
  const { oktaAuth, authState } = useOktaAuth();
  const { userDetails, setUserDetails } = useUserInfo();
  const history = useHistory();
  const location = useLocation();
  const { startLoader, endLoader } = useLoaderContext();
  const roleBasedLandingScreens =
    appConstants.staticValues.roleBasedLandingScreens;

  const shouldRenderMiniMap = () => {
    if (location.pathname === "/") {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated && !userDetails) {
      getUserInfo();
    } else if (authState && !authState.isAuthenticated) {
      history.push("/login");
    }
  }, [authState]);

  const getUserInfo = () => {
    startLoader();
    networkService
      .get(appConstants.urls.getUserInfo)
      .then((response) => {
        setUserDetails(response.data);
        const authMasterData = response.data.access_data;
        const siteConfigData = {
          clients: authMasterData.map((clients) => clients.clientName),
          selectedClient: authMasterData[0].clientName,
          sites: authMasterData[0].sites.map((sites) => sites.site),
          selectedSite: authMasterData[0].sites[0].site,
          authMasterData,
        };
        setSiteConfig(siteConfigData);

        endLoader();
      })
      .catch((error) => {
        if (error.status === 500) {
          history.push("/error");
          return;
        }
        oktaAuth.signOut();
      });
  };

  return (
    <>
      {userDetails && (
        <>
          <Grid item xs="auto">
            <NavDrawer />
          </Grid>
          <Grid
            item
            sm={28}
            lg={shouldRenderMiniMap() ? 20 : 28}
            sx={{
              width: "100%",
            }}
          >
            <BasePage>
              {routeConfig &&
                routeConfig.map((route, index) => {
                  return (
                    <SecureRoute
                      key={index}
                      path={route.path}
                      render={() => {
                        if (userDetails.ui_screens.includes(route.routeName)) {
                          return route.element;
                        } else {
                          return (
                            <Redirect
                              to={
                                routeConfig.find(
                                  (item) =>
                                    item.routeName === userDetails.ui_screens[0]
                                ).path
                              }
                            />
                          );
                        }
                      }}
                      exact
                    />
                  );
                })}
            </BasePage>
          </Grid>
          {shouldRenderMiniMap() && (
            <Grid item sm={0} lg={shouldRenderMiniMap() ? 4 : 0}>
              <NavMiniMap />
            </Grid>
          )}
          {showSiteConfig && <SiteConfig />}
        </>
      )}
    </>
  );
};

export default Router;
