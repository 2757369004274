import { white } from "../../../constants/style";

const baseConfiguration = {
  categories: ["Planned", "Unplanned"],
};

export const horizontalColumnConfig = (height, data) => {
  return {
    title: {
      text: "",
    },
    chart: {
      height: height,
      backgroundColor: "transparent",
      type: "bar",
    },
    legend: {
      enabled: false,
      itemStyle: {
        color: white,
      },
    },
    xAxis: {
      title: {
        text: undefined,
      },
      alignTicks: false,
      opposite: true,
      categories: baseConfiguration.categories,
      labels: {
        style: {
          color: white,
        },
      },
      lineColor: "#666666",
      gridLineColor: "#666666",
    },

    yAxis: {
      title: {
        text: undefined,
      },
      reversed: true,
      opposite: true,
      labels: {
        style: {
          color: white,
        },
      },
      lineWidth: 1,
      lineColor: "#666666",
      gridLineColor: "#323232",
      tickPositions: data.yAxis.tickPositions,
    },

    plotOptions: {
      series: {
        pointPadding: 1,
        pointWidth: 9,
        enableMouseTracking: false,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        showInLegend: true,
        data: data.Data,
        color: "#DAE085",
        borderColor: "#DAE085",
        borderRadiusBottomRight: 1,
        borderRadiusBottomLeft: 1,
      },
    ],
  };
};
