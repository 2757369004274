import { viewMap } from "../constants/paths";

const generateRoutes = (routes = []) => {
  const keys = Object.keys(viewMap);

  return keys.map((view) => {
    return {
      path: viewMap[view].path,
      exact: true,
      element: viewMap[view].element,
      routeName: viewMap[view].routeName
    };
  });
};

const ROUTES = [...generateRoutes()];

export default ROUTES;
