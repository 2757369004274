import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const EclipseDark = ({ size = 60, height = 60 }) => {
  return (
    <MuiIconWrapper size={size} height={height}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="-0.5"
          x2="13.1271"
          y2="-0.5"
          transform="matrix(-0.12815 -0.991755 0.991443 -0.130539 30.2803 58.8679)"
          stroke="white"
        />
        <path
          d="M49.0656 50.6603L30.5609 59.1509L6.72916 46.6981M49.0656 50.6603L56.3553 41.6037M49.0656 50.6603L28.5983 46.6981M56.3553 41.6037L58.8787 28.3018M56.3553 41.6037L46.8226 32.8301M56.3553 41.6037L28.5983 46.6981M58.8787 28.3018L52.9908 11.8867M58.8787 28.3018L46.8226 32.8301M52.9908 11.8867L38.2119 1.30823M52.9908 11.8867L46.8226 32.8301M52.9908 11.8867L37.5703 9.05655M38.2119 1.30823L23.2712 0.848999M38.2119 1.30823L19.9067 9.05655M38.2119 1.30823L37.5703 9.05655M23.2712 0.848999L5.88804 13.8679M23.2712 0.848999L19.9067 9.05655M5.88804 13.8679L0.841309 29.4339M5.88804 13.8679L19.9067 25.4716M5.88804 13.8679L19.9067 9.05655M0.841309 29.4339L6.72916 46.6981M0.841309 29.4339L19.9067 25.4716M0.841309 29.4339L21.589 43.3018L28.5983 46.6981M6.72916 46.6981H28.5983M28.5983 46.6981L46.8226 32.8301M28.5983 46.6981L19.9067 25.4716M46.8226 32.8301L37.5703 9.05655M46.8226 32.8301L19.9067 25.4716M19.9067 25.4716V9.05655M19.9067 25.4716L37.5703 9.05655M19.9067 9.05655H37.5703"
          stroke="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="47.1029"
          cy="32.8302"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="58.8783"
          cy="28.3019"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="58.8783"
          cy="28.3019"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="58.8783"
          cy="28.3019"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="58.8783"
          cy="28.3019"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="22.9906"
          cy="1.13208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="22.9906"
          cy="1.13208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="22.9906"
          cy="1.13208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="22.9906"
          cy="1.13208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="9.05664"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="9.05664"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="9.05664"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="9.05664"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="1.1215"
          cy="28.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="1.1215"
          cy="28.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="1.1215"
          cy="28.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="1.1215"
          cy="28.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="30.2802"
          cy="58.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="30.2802"
          cy="58.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="30.2802"
          cy="58.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="30.2802"
          cy="58.8679"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="38.1308"
          cy="1.69812"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="38.1308"
          cy="1.69812"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="38.1308"
          cy="1.69812"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="38.1308"
          cy="1.69812"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="24.9056"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="24.9056"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="24.9056"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="20.1869"
          cy="24.9056"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="49.3456"
          cy="50.3773"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="49.3456"
          cy="50.3773"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="49.3456"
          cy="50.3773"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="49.3456"
          cy="50.3773"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="56.6357"
          cy="41.3208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="56.6357"
          cy="41.3208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="56.6357"
          cy="41.3208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="56.6357"
          cy="41.3208"
          rx="1.1215"
          ry="1.13208"
          fill="white"
        />
        <ellipse
          cx="6.72883"
          cy="46.9812"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="6.72883"
          cy="46.9812"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="6.72883"
          cy="46.9812"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="6.72883"
          cy="46.9812"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="53.2708"
          cy="11.3208"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="53.2708"
          cy="11.3208"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="53.2708"
          cy="11.3208"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="53.2708"
          cy="11.3208"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="5.60725"
          cy="14.151"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="5.60725"
          cy="14.151"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="5.60725"
          cy="14.151"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="5.60725"
          cy="14.151"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="37.5701"
          cy="9.62267"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="37.5701"
          cy="9.62267"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="37.5701"
          cy="9.62267"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="37.5701"
          cy="9.62267"
          rx="2.24299"
          ry="2.26415"
          fill="white"
        />
        <ellipse
          cx="28.5982"
          cy="46.4151"
          rx="2.80374"
          ry="2.83019"
          fill="white"
        />
        <ellipse
          cx="28.5982"
          cy="46.4151"
          rx="2.80374"
          ry="2.83019"
          fill="white"
        />
        <ellipse
          cx="28.5982"
          cy="46.4151"
          rx="2.80374"
          ry="2.83019"
          fill="white"
        />
        <ellipse
          cx="28.5982"
          cy="46.4151"
          rx="2.80374"
          ry="2.83019"
          fill="white"
        />
      </svg>
    </MuiIconWrapper>
  );
};
