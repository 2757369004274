import React from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { defaultCardStyles, defaultDividerStyles } from "./StatusCard.styles";

const StatusCard = ({ children, showDividers, cardStyles, dividerStyles }) => {
  return (
    <Card elevation={0} sx={{ ...defaultCardStyles, ...cardStyles }}>
      <Stack
        sx={{
          height: "100%",
          minHeight: "420px",
          justifyContent: "space-between",
        }}
        divider={
          showDividers ? (
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ ...defaultDividerStyles, ...dividerStyles }}
            />
          ) : null
        }
      >
        {children && children}
      </Stack>
    </Card>
  );
};

export default StatusCard;
