import React from 'react';
import { useLoaderContext } from '../../_shared/context/loader.context';
import { ThreeDots } from  'react-loader-spinner'

const Loader = () => {
  const {loaderCount} = useLoaderContext();
  return (
    <>
      { loaderCount > 0 && (
          <div className="api-loader">
            <ThreeDots color="#218838" height={80} width={80} />
          </div>
        )
      }
    </>
  );
};
export default Loader;