import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Drawer from "@mui/material/Drawer";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import appNavigation from "../../mockData/navigation_test_2.json";
import phosaLogoWhite from "../../icons/phosaLogo-white.svg";
import customerLogo from "../../icons/customer_logo.svg";
import { gray1, gray2, gray3, white } from "../../constants/style";
import { paths } from "../../constants/paths";
import { useUserInfo } from "../../_shared/context/userInfo.context";
import { useOktaAuth } from "@okta/okta-react";
import { useSiteConfig } from "../../_shared/context/siteChangeTester.context";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLoaderContext } from "../../_shared/context/loader.context";

const NavDrawer = () => {
  const [expand, setExpand] = useState({});
  const [navWidth, setNavWidth] = useState(260);
  const history = useHistory();
  const { userDetails } = useUserInfo();
  let location = useLocation();
  const paddingIncrement = useRef();
  const drawerRef = useRef();
  let { showSiteConfig, setShowSiteConfig } = useSiteConfig();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { oktaAuth } = useOktaAuth();
  const { startLoader } = useLoaderContext();

  const updateState = (pageName, state) => {
    const newState = { ...(state || expand) };

    if (newState[pageName]) {
      delete newState[pageName];
    } else {
      newState[pageName] = true;
    }

    return newState;
  };

  const determineExpansion = (pageName) => {
    setExpand(updateState(pageName, expand));
  };

  const showMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const hideMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (item) => {
    if (paths[item] && location.pathname !== paths[item]) {
      history.push({
        pathname: paths[item],
        state: { expand: updateState(item, expand) },
      });
    } else {
      determineExpansion(item);
    }
  };

  const handleLogout = () => {
    hideMenu();
    startLoader();
    oktaAuth.signOut();
  };

  const handleAccountClick = () => {
    hideMenu();
    setShowSiteConfig(!showSiteConfig);
  };

  const getPadding = () => {
    const basePadding = 10;
    const padding = paddingIncrement.current
      ? basePadding
      : paddingIncrement.current * basePadding;
    paddingIncrement.current += 1;
    return padding;
  };

  useEffect(() => {
    if (location.pathname) {
      determineExpansion(paths[location.pathname]);
    }
  }, [location.pathname]);

  const renderListItem = (item, index) => {
    return (
      <Box key={index}>
        <ListItem
          button
          onClick={() => handleClick(item.name)}
          key={index}
          disablePadding
          sx={{
            padding: "10px",
            backgroundColor:
              location.pathname === paths[item.name] ? gray3 : "transparent",
            marginBottom: "5px",
            borderRadius: "4px",
            "&:hover": { backgroundColor: gray3 },
          }}
        >
          <ListItemText
            primary={item.name}
            sx={{
              color: white,
              paddingLeft: `${getPadding()}px`,
            }}
          />
          {item.children.length > 0 &&
            (expand[item.name] ? (
              <ExpandLessIcon sx={{ color: gray1 }} />
            ) : (
              <ExpandMoreIcon sx={{ color: gray1 }} />
            ))}
        </ListItem>
        {item.children.length > 0 &&
          renderCollpaseList(expand[item.name], item.children)}
      </Box>
    );
  };

  const renderCollpaseList = (expand, children) => {
    return (
      <Collapse in={expand} unmountOnExit>
        {children.map((child, index) => {
          return (
            <List key={index} disablePadding>
              {renderListItem(child)}
            </List>
          );
        })}
      </Collapse>
    );
  };

  const renderList = () => (
    <Box>
      <List
        sx={{
          "& > *": {
            marginBottom: "10px",
          },
          "& > *:last-child": {
            marginBottom: 0,
          },
        }}
      >
        {appNavigation.pageHierarchy.map((item, index) => {
          paddingIncrement.current = 0;
          return renderListItem(item, index);
        })}
      </List>
    </Box>
  );

  useEffect(() => {
    if (drawerRef && drawerRef.current) {
      setNavWidth(drawerRef.current.clientWidth);
    }
  }, []);

  return (
    <Drawer
      anchor={"left"}
      variant="permanent"
      ref={drawerRef}
      sx={{
        height: "100%",
        width: `${navWidth}px`,
        "& .MuiPaper-root ": {
          width: `${navWidth}px`,
          background: "transparent",
          color: white,
          borderRightColor: gray2,
          padding: "30px 8px",
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "50px",
        }}
      >
        <Link to="/">
          <img src={phosaLogoWhite} alt="Phosa logo" />
        </Link>
        <Link to="/">
          <img src={customerLogo} alt="Customer logo" />
        </Link>
      </Stack>
      {renderList()}
      {userDetails && (
        <Stack
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{
            marginTop: "auto",
          }}
        >
          <AccountCircleIcon
            sx={{
              color: gray1,
              height: "33px",
              width: "33px",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#fff",
              fontWeight: 500,
              padding: "5px 0",
            }}
          >
            {`${userDetails.firstName} ${userDetails.lastName}`}
          </Typography>

          <MoreVertIcon
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={showMenu}
            sx={{
              marginLeft: "auto",
              color: gray1,
              height: "45px",
              cursor: "pointer",
              padding: "10px 0",
            }}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={hideMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{ marginRight: "30px" }}
          >
            <MenuItem onClick={handleAccountClick}>Configure </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Stack>
      )}
    </Drawer>
  );
};

export default NavDrawer;
