import React from "react";
import { default as MuiDivider } from "@mui/material/Divider";
import { gray8, gray7 } from "../../constants/style";

const Divider = ({
  dividerStyles,
  orientation = "horizontal",
  thickness = 2,
}) => {
  const lineThickness =
    orientation === "horizontal"
      ? { height: `${thickness}px` }
      : { width: `${thickness}px` };

  const lineShading =
    orientation === "horizontal"
      ? { borderBottom: `${thickness / 2}px solid ${gray7}` }
      : { borderRight: `${thickness / 2}px solid ${gray7}` };

  return (
    <MuiDivider
      orientation={orientation}
      flexItem
      sx={{
        background: gray8,
        opacity: 1,
        ...lineThickness,
        ...lineShading,
        ...dividerStyles,
      }}
    />
  );
};

export default Divider;
