import React, { createContext, useContext, useState } from 'react';

const defaultVal = 0;

export const LoaderContext = createContext(defaultVal);
export const useLoaderContext = () => useContext(LoaderContext);

const LoaderProvider = ({ children }) => {
  const [loaderCount, setloaderCount] = useState(defaultVal);

  const startLoader = () => {
    setloaderCount((prevValue) => prevValue + 1);
  }

  const endLoader = () => {
    setloaderCount((prevValue) => prevValue - 1);
  }

  const value = {
    loaderCount,
    startLoader,
    endLoader
  }

  return (
    <LoaderContext.Provider value={value}>{children && children}</LoaderContext.Provider>
  )
}


export default LoaderProvider;