import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const LoadingDeactive = ({ width = 100, height = 100 }) => {
  return (
    <MuiIconWrapper size={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50" cy="50" r="50" fill="#191D24" />
        <circle
          cx="50"
          cy="50"
          r="48.5"
          stroke="url(#paint0_linear_2723_13518)"
          strokeOpacity="0.8"
          strokeWidth="3"
        />
        <g opacity="0.5">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.3839 51.9312H28.5932C25.5549 51.9312 23.092 54.4528 23.092 57.5634C23.092 60.6739 25.5549 63.1955 28.5932 63.1955H51.3839C54.4221 63.1955 56.8851 60.6739 56.8851 57.5634C56.8851 54.4528 54.4221 51.9312 51.3839 51.9312ZM50.598 54.345H29.379C27.6429 54.345 26.2355 55.7859 26.2355 57.5634C26.2355 59.3408 27.6429 60.7817 29.379 60.7817H50.598C52.3341 60.7817 53.7415 59.3408 53.7415 57.5634C53.7415 55.7859 52.3341 54.345 50.598 54.345Z"
            fill="white"
          />
          <path
            d="M48.0345 57.5634C48.0345 58.8965 49.1152 59.9771 50.4483 59.9771C51.7814 59.9771 52.8621 58.8965 52.8621 57.5634C52.8621 56.2303 51.7814 55.1496 50.4483 55.1496C49.1152 55.1496 48.0345 56.2303 48.0345 57.5634Z"
            fill="white"
          />
          <path
            d="M27.5172 57.5634C27.5172 58.8965 28.5979 59.9771 29.931 59.9771C31.2641 59.9771 32.3448 58.8965 32.3448 57.5634C32.3448 56.2303 31.2641 55.1496 29.931 55.1496C28.5979 55.1496 27.5172 56.2303 27.5172 57.5634Z"
            fill="white"
          />
          <path
            d="M33.5517 57.1611C33.5517 57.8276 34.0921 58.368 34.7586 58.368C35.4252 58.368 35.9655 57.8276 35.9655 57.1611C35.9655 56.4945 35.4252 55.9542 34.7586 55.9542C34.0921 55.9542 33.5517 56.4945 33.5517 57.1611Z"
            fill="white"
          />
          <path
            d="M40.7931 57.1611C40.7931 57.8276 41.3335 58.368 42 58.368C42.6666 58.368 43.2069 57.8276 43.2069 57.1611C43.2069 56.4945 42.6666 55.9542 42 55.9542C41.3335 55.9542 40.7931 56.4945 40.7931 57.1611Z"
            fill="white"
          />
          <path
            d="M37.1724 57.1611C37.1724 57.8276 37.7128 58.368 38.3793 58.368C39.0459 58.368 39.5862 57.8276 39.5862 57.1611C39.5862 56.4945 39.0459 55.9542 38.3793 55.9542C37.7128 55.9542 37.1724 56.4945 37.1724 57.1611Z"
            fill="white"
          />
          <path
            d="M44.4138 57.1611C44.4138 57.8276 44.9541 58.368 45.6207 58.368C46.2872 58.368 46.8276 57.8276 46.8276 57.1611C46.8276 56.4945 46.2872 55.9542 45.6207 55.9542C44.9541 55.9542 44.4138 56.4945 44.4138 57.1611Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.7931 52.7358L67.3448 30.207L65.3333 30.6093L64.1264 34.6323L63.3218 33.8277L48.8391 43.0806L45.2184 34.6323H38.3793V44.2875H35.9655V42.276C35.9655 41.6804 35.6419 41.1604 35.1609 40.8821V37.4484H34.9216C34.6726 36.5354 33.9245 35.828 32.9885 35.6381V36.7387C33.3219 36.8842 33.599 37.1349 33.7783 37.4484H33.5517V40.8821C33.0707 41.1604 32.7471 41.6804 32.7471 42.276V44.2875H27.1149C25.1035 44.2875 25.1035 44.9312 25.1035 45.8967V51.5289H48.8391L57.538 43.5353L55.8697 47.2961L55.4128 47.0934L52.4764 53.7126L54.6829 54.6915L57.6192 48.072L56.973 47.7854L59.7012 41.6352L59.6368 41.6067L63.7241 37.8507L72.1724 52.3335C71.0996 53.6745 67.9081 55.7127 63.7241 53.1381L63.3218 53.5404L61.7126 51.9312L60.908 52.3335L63.3218 55.5519L63.7241 55.1496C65.3333 57.1611 69.1954 61.2646 71.7701 61.5863C74.3448 61.9081 75.7931 57.1611 76.1954 54.7473L74.9885 53.5404L75.7931 52.7358ZM45.9374 42.4854C46.4096 42.3744 46.6768 41.8739 46.5065 41.4197L44.6096 36.3613C44.4918 36.0472 44.1916 35.8392 43.8562 35.8392H40.7931C40.3487 35.8392 39.9885 36.1995 39.9885 36.6438V42.8692C39.9885 43.3882 40.4723 43.7713 40.9774 43.6524L45.9374 42.4854Z"
            fill="white"
          />
          <path
            d="M64.6828 31.4542L64.1211 30.5355L58.999 33.6672L58.7663 33.2865L53.2534 36.6572L54.377 38.4948L59.8899 35.1242L59.5607 34.586L64.6828 31.4542Z"
            fill="white"
          />
          <path
            d="M56.0805 53.1379L58.8966 47.5057L58.092 47.1034L60.5057 41.8736L63.7241 39.4598L70.9655 51.931C69.7586 52.7356 67.7471 54.7471 63.7241 51.931L63.3218 52.7356L61.7126 51.1264L59.7011 51.931L63.3218 56.7586L63.7241 56.3563C65.3333 58.3678 68.938 62.3908 72.1724 62.3908C75.3908 62.3908 76.5977 57.1609 77 54.3448L76.1954 53.5402L77 52.7356L67.7471 29L65.3333 29.8046L64.5287 29L58.8966 32.2184H57.6897L51.6552 35.8391L53.4655 38.6552L49.3621 41.4713L45.6207 33.8276H37.5747V43.4828H36.7701C36.7701 42.5441 36.7701 41.069 35.9655 40.6667M31.9425 43.4828H27.1149C27.1149 43.4828 24.2989 43.3034 24.2989 45.092C24.2989 46.8805 24.2989 49.5172 24.2989 51.5287C24.2989 51.5287 24.2989 52.3333 25.1034 52.3333"
            stroke="white"
            strokeLinecap="round"
          />
          <path
            d="M35.9655 40.6668V36.6438H35.5632C34.7586 35.0346 33.5517 34.6323 32.3448 34.6323V37.0461L32.7471 37.4484"
            stroke="white"
            strokeLinecap="round"
          />
          <path
            d="M32.7471 37.4482V40.6666C31.9425 41.4712 31.9425 41.4712 31.9425 43.4827"
            stroke="white"
            strokeLinecap="round"
          />
          <path
            d="M56.0805 53.1381C57.2873 53.9427 57.8684 56.7588 57.6897 58.368C57.2874 61.9887 53.6667 64.0002 52.0575 64.0002H27.9196C25.9081 64.0002 22.2874 61.9887 22.2874 57.5634C22.2874 54.927 23.8966 52.4676 25.1035 52.3335"
            stroke="white"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2723_13518"
            x1="50"
            y1="0"
            x2="50"
            y2="100"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5C5F72" />
            <stop offset="1" stopColor="#10151B" />
          </linearGradient>
        </defs>
      </svg>
    </MuiIconWrapper>
  );
};
