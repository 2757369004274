import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const LoadingDark = ({ width = 60, height = 60 }) => {
  return (
    <MuiIconWrapper size={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3839 35.9312H8.59316C5.55493 35.9312 3.09196 38.4528 3.09196 41.5634C3.09196 44.6739 5.55493 47.1955 8.59316 47.1955H31.3839C34.4221 47.1955 36.8851 44.6739 36.8851 41.5634C36.8851 38.4528 34.4221 35.9312 31.3839 35.9312ZM30.598 38.345H9.37905C7.64292 38.345 6.23551 39.7859 6.23551 41.5634C6.23551 43.3408 7.64292 44.7817 9.37905 44.7817H30.598C32.3341 44.7817 33.7415 43.3408 33.7415 41.5634C33.7415 39.7859 32.3341 38.345 30.598 38.345Z"
          fill="white"
        />
        <path
          d="M28.0345 41.5634C28.0345 42.8965 29.1152 43.9771 30.4483 43.9771C31.7814 43.9771 32.8621 42.8965 32.8621 41.5634C32.8621 40.2303 31.7814 39.1496 30.4483 39.1496C29.1152 39.1496 28.0345 40.2303 28.0345 41.5634Z"
          fill="white"
        />
        <path
          d="M7.51724 41.5634C7.51724 42.8965 8.59794 43.9771 9.93104 43.9771C11.2641 43.9771 12.3448 42.8965 12.3448 41.5634C12.3448 40.2303 11.2641 39.1496 9.93104 39.1496C8.59794 39.1496 7.51724 40.2303 7.51724 41.5634Z"
          fill="white"
        />
        <path
          d="M13.5517 41.1611C13.5517 41.8276 14.0921 42.368 14.7586 42.368C15.4252 42.368 15.9655 41.8276 15.9655 41.1611C15.9655 40.4945 15.4252 39.9542 14.7586 39.9542C14.0921 39.9542 13.5517 40.4945 13.5517 41.1611Z"
          fill="white"
        />
        <path
          d="M20.7931 41.1611C20.7931 41.8276 21.3335 42.368 22 42.368C22.6666 42.368 23.2069 41.8276 23.2069 41.1611C23.2069 40.4945 22.6666 39.9542 22 39.9542C21.3335 39.9542 20.7931 40.4945 20.7931 41.1611Z"
          fill="white"
        />
        <path
          d="M17.1724 41.1611C17.1724 41.8276 17.7128 42.368 18.3793 42.368C19.0459 42.368 19.5862 41.8276 19.5862 41.1611C19.5862 40.4945 19.0459 39.9542 18.3793 39.9542C17.7128 39.9542 17.1724 40.4945 17.1724 41.1611Z"
          fill="white"
        />
        <path
          d="M24.4138 41.1611C24.4138 41.8276 24.9541 42.368 25.6207 42.368C26.2872 42.368 26.8276 41.8276 26.8276 41.1611C26.8276 40.4945 26.2872 39.9542 25.6207 39.9542C24.9541 39.9542 24.4138 40.4945 24.4138 41.1611Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.7931 36.7358L47.3448 14.207L45.3333 14.6093L44.1264 18.6323L43.3218 17.8277L28.8391 27.0806L25.2184 18.6323H18.3793V28.2875H15.9655V26.276C15.9655 25.6804 15.6419 25.1604 15.1609 24.8821V21.4484H14.9216C14.6726 20.5354 13.9245 19.828 12.9885 19.6381V20.7387C13.3219 20.8842 13.599 21.1349 13.7783 21.4484H13.5517V24.8821C13.0707 25.1604 12.7471 25.6804 12.7471 26.276V28.2875H7.11495C5.10345 28.2875 5.10345 28.9312 5.10345 29.8967V35.5289H28.8391L37.538 27.5353L35.8697 31.2961L35.4128 31.0934L32.4764 37.7126L34.6829 38.6915L37.6192 32.072L36.973 31.7854L39.7012 25.6352L39.6368 25.6067L43.7241 21.8507L52.1724 36.3335C51.0996 37.6745 47.9081 39.7127 43.7241 37.1381L43.3218 37.5404L41.7126 35.9312L40.908 36.3335L43.3218 39.5519L43.7241 39.1496C45.3333 41.1611 49.1954 45.2646 51.7701 45.5863C54.3448 45.9081 55.7931 41.1611 56.1954 38.7473L54.9885 37.5404L55.7931 36.7358ZM25.9374 26.4854C26.4096 26.3744 26.6768 25.8739 26.5065 25.4197L24.6096 20.3613C24.4918 20.0472 24.1916 19.8392 23.8562 19.8392H20.7931C20.3487 19.8392 19.9885 20.1995 19.9885 20.6438V26.8692C19.9885 27.3882 20.4723 27.7713 20.9774 27.6524L25.9374 26.4854Z"
          fill="white"
        />
        <path
          d="M44.6828 15.4542L44.1211 14.5355L38.999 17.6672L38.7663 17.2865L33.2534 20.6572L34.377 22.4948L39.8899 19.1242L39.5607 18.586L44.6828 15.4542Z"
          fill="white"
        />
        <path
          d="M36.0805 37.1379L38.8966 31.5057L38.092 31.1034L40.5057 25.8736L43.7241 23.4598L50.9655 35.931C49.7586 36.7356 47.7471 38.7471 43.7241 35.931L43.3218 36.7356L41.7126 35.1264L39.7011 35.931L43.3218 40.7586L43.7241 40.3563C45.3333 42.3678 48.938 46.3908 52.1724 46.3908C55.3908 46.3908 56.5977 41.1609 57 38.3448L56.1954 37.5402L57 36.7356L47.7471 13L45.3333 13.8046L44.5287 13L38.8966 16.2184H37.6897L31.6552 19.8391L33.4655 22.6552L29.3621 25.4713L25.6207 17.8276H17.5747V27.4828H16.7701C16.7701 26.5441 16.7701 25.069 15.9655 24.6667M11.9425 27.4828H7.11494C7.11494 27.4828 4.29885 27.3034 4.29885 29.092C4.29885 30.8805 4.29885 33.5172 4.29885 35.5287C4.29885 35.5287 4.29885 36.3333 5.10345 36.3333"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          d="M15.9655 24.6668V20.6438H15.5632C14.7586 19.0346 13.5517 18.6323 12.3448 18.6323V21.0461L12.7471 21.4484"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          d="M12.7471 21.4482V24.6666C11.9425 25.4712 11.9425 25.4712 11.9425 27.4827"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          d="M36.0805 37.1381C37.2873 37.9427 37.8684 40.7588 37.6897 42.368C37.2874 45.9887 33.6667 48.0002 32.0575 48.0002H7.91956C5.90807 48.0002 2.28738 45.9887 2.28738 41.5634C2.28738 38.927 3.89658 36.4676 5.10347 36.3335"
          stroke="white"
          strokeLinecap="round"
        />
      </svg>
    </MuiIconWrapper>
  );
};
