export const tableStyles = {
  fontSize: '25px',
  lineHeight: '28px',
  fontWeight: 700,
  color: 'black',
  display: 'inline-flex',
  alignItems:'center'
};

export const defaultDataTableStyles = {
  flexGrow: 1,
  border: 'none',
  width: '100%',
  '& .MuiDataGrid-columnHeader' : {
    fontSize: '14px',
    lineHeight: '16px',
    color : '#454545',
  },
  '& .MuiDataGrid-columnHeaders' : {
    border: 'none'
  },
  '& .MuiDataGrid-cell' : {
    border: 'none'
  },
  '& .MuiDataGrid-columnSeparator' : {
    display: 'none'
  }
};