import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { white } from "../../../constants/style";
import { Pill } from "../components";
import Divider from "../../../components/Divider/Divider";
import {
  getStatusColor,
  abbreviateNumber,
  abbreviateNumberUpdated,
} from "../../../utils";
import CTA from "./CTA";

const dataOrder = [
  {
    display_name: "Asset Performance",
    order: [
      "production_actual_gross",
      "production_optimal_gross",
      "target_revenue",
    ],
  },
  {
    display_name: "Full Potential Value",
    order: ["baseline_revenue", "fpv"],
  },
];

const statusMessages = {
  production_actual_gross: {
    status: (val) => {
      return val > 0 ? "Good" : "Issue";
    },
    status_message: (val) => {
      return val > 0 ? `$${val}M from target` : `-$${val * -1}M from target`;
    },
  },
  production_optimal_gross: {
    status: "Good",
    status_message: "PHOSA Activated",
  },
  target_revenue: {
    status: "n/a",
    status_message: "Adjusted to date",
  },
};

const TopSummary = ({ data }) => {
  const targetDifference =
    abbreviateNumberUpdated(
      data["production_actual_gross"].attribute_value,
      1
    ) - abbreviateNumberUpdated(data["target_revenue"].attribute_value, 1);
  return (
    <Stack
      spacing="20px"
      direction="row"
      justifyContent="center"
      sx={{ marginBottom: "20px" }}
    >
      <Pill
        styles={{
          padding: "20px",
          height: "auto",
          background:
            "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
        }}
      >
        <Typography sx={{ color: white, fontSize: "16px", fontWeight: 700 }}>
          {dataOrder[0].display_name}
        </Typography>
        <Stack
          direction="row"
          sx={{
            color: white,
            padding: "10px 0",
          }}
          divider={
            <Divider
              orientation="vertical"
              dividerStyles={{ margin: "0 30px" }}
            />
          }
        >
          {dataOrder[0].order.map((item, index) => {
            return (
              <Stack key={index}>
                <Typography sx={{ fontSize: "14px" }}>
                  {data[item].display_name}
                </Typography>
                <Stack direction="row" sx={{ color: white }}>
                  <Typography sx={{ fontSize: "35px", fontWeight: 700 }}>
                    {data[item].display_uom}
                  </Typography>
                  <Typography sx={{ fontSize: "35px", fontWeight: 700 }}>
                    {abbreviateNumber(data[item].attribute_value, 1, true)}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: getStatusColor(
                      item === "production_actual_gross"
                        ? statusMessages[item].status(
                            targetDifference.toFixed(1)
                          )
                        : statusMessages[item].status
                    ).color,
                  }}
                >
                  {" "}
                  {item === "production_actual_gross"
                    ? statusMessages[item].status_message(
                        targetDifference.toFixed(1)
                      )
                    : statusMessages[item].status_message}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Pill>
      <CTA data={dataOrder[1].order.map((item) => data[item])} />;
    </Stack>
  );
};

export default TopSummary;
