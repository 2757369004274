import { appConstants } from '../config/app.config';

class StorageService {
    
  static _instance;


  static getInstance() {
    if (!StorageService._instance) {
      StorageService._instance = new StorageService();
    }
    return StorageService._instance;
  }


  getItem(key, storageType = appConstants.keys.storageType) {
    switch (storageType) {
    case appConstants.storageTypes.local:
      return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : null;

    case appConstants.storageTypes.session:
      return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key) || '') : null;
    }
  }

  setItem(key, data, storageType = appConstants.keys.storageType) {
    switch (storageType) {
    case appConstants.storageTypes.local:
      localStorage.setItem(key, JSON.stringify(data));
      break;

    case appConstants.storageTypes.session:
      sessionStorage.setItem(key, JSON.stringify(data));
      break;
    }
  }

  removeItem(key, storageType = appConstants.keys.storageType) {
    switch (storageType) {
    case appConstants.storageTypes.local:
      localStorage.removeItem(key);
      break;

    case appConstants.storageTypes.session:
      sessionStorage.removeItem(key);
      break;
    }
  }

  clearAll(storageType = appConstants.keys.storageType) {
    switch (storageType) {
    case appConstants.storageTypes.local:
      localStorage.clear();
      break;

    case appConstants.storageTypes.session:
      sessionStorage.clear();
      break;
    }
  }
}

export default StorageService.getInstance();
