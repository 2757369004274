import React from "react";
import {
  UpstreamIconDark,
  ProductionIconDark,
  FlotationIconDark,
  GrindingIconDark,
  FlotationDark,
  DumpTruckDark,
  StockPilingDark,
  EclipseDark,
  CrushingDark,
  LoadingDark,
  GrindingDark,
  FlotationDeactive,
  StockPilingDeactive,
  CrushingDeactive,
  GrindingDeactive,
  HaulDeactive,
  EclipseDeactive,
  LoadingDeactive,
  DownStream,
  Environment,
  FlotationDim,
  StockPilingDim,
  CrushingDim,
  GrindingDim,
  HaulingDim,
  IntegratedDim,
  LoadingDim,
} from "../icons";

const iconMap = {
  1: {
    flot: <FlotationIconDark />,
    stock: <ProductionIconDark />,
    crush: <UpstreamIconDark />,
    grin: <GrindingIconDark />,
    upstr: <UpstreamIconDark />,
    prod: <ProductionIconDark />,
    min: <ProductionIconDark />,
    mill: <GrindingIconDark />,
    trans: <UpstreamIconDark />,
    down: <DownStream />,
    env: <Environment />,
  },
  2: {
    flot: <FlotationDark />,
    stock: <StockPilingDark />,
    crush: <CrushingDark />,
    grin: <GrindingDark />,
    haul: <DumpTruckDark />,
    integr: <EclipseDark />,
    shov: <LoadingDark />,
    load: <LoadingDark />,
  },
  3: {
    flot: <FlotationDeactive />,
    stock: <StockPilingDeactive />,
    crush: <CrushingDeactive />,
    grin: <GrindingDeactive />,
    haul: <HaulDeactive />,
    integr: <EclipseDeactive />,
    shov: <LoadingDeactive />,
    load: <LoadingDeactive />,
  },
  4: {
    flot: <FlotationDim />,
    stock: <StockPilingDim />,
    crush: <CrushingDim />,
    grin: <GrindingDim />,
    haul: <HaulingDim />,
    integr: <IntegratedDim />,
    load: <LoadingDim />,
  },
};

export const getIcon = (statusName = "flotation", variant = 1) => {
  const lowerCaseName = statusName.toLowerCase();
  let icon = iconMap[variant].flot;

  Object.keys(iconMap[variant]).forEach((iconKey) => {
    if (lowerCaseName.match(new RegExp(iconKey))) {
      icon = iconMap[variant][iconKey];
    }
  });

  return icon;
};
