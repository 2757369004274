import {
  cardBorder,
  statusCardBackground,
  gray1,
  elevation1,
} from "../../constants/style";

export const defaultCardStyles = {
  width: "100%",
  minWidth: 280,
  maxWidth: 280,
  background: statusCardBackground,
  borderRadius: "15px",
  border: cardBorder,
  boxShadow: elevation1,
  height: 462,
  minHeight: 462,
};

export const defaultDividerStyles = {
  marginBottom: "15px",
  background: gray1,
};
