import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "../../components/charts/Chart";
import { useHistory } from "react-router-dom";
import PhosaRecommendation from "./components/PhosaRecommendation";
import { totalBusinessRealized } from "./components/TotalBusinessRealized";
import {
  IntenseRed1,
  IntenseRed2,
  IntenseRed3,
  black,
  white,
} from "../../constants/style";
import ProcessCardFPV from "./components/processCard_FPV";
import { getViewData } from "../../utils/getViewData";
import { useSiteConfig } from "../../_shared/context/siteChangeTester.context";
import { useOktaAuth } from "@okta/okta-react";
import { useLoaderContext } from "../../_shared/context/loader.context";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useTimestampContext } from "../../_shared/context/timestamp.context ";

const colorMap = {
  1: IntenseRed1,
  2: IntenseRed2,
  3: IntenseRed3,
};

const FullPotentialValue = () => {
  const [data, setData] = useState();
  const { siteConfig } = useSiteConfig();
  const { oktaAuth } = useOktaAuth();
  const { startLoader, endLoader } = useLoaderContext();
  const [activeFpv, setActiveFpv] = useState("");
  const [fpvList, setFpvList] = useState([]);
  const history = useHistory();
  const { setTimestamp } = useTimestampContext();

  const handleChange = (event) => {
    setActiveFpv(() => event.target.value);
    setData(null);
    getFpvData(event.target.value);
  };

  useEffect(() => {
    if (siteConfig.selectedClient) {
      getFpvData();
    }
  }, [siteConfig]);

  const getFpvData = (fpv = activeFpv) => {
    startLoader();
    getViewData(
      "getFpvData",
      "post",
      siteConfig.selectedClient,
      siteConfig.selectedSite,
      fpv
    )
      .then((res) => {
        endLoader();
        const FPVData = res.data;
        if (res.detail === "No Data Found") {
          setData(null);
          return;
        }
        FPVData.selectedProcess = {
          integratedOperationsValue: 0,
          fullPotentialValue: 0,
        };
        FPVData.recommondationResults = [];
        FPVData.accumulatedOperatingValue.withPhosa = [];
        setData(updateBottleNeckProcess(FPVData));
        setFpvList(FPVData.fpv_screens);
        setTimestamp(new Date().toISOString());
        if (!activeFpv) {
          setActiveFpv(FPVData.fpv_screens[0].fpv_name);
        }
      })
      .catch((error) => {
        endLoader();
        console.log(error);
        if (error.status === 500) {
          history.push("/error");
        } else if (error.status === 401) {
          oktaAuth.signOut();
        } else if (error.status === 403) {
          history.push("/unauthorized");
        }
      });
  };

  const box1Styles = {
    background:
      "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
    border: "1px solid #464959",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: "25px",
    padding: "3px",
  };

  const roundToPrecision = (subject, precision) => {
    return +(+subject).toFixed(precision);
  };

  const updateBottleNeckProcess = (data) => {
    const topThreeProcesses = [...data.processes]
      .sort((x, y) => y.value - x.value)
      .slice(0, 3)
      .map((item) =>
        data.processes.findIndex((process) => process.name === item.name)
      );
    topThreeProcesses.reverse().forEach((item, index) => {
      data.processes[item].bottleNeckIntensity = index + 1;
    });
    return data;
  };

  const updateSelection = (event, name) => {
    const currentState = { ...data };
    const targetIndex = currentState.processes.findIndex(
      (process) => process.name === name
    );
    currentState.processes[targetIndex].isChecked = event.target.checked;

    const selectedProcesses = currentState.processes.filter(
      (process) => process.isChecked
    );
    currentState.activatedProcessesCount = selectedProcesses.length;
    if (currentState.activatedProcessesCount) {
      currentState.selectedProcess.integratedOperationsValue =
        currentState.integratedOpsValues[
          calculateIntegratedOperationsValue([...selectedProcesses])
        ] || 0;
      currentState.selectedProcess.fullPotentialValue = selectedProcesses
        .map((process) => process.value)
        .reduce((x, y) => roundToPrecision(x + y, 2));

      const lineChartData =
        currentState.with_phosa_operating_values[
          calculateIntegratedOperationsValue([...selectedProcesses])
        ];
      currentState.accumulatedOperatingValue.withPhosa = lineChartData;
      currentState.recommondationResults = getDataForWaterFallChart(
        currentState,
        selectedProcesses
      );
    } else {
      currentState.selectedProcess.fullPotentialValue = 0;
      currentState.selectedProcess.integratedOperationsValue = 0;
      currentState.accumulatedOperatingValue.withPhosa = [];
      currentState.recommondationResults = [];
    }
    setData(currentState);
  };

  const calculateIntegratedOperationsValue = (selectedProcesses) => {
    const processesNames = selectedProcesses
      .map((item) => item.name.toLowerCase())
      .sort();
    return processesNames.join("_");
  };

  const getDataForWaterFallChart = (data, selectedProcesses) => {
    const orderedProcessesByValue = [...selectedProcesses]
      .map((process) => {
        return {
          name: process.name,
          value: process.value,
        };
      })
      .sort((a, b) => b.value - a.value);

    const staticAddition = [
      {
        name: "Integrated operations",
        value: data.selectedProcess.integratedOperationsValue,
      },
      {
        name: "FPV",
        value: data.selectedProcess.fullPotentialValue,
      },
    ];

    return [...orderedProcessesByValue, ...staticAddition];
  };

  const bottleNeckLegends = () => {
    const bottleNeckLegends = [...data.processes]
      .filter((item) => item.bottleNeckIntensity)
      .sort((a, b) => b.value - a.value);
    const legends = bottleNeckLegends.map((item) => {
      return (
        <Box
          sx={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            border: "3px solid",
            borderColor: colorMap[item.bottleNeckIntensity],
          }}
        ></Box>
      );
    });
    return legends;
  };

  const getRefinedValue = (val) => {
    return `$${val}M`;
  };

  return (
    <>
      <Stack gap="30px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap="1rem" alignItems="center">
            <Typography
              sx={{ color: "#fff", fontSize: "21px", fontWeight: "700" }}
            >
              Full Potential Value
            </Typography>
            {data && (
              <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                &#40; Copper price at ${data.summary.copper_price}/tonne &#41;
              </Typography>
            )}
          </Stack>
          <Stack>
            <TextField
              select
              value={activeFpv}
              onChange={handleChange}
              sx={{
                borderColor: white,
                width: "240px",
                "& .MuiInputBase-root": {
                  color: white,
                },
                "& .MuiSvgIcon-root": {
                  color: white,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#575A67",
                  "&:hover": {
                    borderColor: "#575A67",
                  },
                },
              }}
            >
              {fpvList.map((option) => (
                <MenuItem
                  key={option.fpv_name}
                  value={option.fpv_name}
                  sx={{ color: black }}
                >
                  {option.fpv_name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>
        {data && (
          <Stack gap="30px">
            <Stack
              direction="row"
              gap="15px"
              sx={box1Styles}
              alignItems="center"
            >
              <Stack sx={{ padding: "15px" }}>
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  Full Potential Value
                </Typography>
                <Typography
                  sx={{ color: "#fff", fontSize: "35px", fontWeight: "700" }}
                >
                  {getRefinedValue(data.summary.full_potential_value)}
                </Typography>
                <Typography sx={{ color: "#80CBC3", fontSize: "14px" }}>
                  Calculated by PHOSA
                </Typography>
              </Stack>
              <Stack sx={{ padding: "15px" }}>
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  Baseline value
                </Typography>
                <Typography sx={{ color: "#fff", fontSize: "35px" }}>
                  {data.summary.baselineValue}
                  {getRefinedValue(data.summary.baseline_value)}
                </Typography>
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  Set by the business
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#252933",
                  borderRadius: "23px",
                  flexGrow: "1",
                  padding: "15px",
                }}
              >
                <Stack
                  sx={{
                    padding: "10px 30px 10px 10px",
                    alignSelf: "center",
                    flex: "0 0 200px",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    Potential incremental value
                  </Typography>
                  <Typography
                    sx={{ color: "#fff", fontSize: "35px", fontWeight: "700" }}
                  >
                    {getRefinedValue(
                      roundToPrecision(
                        data.selectedProcess.fullPotentialValue +
                          data.selectedProcess.integratedOperationsValue,
                        2
                      )
                    )}
                  </Typography>
                  <Typography sx={{ color: "#80CBC3", fontSize: "14px" }}>
                    Based on PHOSA offerings selected
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    padding: "0px 10px 0px 20px",
                    flexGrow: 1,
                    borderLeft: "1px solid rgba(207, 209, 219, 0.6)",
                  }}
                >
                  <Stack sx={{ flexGrow: 1 }}>
                    <Chart
                      data={totalBusinessRealized(
                        data.accumulatedOperatingValue,
                        "175px"
                      )}
                    ></Chart>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack gap="10px">
              <Stack
                direction="row"
                sx={{ position: "relative", marginTop: "20px" }}
              >
                <Box
                  sx={{
                    border: `1px solid #8E8E8E`,
                    borderRadius: "45px",
                    position: "absolute",
                    top: 60,
                    bottom: 0,
                    left: `${100 / (data.processes.length + 1) / 2}%`,
                    right: 0,
                    zIndex: 1,
                    background:
                      "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  }}
                />
                <Stack direction="row" sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{
                      width: `${100 / (data.processes.length + 1)}%`,
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    <ProcessCardFPV
                      data={{
                        name: "Integrated operations",
                        value: data.selectedProcess.integratedOperationsValue,
                        currency: "$",
                        unit: "M",
                        color:
                          "linear-gradient(180deg, #FAFCDA 37.5%, #F6FAC9 51.73%, #DAE085 122.86%)",
                      }}
                      renderLine={false}
                      handleEnable={(event, name) => {
                        updateSelection(event, name);
                      }}
                      isReadOnly={true}
                    />
                  </Stack>
                  {data.processes.map((process, index) => {
                    return (
                      <Stack
                        justifyContent="center"
                        key={process.name}
                        sx={{
                          width: `${100 / (data.processes.length + 1)}%`,
                          position: "relative",
                          zIndex: 2,
                        }}
                      >
                        <ProcessCardFPV
                          id={`processCard-${index}`}
                          data={process}
                          renderLine={
                            data.processes.length - 1 === index ? false : true
                          }
                          handleEnable={(event, name) => {
                            updateSelection(event, name);
                          }}
                          isReadOnly={false}
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                gap="20px"
                alignItems="center"
                justifyContent="end"
              >
                {data.selectedProcess.integratedOperationsValue > 0 && (
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        border: "3px solid",
                        borderColor: "#DAE085",
                      }}
                    ></Box>
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Additional value received by activating two or more
                    </Typography>
                  </Stack>
                )}
                {data.processes.find((item) => item.bottleNeckIntensity) && (
                  <Stack direction="row" gap="10px" alignItems="center">
                    {bottleNeckLegends()}
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Most to least intense bottleneck
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack gap="15px">
              <Typography
                sx={{ color: "#fff", fontSize: "21px", fontWeight: 700 }}
              >
                PHOSA recommends you enable in the following order:{" "}
              </Typography>
              <Stack gap="10px" sx={{ ...box1Styles, padding: "10px" }}>
                {data.activatedProcessesCount ? (
                  <Stack direction="row" sx={{ padding: "0 50px 0 10px" }}>
                    {data.recommondationResults.map((item, index) => {
                      return (
                        <Stack
                          key={item.name}
                          alignItems="center"
                          sx={{
                            flex: `1 0 ${
                              100 / data.recommondationResults.length
                            }%`,
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "13px",
                              lineHeight: "23px",
                              fontWeight: "700",
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                              color: `${
                                item.name === "Integrated planning"
                                  ? "#000"
                                  : "#fff"
                              }`,
                              backgroundColor: `${
                                item.name === "Integrated planning"
                                  ? "#DAE085"
                                  : "#80CBC3"
                              }`,
                              border: "1px solid #464959",
                              textAlign: "center",
                            }}
                          >
                            {index + 1}
                          </Box>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "13px",
                              fontWeight: "700",
                            }}
                          >
                            {item.name}
                          </Typography>
                          {data.recommondationResults.length !== index + 1 ? (
                            <Box
                              sx={{
                                position: "absolute",
                                width: `calc(100% - 45px)`,
                                left: `calc(50% + 12.5px + 10px)`,
                                top: "11.5px",
                                height: "2px",
                                backgroundColor: "#fff",
                              }}
                            ></Box>
                          ) : null}
                          {item.name === "Integrated planning" && (
                            <Box
                              sx={{
                                position: "absolute",
                                border: "6px solid transparent",
                                borderLeftColor: "#fff",
                                left: `calc(50% - 10px - 12.5px)`,
                                top: "7px",
                              }}
                            ></Box>
                          )}
                        </Stack>
                      );
                    })}
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "13px",
                        paddingTop: "15px",
                        fontWeight: "700",
                      }}
                    >
                      Toggle above to activate your incremental potential values{" "}
                    </Typography>
                  </Stack>
                )}
                <Stack>
                  <PhosaRecommendation chartData={data.recommondationResults} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default FullPotentialValue;
