import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Pill } from "../../../components/Pill";
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";
import {
  tabSmallActive,
  tabSmallHover,
  tabSmallIdle,
  white,
} from "../../../constants/style";
import { DownArrow } from "../../../icons";
import { paths } from "../../../constants/paths";

const drillDownMap = [
  {
    name: "Grinding adherence drill down",
    link: "Grinding Adherence",
  },
  {
    name: "Downtime drill down",
    link: "Grinding Downtime",
  },
];

const DrillDown = () => {
  const history = useHistory();

  const navigateToView = (link) => {
    history.push(paths[link]);
  };

  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          flex: "0 1 auto",
        }}
      />
      {drillDownMap.map((item, index) => {
        return (
          <Stack
            key={index}
            onClick={() => navigateToView(item.link)}
            direction="row"
            sx={{
              width: "100%",
              flex: "0 1 auto",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid transparent",
              borderTopColor: "#8d909a",
              borderRadius: "0px 0px 15px 15px",
              marginRight: "10px",
              cursor: "pointer",
              userSelect: "none",
              background: tabSmallIdle,
              "&:hover": {
                background: tabSmallHover,
              },
              "&:active": {
                background: tabSmallActive,
              },
            }}
          >
            <DownArrow />
            <Typography sx={{ color: white, marginLeft: "10px" }}>
              {item.name}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

const Accordion = ({ data, handleExpand, expanded, isLast }) => {
  const handleClick = () => {
    handleExpand(data.Display_Name);
  };

  return (
    <Box>
      <Pill
        pillStyles={{
          borderRadius: "10px",
          marginBottom: isLast ? "0" : "20px",
        }}
      >
        <AccordionSummary
          data={data}
          handleClick={handleClick}
          expanded={expanded}
        />
        {expanded[data.Display_Name] && <AccordionDetails data={data} />}
      </Pill>
      {isLast && <DrillDown />}
    </Box>
  );
};

export default Accordion;
