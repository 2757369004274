import { Stack, Box, Typography, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import phosaLogoWhite from "../../icons/phosaLogo-white.svg";
import { useLoaderContext } from "../../_shared/context/loader.context";

const Login = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [invalidLogin, setInvalidLogin] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const { startLoader, endLoader } = useLoaderContext();

  const textFieldStyles = {
    input: {
      color: "#fff",
      background: "rgba(255, 255, 255, 0.15)",
      borderRadius: "8px",
    },
    "input::placeholder": {
      color: "#fff",
    },
    fieldSet: {
      border: "none",
    },
  };

  const deviderStyle = {
    color: "#fff",
    position: "relative",
    width: "100%",
    textAlign: "center",
    ":after": {
      content: "''",
      left: 0,
      top: "calc(50% - 0.5px)",
      position: "absolute",
      width: "100%",
      height: "1px",
      backgroundColor: "rgba(207, 209, 219, 0.6)",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    startLoader();
    oktaAuth
      .signIn({ username: userEmail, password: userPassword })
      .then((res) => {
        if (res.status === "SUCCESS") {
          oktaAuth.signInWithRedirect({ sessionToken: res.sessionToken });
        }
      })
      .catch((error) => {
        setInvalidLogin(true);
        endLoader();
      });
  };

  const handleSSO = () => {
    startLoader();
    oktaAuth.signInWithRedirect();
  };

  return (
    <Stack
      direction="column"
      gap="20px"
      sx={{
        minHeight: "100vh",
        width: "100vw",
        background: "linear-gradient(180deg, #10151B 50%, #404252 100%)",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ marginTop: "40px", width: "175px" }}
        component="img"
        src={phosaLogoWhite}
        alt="Phosa logo"
      ></Box>
      <Stack
        direction="column"
        gap="30px"
        sx={{
          background:
            "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%);",
          width: "400px",
          padding: "35px",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          alignItems: "center",
          borderRadius: "25px",
          border: "1px solid #464959",
          position: "relative",
          ":after": {
            content: "''",
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)",
            position: "absolute",
            left: "-50px",
            right: "-50px",
            bottom: "-100px",
            height: "100px",
          },
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "1.2",
          }}
          component="h2"
        >
          Welcome
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Stack
            onSubmit={handleSubmit}
            component="form"
            noValidate
            autoComplete="off"
            gap="20px"
          >
            <TextField
              hiddenLabel
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              variant="outlined"
              size="small"
              id="email"
              name="email"
              placeholder="Please enter your email"
              autoFocus
              sx={textFieldStyles}
            />
            <TextField
              hiddenLabel
              type="password"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              variant="outlined"
              id="password"
              name="password"
              size="small"
              placeholder="Please enter your pasword"
              sx={textFieldStyles}
            />
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="outlined"
              disabled={userEmail && userPassword ? false : true}
              sx={{
                backgroundColor: "#85888f",
                borderRadius: "8x",
                color: "#151515",
                textTransform: "capitalize",
                border: "none",
                marginTop: "20px",
                fontWeight: "700",
                "&:hover": { border: "none", backgroundColor: "#6A6D74" },
              }}
            >
              Login
            </Button>
          </Stack>
          {invalidLogin && (
            <Typography
              sx={{ fontSize: "14px", color: "red", paddingTop: "10px" }}
            >
              Invalid username and password
            </Typography>
          )}
        </Box>
        <Box sx={deviderStyle}>
          <Typography
            sx={{
              backgroundColor: "#3c3e4b",
              "z-index": 1,
              position: "relative",
              display: "inline-block",
              padding: "0 10px",
            }}
          >
            OR
          </Typography>
        </Box>
        <Button
          onClick={handleSSO}
          sx={{
            backgroundColor: "#80CBC3",
            padding: "6px 50px",
            fontWeight: "700",
            textTransform: "inherit",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            color: "#151515",
            borderRadius: "8px",
            "&:hover": { border: "none", backgroundColor: "#46AA9F" },
          }}
        >
          Log in with SSO
        </Button>
      </Stack>
    </Stack>
  );
};

export default Login;
