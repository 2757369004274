import { white } from "../../constants/style";

export const metricStyles = {
  color: white,
  fontSize: "14px",
  lineHeight: "16px",
};

export const metricAmountStyles = {
  color: white,
  fontSize: "25px",
  fontWeight: 700,
  lineHeight: "28px",
};

export const capacityStyles = {
  color: "#454545",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "16px",
  marginRight: "15px",
};

export const capacityStatusStyles = {
  fontSize: "14px",
  lineHeight: "16px",
  borderRadius: "4px",
  height: "20px",
  "& .MuiChip-label": {
    padding: "7px",
  },
};
