import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { black, white } from "../../constants/style";

const ViewTopBar = ({ children, dataTimeRanges }) => {
  const dropDownValues = dataTimeRanges || [
    {
      value: "Month to date",
      label: "Month to date",
    },
    // {
    //   value: "Year to date",
    //   label: "Year to date",
    // },
  ];
  const [range, setRange] = useState(dropDownValues[0].value);
  const handleChange = (event) => {
    setRange(event.target.value);
  };

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center" }}>
        {children && children}
      </Stack>
      <TextField
        select
        value={range}
        onChange={handleChange}
        sx={{
          borderColor: white,
          width: "240px",
          "& .MuiInputBase-root": {
            color: white,
          },
          "& .MuiSvgIcon-root": {
            color: white,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#575A67",
            "&:hover": {
              borderColor: "#575A67",
            },
          },
        }}
      >
        {dropDownValues.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ color: black }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};

export default ViewTopBar;
