import FullPotentialValue from "../views/FullPotentialValue/FullPotentialValue";
import MillStatus from "../views/MillStatus/MillStatus";
import MillPerformance from "../views/MillPerformance/MillPerformance";
import MillMapOverview from "../components/MillMapOverview";
import AssetPerformance from "../views/AssetPerformance/AssetPerformance";
import Grinding from "../views/Grinding/Grinding";
import GrindingAdherence from "../views/GrindingAdherence/GrindingAdherence";
import GrindingDowntime from "../views/GrindingDowntime/GrindingDowntime";
import { Home } from "../views/Home";
import { appConstants } from "../_shared/config/app.config";
import WorkInProgress from "../views/WIP/workInProgress";
import AssetStrategy from "../views/AssetStrategy/AssetStrategy";

const roles = appConstants.staticValues.roles;
export const viewMap = {
  home: {
    name: "Home",
    path: "/",
    element: <Home />,
    routeName: "phosa_value",
  },
  integratedOps: {
    name: "Integrated Ops",
    path: "/app/integrated-ops",
    element: <WorkInProgress />,
    routeName: "integrated_operations",
  },
  minePlanOptimization: {
    name: "Mine Plan Optimization",
    path: "/app/mine_plan_optimization",
    element: <WorkInProgress />,
    routeName: "mine_planning",
  },
  maintenancePlanOptimization: {
    name: "Maintenance Plan Optimization",
    path: "/app/maintenance_plan_optimization",
    element: <WorkInProgress />,
    routeName: "maintenance_planning",
  },
  laborOptimization: {
    name: "Labor Optimization",
    path: "/app/labor_optimization",
    element: <WorkInProgress />,
    routeName: "labor_planning",
  },
  assetPerformance: {
    name: "Asset Performance",
    path: "/app/asset_performance",
    element: <AssetPerformance />,
    routeName: "asset_performance",
  },
  minePerformance: {
    name: "Mine Performance",
    path: "/app/mine_performance",
    element: <WorkInProgress />,
    routeName: "mine_performance",
  },
  millPerformance: {
    name: "Mill Performance",
    path: "/app/mill_performance",
    element: <MillPerformance />,
    routeName: "mill_performance",
  },
  millMapOverview: {
    name: "Mill Map Overview",
    path: "/app/mill_map",
    element: <MillMapOverview />,
    routeName: "mill_map_overview",
  },
  millStatus: {
    name: "Mill Status",
    path: "/app/mill_status",
    element: <MillStatus />,
    routeName: "mill_status",
  },
  grinding: {
    name: "Grinding",
    path: "/app/grinding",
    element: <Grinding />,
    routeName: "grinding",
  },
  grindingRecommendations: {
    name: "Grinding Recommendations",
    path: "/app/grinding_recommendations",
    element: <WorkInProgress />,
    routeName: "grinding_recommendations",
  },
  grindingModelPerformance: {
    name: "Grinding Model Performance",
    path: "/app/grinding_model_performance",
    element: <WorkInProgress />,
    routeName: "grinding_model_performance",
  },
  grindingDowntimePrediction: {
    name: "Grinding Downtime Prediction",
    path: "/app/grinding_downtime_prediction",
    element: <WorkInProgress />,
    routeName: "grinding_downtime_prediction",
  },
  grindingAdherence: {
    name: "Grinding Adherence",
    path: "/app/grinding_adherence",
    element: <GrindingAdherence />,
    routeName: "grinding_downtime_prediction",
  },
  grindingDowntime: {
    name: "Grinding Downtime",
    path: "/app/grinding_downtime",
    element: <GrindingDowntime />,
    routeName: "grinding_downtime_prediction",
  },
  flotation: {
    name: "Flotation",
    path: "/app/flotation",
    element: <WorkInProgress />,
    routeName: "flotation",
  },
  flotationRecommendations: {
    name: "Flotation Recommendations",
    path: "/app/flotation_recommendations",
    element: <WorkInProgress />,
    routeName: "flotation_recommendations",
  },
  flotationModelPerformance: {
    name: "Flotation Model Performance",
    path: "/app/flotation_model_performance",
    element: <WorkInProgress />,
    routeName: "flotation_model_performance",
  },
  flotationDowntimePrediction: {
    name: "Flotation Downtime Prediction",
    path: "/app/flotation_downtime_prediction",
    element: <WorkInProgress />,
    routeName: "flotation_downtime_prediction",
  },
  environment: {
    name: "Environment",
    path: "/app/environment",
    element: <WorkInProgress />,
    routeName: "transportation_network",
  },
  assetStrategy: {
    name: "Asset Strategy",
    path: "/app/asset_strategy",
    element: <AssetStrategy />,
    routeName: "asset_strategy",
  },
  fieldTestedValue: {
    name: "Field Tested Value",
    path: "/app/field_tested_value",
    element: <WorkInProgress />,
    routeName: "field_tested_value",
  },
  fullPotentialValue: {
    name: "Full Potential Value",
    path: "/app/full_potential_value",
    element: <FullPotentialValue />,
    routeName: "full_potential_value",
  },
};

const pathsEnum = (paths = {}) => {
  const viewMapKeys = Object.keys(viewMap);

  viewMapKeys.forEach((view) => {
    paths[(paths[viewMap[view].name] = viewMap[view].path)] =
      viewMap[view].name;
  });

  return paths;
};

export const paths = pathsEnum();
