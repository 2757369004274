import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { lightgreen, white } from "../../../constants/style";
import { getStatusColor } from "../../../utils";
import { isUnitCurrency } from "../../../utils/isUnitCurrency";

const DisplayUnits = ({ units }) => {
  return (
    <Typography
      sx={{
        color: white,
        paddingLeft: isUnitCurrency(units) ? "0" : "8px",
        fontSize: isUnitCurrency(units) ? "32px" : "20px",
        lineHeight: isUnitCurrency(units) ? "30px" : "16px",
        paddingBottom: isUnitCurrency(units) ? "8px" : "10px",
        fontWeight: 700,
      }}
    >
      {units}
    </Typography>
  );
};

const Title = ({ title, titleStyles }) => {
  return (
    <Typography
      sx={{
        color: white,
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: 400,
        paddingBottom: "8px",
        ...titleStyles,
      }}
    >
      {title}
    </Typography>
  );
};

const GridItem = ({
  value,
  statusMessage,
  status,
  title,
  titleStyles,
  units,
  fontWeight = 700,
  titleLocation = "top",
  gridItemProps,
  index,
}) => {
  return (
    <Grid item {...gridItemProps}>
      <Stack
        sx={{
          alignItems: index === 0 || index === 4 ? "flex-start" : "center",
        }}
      >
        {title && titleLocation === "top" && (
          <Title title={title} titleStyles={titleStyles} />
        )}
        <Stack direction="row" sx={{ alignItems: "flex-end" }}>
          {units && isUnitCurrency(units) && <DisplayUnits units={units} />}
          <Typography
            sx={{
              color: white,
              fontSize: "32px",
              lineHeight: "30px",
              paddingBottom: "8px",
              fontWeight: fontWeight,
            }}
          >
            {value}
          </Typography>
          {units && !isUnitCurrency(units) && <DisplayUnits units={units} />}
          {statusMessage && (
            <Typography
              sx={{
                color: status ? getStatusColor(status).color : lightgreen,
                paddingLeft: "10px",
                lineHeight: "10px",
                paddingBottom: "10px",
                fontSize: "12px",
              }}
            >
              {statusMessage}
            </Typography>
          )}
        </Stack>
        {title && titleLocation !== "top" && (
          <Title title={title} titleStyles={titleStyles} />
        )}
      </Stack>
    </Grid>
  );
};

export default GridItem;
