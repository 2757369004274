import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const DownArrow = ({ size = 15, height = 8 }) => {
  return (
    <MuiIconWrapper size={size} height={height}>
      <svg
        width={size}
        height={height}
        viewBox={`0 0 ${size} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.5 7.5L0 0H15L7.5 7.5Z" fill="white" />
      </svg>
    </MuiIconWrapper>
  );
};
