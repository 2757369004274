// import generateViewHierarchyLegend from './generateViewHierarchyLegend';
import viewHierarchyLegend from "../constants/viewHierarchyLegend";
import appHierarchy from "../mockData/navigation_test_2.json";

export const getNavMiniMapState = (pageName) => {
  let parentNode;
  let currentNode = appHierarchy.pageHierarchy;
  let pageLocation = viewHierarchyLegend[pageName] ? viewHierarchyLegend[pageName].location : [];
  let locationIndex = pageLocation[pageLocation.length - 1];
  let processLevelArray = [];
  let downstreamNode;
  let upstreamNode;

  // Traverse pageHierarchy (currentNode) per the location steps array
  if (viewHierarchyLegend[pageName]) {
    viewHierarchyLegend[pageName].location.forEach((step) => {
      if (step === "children") {
        processLevelArray = currentNode.children;
        parentNode = currentNode;
        currentNode = currentNode.children;
      } else {
        currentNode = currentNode[step];
        if (!parentNode) {
          parentNode = currentNode;
        }
      }
    });
  }

  // Determine downstream node
  if (processLevelArray.length > 0 && locationIndex - 1 >= 0) {
    downstreamNode = processLevelArray[locationIndex - 1];
  }

  // Determine upstream node
  if (
    processLevelArray.length > 0 &&
    locationIndex + 1 <= processLevelArray.length - 1
  ) {
    upstreamNode = processLevelArray[locationIndex + 1];
  }

  /**
   * TODO: set the currentNode, upstreamNode, downstreamNode, parentNode, processLevelArray
   */

  return {
    currentNode,
    downstreamNode,
    upstreamNode,
    parentNode,
  };
};
