import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const TopCard = (props) => {
  return (
    <div style={{ width: '100%', minHeight: '184px', borderRadius: '15px', ...(props.styles ? props.styles : {}) }}>
      <div style={{
        display:'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: '100%'
      }}>
        {props.children && props.children}
      </div>
    </div>
  );
};

const productionRateCardStyles = {
  background: 'linear-gradient(180deg, rgba(21, 128, 107, 0.85) 0%, #15806B 100%), #FFFFFF',
  color: '#fff',
  padding: '30px 30px 5px 30px',
  boxSizing: 'border-box'
};

const valuePotentialCardStyles = {
  padding: '30px',
  boxSizing: 'border-box',
  background: 'white'
};

const TopStatus = () => {
  return (
    <Stack direction="row" spacing='32px' sx={{ width: '100%'}}>
      <TopCard styles={productionRateCardStyles}>
        <Typography>
          Copper Concentrate Production Rate <HelpOutlineIcon sx={{width: '16px', height:'16px'}}/>
        </Typography>
        <Typography sx={{fontSize: '49px', fontWeight: 700}}>
          28tph
        </Typography>
      </TopCard>
      <TopCard styles={valuePotentialCardStyles}>
        <Typography sx={{ fontSize: 14 }}>
         Full Value Potential Capture <HelpOutlineIcon sx={{width: '16px', height:'16px'}}/>
        </Typography>
        <Typography sx={{ fontSize: 22, fontWeight: 700 }}>
          Actions Recommended
        </Typography>
        <Button disableElevation size="small" variant="contained" sx={{background: '#15806B', borderRadius: '8px'}}>Learn More</Button>
      </TopCard>
    </Stack>
  );
};

export default TopStatus;
