import React from "react";
import Stack from "@mui/material/Stack";
import { Pill } from "../../../components/Pill";
import Divider from "../../../components/Divider/Divider";
import PillSection from "./PillSection";
import GridRow from "./GridRow";
import GridItem from "./GridItem";
import { lightgreen } from "../../../constants/style";

const TopSummary = ({ data }) => {
  return (
    <Pill pillStyles={{ padding: "4px" }}>
      <Stack
        direction="row"
        sx={{ height: "100%", justifyContent: "space-between" }}
      >
        <Stack
          sx={{
            width: "100%",
            padding: "0 36px 0 15px",
          }}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <GridRow rowTitle="Actual" rowTitleStyles={{ fontWeight: 700 }}>
            {Object.keys(data.asset_performance_summary).map((item, index) => {
              if (item.startsWith("Actual")) {
                return (
                  <GridItem
                    index={index}
                    key={index}
                    fontWeight={700}
                    title={data.asset_performance_summary[item].Display_Name}
                    titleLocation="top"
                    value={data.asset_performance_summary[item].Metric_Value}
                    units={data.asset_performance_summary[item].Display_UOM}
                    statusMessage={
                      data.asset_performance_summary[item].Status_Message
                    }
                    status={data.asset_performance_summary[item].Status}
                    gridItemProps={{
                      sx: {
                        flex: "1 1 0",
                      },
                    }}
                  />
                );
              }
            })}
          </GridRow>
          <GridRow rowTitle="Optimal">
            {Object.keys(data.asset_performance_summary).map((item, index) => {
              const hasSpecialTitle =
                data.asset_performance_summary[item].Display_Name ===
                "Copper production revenue";
              if (item.startsWith("Optimal")) {
                return (
                  <GridItem
                    index={index}
                    key={index}
                    fontWeight={300}
                    title={hasSpecialTitle ? "PHOSA Optimal" : ""}
                    titleLocation="bottom"
                    titleStyles={{ color: lightgreen }}
                    value={data.asset_performance_summary[item].Metric_Value}
                    units={data.asset_performance_summary[item].Display_UOM}
                    statusMessage={
                      data.asset_performance_summary[item].Status_Message
                    }
                    gridItemProps={{
                      sx: {
                        flex: "1 1 0",
                      },
                    }}
                  />
                );
              }
            })}
          </GridRow>
        </Stack>
        <PillSection data={data.Calendar_Hours} />
      </Stack>
    </Pill>
  );
};

export default TopSummary;
