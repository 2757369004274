import { appConstants } from "../_shared/config/app.config";
import networkService from "../_shared/services/network.service";

const callApi = (url, method, payload) => {
  if (method === "post") {
    return networkService[method](url, payload);
  }
  return networkService[method](url);
};

const getPayload = (urlKey, site, selectedTimestamp) => {
  const payload = {
    site_name: site
  }
  if (urlKey === 'getFpvData') {
    payload.fpv_name = selectedTimestamp;
  } else {
    payload.aggregation_type = selectedTimestamp;
  }
  return payload;
}

export const getViewData = (
  urlKey,
  method,
  selectedClient,
  selectedSite,
  selectedTimestamp
) => {
  const url = appConstants.urls[urlKey](selectedClient);

  const call = callApi(url, method, getPayload(urlKey, selectedSite, selectedTimestamp));

  return call;
};
