import React from "react";
import Stack from "@mui/material/Stack";

const ProcessCardContainer = ({
  data,
  children,
  styles,
  direction = "column",
}) => {
  return (
    <Stack
      direction={direction}
      justifyContent="center"
      sx={{
        width: `${100 / (data.length + 1)}%`,
        position: "relative",
        zIndex: 2,
        ...styles,
      }}
    >
      {children && children}
    </Stack>
  );
};

export default ProcessCardContainer;
