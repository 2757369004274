import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  drawerActive,
  drawerHover,
  drawerIdle,
  gray1,
  white,
} from "../../../constants/style";
import Divider from "../../../components/Divider/Divider";

const SectionStatus = ({ status, label }) => {
  const backgroundStyles =
    status && label
      ? "linear-gradient(180deg, #F2F3F6 37.5%, #DDDEE2 51.73%, #A6ABC1 122.86%)"
      : "#30323b";

  return (
    <Stack
      direction="row"
      sx={{
        height: "30px",
        borderRadius: "5px",
        padding: "5px 13px",
        alignItems: "center",
        flex: "0 1 auto",
        width: "100%",
        background: backgroundStyles,
      }}
    >
      {status && label ? (
        <>
          <WarningIcon sx={{ marginRight: "7px" }} />
          <Stack direction="row">
            <Typography sx={{ marginRight: "5px", fontWeight: 700 }}>
              {status}
            </Typography>
            <Typography sx={{ fontWeight: 700 }}>{label}</Typography>
          </Stack>
        </>
      ) : null}
    </Stack>
  );
};

const AccordionSummary = ({ data, handleClick, expanded }) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        alignItems: "center",
        cursor: "pointer",
        background: expanded.hasOwnProperty(data.Display_Name)
          ? drawerActive
          : drawerIdle,
        "&:hover": {
          background: drawerHover,
        },
      }}
      onClick={handleClick}
    >
      <Stack
        direction="row"
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          padding: "10px 0 10px 15px",
        }}
        divider={
          <Divider
            orientation="vertical"
            dividerStyles={{ margin: "0 20px" }}
          />
        }
      >
        <Stack direction="row" sx={{ flex: "0 1 auto", width: "100%" }}>
          <Typography
            sx={{ color: white, marginRight: "5px", fontSize: "21px" }}
          >
            System {data.Display_Name}:
          </Typography>
          <Typography
            sx={{
              color: white,
              fontSize: "21px",
              fontWeight: 700,
              marginRight: "5px",
            }}
          >
            {data.Throughput.Metric_Value} {data.Throughput.Display_UOM}
          </Typography>
          <Typography sx={{ color: white, fontSize: "21px" }}>
            gross throughput
          </Typography>
        </Stack>
        <SectionStatus status={data.Adherence_Status} label="PHOSA adherence" />
        <SectionStatus
          status={data.Downtime_Status}
          label="unplanned downtime loss"
        />
      </Stack>
      <Box>
        {expanded.hasOwnProperty(data.Display_Name) ? (
          <KeyboardArrowUpIcon sx={{ color: gray1 }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color: gray1 }} />
        )}
      </Box>
    </Stack>
  );
};

export default AccordionSummary;
