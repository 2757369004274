import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const UpstreamIconDark = () => {
  return (
    <MuiIconWrapper>
      <svg
        width="45"
        height="44"
        viewBox="0 0 45 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <rect x="0.660645" width="44" height="44" rx="22" fill="white" />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.0183 27.4772C29.6871 28.0497 29.4975 28.7144 29.4975 29.4234C29.4975 31.5732 31.2402 33.3159 33.39 33.3159C35.5397 33.3159 37.2825 31.5732 37.2825 29.4234C37.2825 28.7144 37.0929 28.0497 36.7617 27.4772H36.169C36.5556 28.0281 36.7825 28.6993 36.7825 29.4234C36.7825 31.297 35.2636 32.8159 33.39 32.8159C31.5164 32.8159 29.9975 31.297 29.9975 29.4234C29.9975 28.6993 30.2244 28.0281 30.611 27.4772H30.0183ZM13.4752 27.4772C13.1441 28.0497 12.9545 28.7144 12.9545 29.4234C12.9545 31.5732 14.6972 33.3159 16.847 33.3159C18.9967 33.3159 20.7394 31.5732 20.7394 29.4234C20.7394 28.7144 20.5499 28.0497 20.2187 27.4772H19.626C20.0126 28.0281 20.2394 28.6993 20.2394 29.4234C20.2394 31.297 18.7206 32.8159 16.847 32.8159C14.9734 32.8159 13.4545 31.297 13.4545 29.4234C13.4545 28.6993 13.6814 28.0281 14.0679 27.4772H13.4752Z"
            fill="#292B37"
          />
          <circle cx="16.8469" cy="29.4234" r="2.41935" stroke="#292B37" />
          <circle cx="33.3899" cy="29.4234" r="2.41935" stroke="#292B37" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.9841 11.9073V12.8804H28.5244V24.0713H10.0351L7.11578 17.2594L12.9545 11.9073H29.9841ZM11.9814 26.5041L11.2515 24.5578H29.4975V14.8266H34.3631L37.2825 20.6654V24.5578H38.2556V26.5041H36.7959H35.0042C34.5419 26.1966 33.9868 26.0175 33.3899 26.0175C32.7931 26.0175 32.238 26.1966 31.7756 26.5041H29.4975H18.4612C17.9989 26.1966 17.4438 26.0175 16.8469 26.0175C16.25 26.0175 15.695 26.1966 15.2326 26.5041H11.9814ZM20.7394 13.3535C21.0155 13.3535 21.2394 13.5774 21.2394 13.8535V21.1519C21.2394 21.4281 21.0155 21.6519 20.7394 21.6519C20.4633 21.6519 20.2394 21.4281 20.2394 21.1519V13.8535C20.2394 13.5774 20.4633 13.3535 20.7394 13.3535ZM24.6319 13.3535C24.908 13.3535 25.1319 13.5774 25.1319 13.8535V21.1519C25.1319 21.4281 24.908 21.6519 24.6319 21.6519C24.3557 21.6519 24.1319 21.4281 24.1319 21.1519V13.8535C24.1319 13.5774 24.3557 13.3535 24.6319 13.3535ZM16.8469 14.8132C17.1231 14.8132 17.3469 15.0371 17.3469 15.3132V21.1519C17.3469 21.4281 17.1231 21.6519 16.8469 21.6519C16.5708 21.6519 16.3469 21.4281 16.3469 21.1519V15.3132C16.3469 15.0371 16.5708 14.8132 16.8469 14.8132ZM12.9545 15.7863C13.2306 15.7863 13.4545 16.0102 13.4545 16.2863V21.1519C13.4545 21.4281 13.2306 21.6519 12.9545 21.6519C12.6783 21.6519 12.4545 21.4281 12.4545 21.1519V16.2863C12.4545 16.0102 12.6783 15.7863 12.9545 15.7863ZM30.9607 16C30.795 16 30.6607 16.1343 30.6607 16.3V19.7C30.6607 19.8657 30.795 20 30.9607 20H33.3607C33.5264 20 33.6607 19.8657 33.6607 19.7V16.3C33.6607 16.1343 33.5264 16 33.3607 16H30.9607Z"
            fill="#292B37"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9545 10.6841H12.8591L12.788 10.7477L5.97617 16.8296L5.84259 16.9489L5.91253 17.1138L9.31844 25.142L9.38308 25.2944H9.54859H10.3584L11.36 27.5776L11.4256 27.7272H11.589H13.8928C13.9949 27.5498 14.1124 27.3825 14.2437 27.2272H11.7523L10.7506 24.9439L10.685 24.7944H10.5217H9.71409L6.44276 17.0833L13.0499 11.1841H30.4793V13.6035H29.4975V14.1035H30.7293H30.9793H34.7003L38.0056 20.2418V24.0712V24.3212H38.2556H38.9787V27.2272H35.9933C36.1245 27.3825 36.2421 27.5498 36.3441 27.7272H39.2287H39.4787V27.4772V24.0712V23.8212H39.2287H38.5056V20.1788V20.1157L38.4757 20.0602L35.0698 13.735L34.999 13.6035H34.8497H30.9793V10.9341V10.6841H30.7293H12.9545ZM30.4358 27.7272C30.5379 27.5498 30.6554 27.3825 30.7867 27.2272H19.4503C19.5815 27.3825 19.6991 27.5498 19.8011 27.7272H30.4358Z"
            fill="#292B37"
          />
        </g>
      </svg>
    </MuiIconWrapper>
  );
};
