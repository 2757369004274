import React from "react";
import Stack from "@mui/material/Stack";
import { UpdateSiteData } from "../UpdateSiteData";

const Footer = () => {
  return (
    <Stack sx={{ width: "100%" }}>
      <UpdateSiteData />
    </Stack>
  );
};

export default Footer;
