import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const EclipseDeactive = ({ size = 100, height = 100 }) => {
  return (
    <MuiIconWrapper size={size} height={height}>
      <svg
        width={size}
        height={height}
        viewBox={`0 0 ${size} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50" cy="50" r="50" fill="#191D24" />
        <circle
          cx="50"
          cy="50"
          r="48.5"
          stroke="url(#paint0_linear_2723_13455)"
          strokeOpacity="0.8"
          strokeWidth="3"
        />
        <line
          y1="-0.5"
          x2="13.1271"
          y2="-0.5"
          transform="matrix(-0.12815 -0.991755 0.991443 -0.130539 50.2803 78.8682)"
          stroke="url(#paint1_linear_2723_13455)"
          strokeOpacity="0.8"
        />
        <path
          d="M69.0656 70.6604L50.5609 79.151L26.7292 66.6982M69.0656 70.6604L76.3553 61.6038M69.0656 70.6604L48.5983 66.6982M76.3553 61.6038L78.8787 48.302M76.3553 61.6038L66.8226 52.8303M76.3553 61.6038L48.5983 66.6982M78.8787 48.302L72.9908 31.8869M78.8787 48.302L66.8226 52.8303M72.9908 31.8869L58.2119 21.3083M72.9908 31.8869L66.8226 52.8303M72.9908 31.8869L57.5703 29.0567M58.2119 21.3083L43.2712 20.8491M58.2119 21.3083L39.9067 29.0567M58.2119 21.3083L57.5703 29.0567M43.2712 20.8491L25.888 33.868M43.2712 20.8491L39.9067 29.0567M25.888 33.868L20.8413 49.434M25.888 33.868L39.9067 45.4718M25.888 33.868L39.9067 29.0567M20.8413 49.434L26.7292 66.6982M20.8413 49.434L39.9067 45.4718M20.8413 49.434L41.589 63.3019L48.5983 66.6982M26.7292 66.6982H48.5983M48.5983 66.6982L66.8226 52.8303M48.5983 66.6982L39.9067 45.4718M66.8226 52.8303L57.5703 29.0567M66.8226 52.8303L39.9067 45.4718M39.9067 45.4718V29.0567M39.9067 45.4718L57.5703 29.0567M39.9067 29.0567H57.5703"
          stroke="url(#paint2_linear_2723_13455)"
          strokeOpacity="0.8"
        />
        <path
          d="M68.8458 52.8301C68.8458 53.8088 68.0611 54.5942 67.1029 54.5942C66.1446 54.5942 65.3599 53.8088 65.3599 52.8301C65.3599 51.8513 66.1446 51.0659 67.1029 51.0659C68.0611 51.0659 68.8458 51.8513 68.8458 52.8301Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M68.8458 52.8301C68.8458 53.8088 68.0611 54.5942 67.1029 54.5942C66.1446 54.5942 65.3599 53.8088 65.3599 52.8301C65.3599 51.8513 66.1446 51.0659 67.1029 51.0659C68.0611 51.0659 68.8458 51.8513 68.8458 52.8301Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M79.4998 48.302C79.4998 48.6555 79.2172 48.9341 78.8783 48.9341C78.5395 48.9341 78.2568 48.6555 78.2568 48.302C78.2568 47.9485 78.5395 47.6699 78.8783 47.6699C79.2172 47.6699 79.4998 47.9485 79.4998 48.302Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M43.6122 21.1321C43.6122 21.4856 43.3295 21.7642 42.9907 21.7642C42.6518 21.7642 42.3692 21.4856 42.3692 21.1321C42.3692 20.7786 42.6518 20.5 42.9907 20.5C43.3295 20.5 43.6122 20.7786 43.6122 21.1321Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M40.8084 29.0564C40.8084 29.4099 40.5258 29.6885 40.1869 29.6885C39.8481 29.6885 39.5654 29.4099 39.5654 29.0564C39.5654 28.7029 39.8481 28.4243 40.1869 28.4243C40.5258 28.4243 40.8084 28.7029 40.8084 29.0564Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M21.743 48.8679C21.743 49.2214 21.4603 49.5 21.1215 49.5C20.7827 49.5 20.5 49.2214 20.5 48.8679C20.5 48.5144 20.7827 48.2358 21.1215 48.2358C21.4603 48.2358 21.743 48.5144 21.743 48.8679Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M50.9017 78.8679C50.9017 79.2214 50.619 79.5 50.2802 79.5C49.9414 79.5 49.6587 79.2214 49.6587 78.8679C49.6587 78.5144 49.9414 78.2358 50.2802 78.2358C50.619 78.2358 50.9017 78.5144 50.9017 78.8679Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M58.7523 21.698C58.7523 22.0515 58.4696 22.3301 58.1308 22.3301C57.7919 22.3301 57.5093 22.0515 57.5093 21.698C57.5093 21.3445 57.7919 21.0659 58.1308 21.0659C58.4696 21.0659 58.7523 21.3445 58.7523 21.698Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M40.8084 44.9055C40.8084 45.259 40.5258 45.5376 40.1869 45.5376C39.8481 45.5376 39.5654 45.259 39.5654 44.9055C39.5654 44.552 39.8481 44.2734 40.1869 44.2734C40.5258 44.2734 40.8084 44.552 40.8084 44.9055Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M69.9671 70.3772C69.9671 70.7307 69.6844 71.0093 69.3456 71.0093C69.0068 71.0093 68.7241 70.7307 68.7241 70.3772C68.7241 70.0237 69.0068 69.7451 69.3456 69.7451C69.6844 69.7451 69.9671 70.0237 69.9671 70.3772Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M77.2572 61.3206C77.2572 61.6741 76.9745 61.9526 76.6357 61.9526C76.2968 61.9526 76.0142 61.6741 76.0142 61.3206C76.0142 60.967 76.2968 60.6885 76.6357 60.6885C76.9745 60.6885 77.2572 60.967 77.2572 61.3206Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M28.4718 66.9809C28.4718 67.9597 27.687 68.7451 26.7288 68.7451C25.7706 68.7451 24.9858 67.9597 24.9858 66.9809C24.9858 66.0022 25.7706 65.2168 26.7288 65.2168C27.687 65.2168 28.4718 66.0022 28.4718 66.9809Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M75.0138 31.3208C75.0138 32.2995 74.229 33.0849 73.2708 33.0849C72.3126 33.0849 71.5278 32.2995 71.5278 31.3208C71.5278 30.342 72.3126 29.5566 73.2708 29.5566C74.229 29.5566 75.0138 30.342 75.0138 31.3208Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M27.3502 34.1509C27.3502 35.1296 26.5655 35.915 25.6072 35.915C24.649 35.915 23.8643 35.1296 23.8643 34.1509C23.8643 33.1721 24.649 32.3867 25.6072 32.3867C26.5655 32.3867 27.3502 33.1721 27.3502 34.1509Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M59.3131 29.6225C59.3131 30.6013 58.5284 31.3867 57.5701 31.3867C56.6119 31.3867 55.8271 30.6013 55.8271 29.6225C55.8271 28.6438 56.6119 27.8584 57.5701 27.8584C58.5284 27.8584 59.3131 28.6438 59.3131 29.6225Z"
          fill="#626579"
          stroke="#626579"
        />
        <path
          d="M50.9019 66.4151C50.9019 67.7065 49.8661 68.7453 48.5982 68.7453C47.3303 68.7453 46.2944 67.7065 46.2944 66.4151C46.2944 65.1238 47.3303 64.085 48.5982 64.085C49.8661 64.085 50.9019 65.1238 50.9019 66.4151Z"
          fill="#626579"
          stroke="#626579"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2723_13455"
            x1="50"
            y1="0"
            x2="50"
            y2="100"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5C5F72" />
            <stop offset="1" stopColor="#10151B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2723_13455"
            x1="6.56355"
            y1="0"
            x2="6.56355"
            y2="1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5C5F72" />
            <stop offset="1" stopColor="#10151B" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2723_13455"
            x1="49.86"
            y1="20.8491"
            x2="49.86"
            y2="79.151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5C5F72" />
            <stop offset="1" stopColor="#10151B" />
          </linearGradient>
        </defs>
      </svg>
    </MuiIconWrapper>
  );
};
