import React from 'react';

const MillMapOverview = () => {
  return (
    <section className="hero-section">
      <p>Welcome to </p>
      <h1>Phosa Mill Map Overview Page</h1>
    </section>
  );
};
export default MillMapOverview;