import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { defaultDataTableStyles } from './DataTable.styles';

const DataTable = (props) => {
  const { rows, columns, dataTableStyles } = props;

  return (
    <MuiDataGrid
      sx={{ ...defaultDataTableStyles, ...dataTableStyles }}
      rows={rows}
      columns={columns}
      //   pageSize={5}
      //   rowsPerPageOptions={[5]}
      hideFooter
      //   hideFooterPagination
      //   hideFooterSelectedRowCount
      //   density='compact'
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      scrollbarSize={0}
    />
  );
};

export default DataTable;
