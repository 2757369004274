import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { ProcessCard } from "./components";
import TopSummary from "./components/TopSummary";
import ViewTopBar from "../../components/ViewTopBar/ViewTopBar";
import ProcessCardRegister from "../../components/ProcessCardRegister/ProcessCardRegister";
import { useSiteConfig } from "../../_shared/context/siteChangeTester.context";
import { getViewData } from "../../utils/getViewData";

import {
  drawerActive,
  drawerHover,
  lightgreen,
  white,
} from "../../constants/style";
import { paths } from "../../constants/paths";
import { useLoaderContext } from "../../_shared/context/loader.context";
import { DownArrow } from "../../icons";
import { useTimestampContext } from "../../_shared/context/timestamp.context ";

const links = [
  {
    name: "Analyze asset performance",
    link: "Asset Performance",
  },
  {
    name: "Manage integrated planning",
    link: "Integrated Ops",
  },
];

const isAdjacentIcon = (id, isLastItem, index) => {
  if (isLastItem) return false;
  return true;
};

const Home = () => {
  const { siteConfig } = useSiteConfig();
  const [data, setData] = useState();
  const { startLoader, endLoader } = useLoaderContext();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const { setTimestamp, timestampCount } = useTimestampContext();

  useEffect(() => {
    getLandingData();
  }, [siteConfig]);

  useEffect(() => {
    if (!data) { return};
    getLandingData();
  }, [timestampCount]);

  const handelRenderCoreProcess = (data) => {
    return <ProcessCard data={data} renderLine={false} isCoreProcess={true} />;
  };

  const handleRenderProcess = (process, index) => {
    return (
      <ProcessCard
        id={`processCard-${index}`}
        data={process}
        renderLine={isAdjacentIcon(
          `processCard-${index}`,
          data.processes.length - 2 === index,
          index
        )}
      />
    );
  };

  const handleNavigate = (link) => {
    history.push(link);
  };

  const getLandingData = () => {
    if (siteConfig.selectedClient) {
      startLoader();
      getViewData(
        "getLanding",
        "post",
        siteConfig.selectedClient,
        siteConfig.selectedSite,
        siteConfig.selectedTimestamp
      )
        .then((res) => {
          endLoader();
          setData(res.data);
          setTimestamp(res.data.timestamp);
        })
        .catch((error) => {
          endLoader();
          if (error.status === 500) {
            history.push("/error");
          } else if (error.status === 401) {
            oktaAuth.signOut();
          } else if (error.status === 403) {
            history.push("/unauthorized");
          }
        });
    }
  }

  return (
    <Grid
      className="Home"
      container
      columns={20}
      spacing="20px"
      sx={{ width: "100%" }}
    >
      <Grid item xs={20}>
        <ViewTopBar>
          <Stack direction="row" sx={{ alignItems: "flex-end" }}>
            <Typography
              sx={{
                color: white,
                fontSize: "21px",
                fontWeight: 700,
                marginRight: "5px",
              }}
            >
              PHOSA -
            </Typography>
            <Typography sx={{ color: white, fontSize: "21px" }}>
              Unlocking the full potential of your mining operations
            </Typography>
          </Stack>
        </ViewTopBar>
      </Grid>
      <Grid item xs={20}>
        {data && data.summary && <TopSummary data={data.summary} />}
      </Grid>
      <Grid item xs={20}>
        <Stack
          direction="row"
          sx={{ position: "relative", marginBottom: "10px" }}
        >
          {data && data.processes && (
            <ProcessCardRegister
              processes={data.processes.filter((item, index) => index !== 0)}
              coreProcess={data.processes[0]}
              renderProcess={handleRenderProcess}
              renderCoreProcess={handelRenderCoreProcess}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "80px",
          }}
        >
          <span
            style={{
              height: "10px",
              width: "10px",
              borderRadius: "50%",
              border: `3px solid ${lightgreen}`,
              marginRight: "10px",
            }}
          />
          <Typography sx={{ color: white, fontSize: "12px" }}>
            PHOSA Activated
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={20}>
        <Stack
          spacing="20px"
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          {links.map((item, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                onClick={() => handleNavigate(paths[item.link])}
                sx={{
                  cursor: "pointer",
                  color: white,
                  borderRadius: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0",
                  width: "100%",
                  maxWidth: "393px",
                  flex: "1 1 auto",
                  border: "1px solid #464959",
                  background:
                    "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  "&:hover": {
                    background: drawerHover,
                  },
                  "&:active": {
                    background: drawerActive,
                  },
                }}
              >
                <DownArrow />
                <Typography
                  sx={{ marginLeft: "10px", fontSize: "16px", fontWeight: 700 }}
                >
                  {item.name}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Home;
