import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { white } from "../../../constants/style";
import Divider from "../../../components/Divider/Divider";

const StackSection = ({ data }) => {
  return (
    <Stack
      direction="row"
      divider={
        <Divider orientation="vertical" dividerStyles={{ margin: "0 20px" }} />
      }
    >
      {data &&
        data.map((item, index) => {
          return (
            <Stack
              key={index}
              sx={{
                flexGrow: 1,
                height: "80px",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: white, fontSize: "14px" }}>
                {item.Display_Name}
              </Typography>
              <Stack direction="row" sx={{ alignItems: "flex-end" }}>
                <Typography
                  sx={{ color: white, fontSize: "25px", marginRight: "2px" }}
                >
                  {item.Metric_Value}
                </Typography>
                <Typography
                  sx={{ color: white, fontSize: "15px", marginBottom: "5px" }}
                >
                  {item.Display_UOM}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
};

export default StackSection;
