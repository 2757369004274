import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TopSummary from "./components/TopSummary";
import Accordion from "./components/Accordion";
import { GrindingIconDark } from "../../icons";
import { gray1, white } from "../../constants/style";
import grindingJson from "../../mockData/grinding.json";
import ViewTopBar from "../../components/ViewTopBar/ViewTopBar";

const Grinding = () => {
  const [expanded, setExpanded] = useState({});

  const handleExpand = (item) => {
    const newExpanded = { ...expanded };

    if (newExpanded[item]) {
      delete newExpanded[item];
    } else {
      newExpanded[item] = true;
    }

    setExpanded(newExpanded);
  };

  const handleCollapseToggle = () => {
    if (!Object.keys(expanded).length) {
      const newExpandedState = {};
      grindingJson.data.Systems.forEach((item) => {
        newExpandedState[item.Display_Name] = true;
      });

      setExpanded(newExpandedState);
    } else {
      setExpanded({});
    }
  };

  return (
    <Grid
      className="Grinding"
      container
      columns={20}
      spacing="20px"
      sx={{ width: "100%" }}
    >
      <Grid item xs={20}>
        <ViewTopBar>
          <GrindingIconDark />
          <Typography
            sx={{
              color: white,
              fontSize: "21px",
              fontWeight: 700,
              marginLeft: "15px",
            }}
          >
            Grinding
          </Typography>
        </ViewTopBar>
      </Grid>
      <Grid item xs={20}>
        <TopSummary {...grindingJson.data.Grinding.Top_Summary} />
      </Grid>
      <Grid item xs={20}>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: white, fontWeight: 700, fontSize: "21px" }}>
            Details per grinding system
          </Typography>
          <Stack
            direction="row"
            sx={{ cursor: "pointer", userSelect: "none" }}
            onClick={handleCollapseToggle}
          >
            <Typography
              sx={{ color: white, marginRight: "10px", fontSize: "17px" }}
            >
              {Object.keys(expanded).length ? "Collapse All " : "Expand All"}
            </Typography>
            {Object.keys(expanded).length ? (
              <KeyboardArrowUpIcon sx={{ color: gray1 }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: gray1 }} />
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={20}>
        {grindingJson.data.Systems.map((system, index) => {
          return (
            <Accordion
              key={index}
              data={system}
              handleExpand={handleExpand}
              expanded={expanded}
              isLast={grindingJson.data.Systems.length - 1 === index}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Grinding;
