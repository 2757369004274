import React from "react";
import Highcharts from "highcharts";
import MoreCharts from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import RoundedCorners from "highcharts-rounded-corners";

HighchartsMore(Highcharts);
RoundedCorners(Highcharts);
MoreCharts(Highcharts);

const Chart = ({ data }) => {
  return (
    <div className="ChartContainer" style={{ width: "100%", height: "100%" }}>
      {data ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={data}
          allowChartUpdate={true}
        />
      ) : null}
    </div>
  );
};

export default Chart;
