import { Stack, Typography } from "@mui/material";
import React from "react";
import { Watch } from "react-loader-spinner";

const WorkInProgress = (props) => {
  return (
    <Stack gap="2rem" alignItems="center" justifyContent="center" sx={{width: "100%", height:"100%"}}>
      <Watch
        height="80"
        width="80"
        radius="48"
        color="#218838"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      <Typography sx={{color: "#fff"}}>Work in Progress</Typography>
    </Stack>
  );
};

export default WorkInProgress;
