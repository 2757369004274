import React from "react";
import Stack from "@mui/material/Stack";
import { cardBorder, dataPillBackground } from "../../../constants/style";

const Pill = ({ styles, children }) => {
  return (
    <Stack
      sx={{
        height: "150px",
        width: "100%",
        border: cardBorder,
        background: dataPillBackground,
        borderRadius: "15px",
        ...styles,
      }}
    >
      {children && children}
    </Stack>
  );
};

export default Pill;
