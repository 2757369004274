import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import BCGHenSansRegular from '../fonts/BCGHenSansRegular.ttf';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Henderson BCG Sans',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  components : {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Henderson BCG Sans',
          fontDisplay: 'swap',
          fontStyle: 'normal',
          src: `local('Henderson BCG Sans'), url(${BCGHenSansRegular}) format('ttf')`
        },
        '*' : {
          fontFamily: 'Henderson BCG Sans !important'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const BaseTheme = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children && children}
    </ThemeProvider>
  );
};

export default BaseTheme;