import React from "react";
import MuiIconWrapper from "./MuiIconWrapper";

export const NavigationArrowVertical = () => {
  return (
    <MuiIconWrapper size={6} height={44}>
      <svg
        width="6"
        height="44"
        viewBox="0 0 6 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 44L5.88675 39H0.113249L3 44ZM3 -9.53674e-07L0.113249 5H5.88675L3 -9.53674e-07ZM3.5 39.5L3.5 4.5H2.5L2.5 39.5H3.5Z"
          fill="#B3B4BA"
        />
      </svg>
    </MuiIconWrapper>
  );
};
