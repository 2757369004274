import { lightgreen, gray2, red, white, yellow1 } from "../constants/style";

const greenStatus = {
  good: true,
  pass: true,
  green: true,
  1: true,
};

const yellowStatus = {
  warn: true,
  warning: true,
  yellow: true,
  2: true,
};

const redStatus = {
  error: true,
  fail: true,
  issue: true,
  red: true,
  3: true,
};

const grayStatus = {
  disabled: true,
  "n\\a": true,
  "not available": true,
  "not applicable": true,
  "-1": true,
};

export const getStatusColor = (status, showStatus = true) => {
  if (!showStatus) return white;

  if (typeof status === "string") status = status.toLocaleLowerCase();

  if (greenStatus[status]) {
    return {
      background: lightgreen,
      border: lightgreen,
      color: lightgreen,
    };
  }

  if (yellowStatus[status]) {
    return {
      background: yellow1,
      border: yellow1,
      color: yellow1,
    };
  }

  if (redStatus[status]) {
    return {
      background: red,
      border: red,
      color: red,
    };
  }

  if (grayStatus[status]) {
    return {
      background: gray2,
      border: gray2,
      color: gray2,
    };
  }

  return {
    background: white,
    border: white,
    color: white,
  };
};
