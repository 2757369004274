import React from "react";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { drawerActive, drawerHover, white } from "../../../constants/style";
import { Pill } from "../components";
import { abbreviateNumber, getStatusColor } from "../../../utils";
import { DownArrow } from "../../../icons";

const statusMessages = {
  baseline_revenue: {
    status: "n/a",
    status_message: "Without PHOSA",
  },
  fpv: {
    status: "Good",
    status_message: "Calculated by PHOSA",
  },
};

const isOptimal = (data) => {
  if (data.toLowerCase().match(new RegExp("potential value"))) {
    return true;
  }
  if (data.toLowerCase().match(new RegExp("optim"))) {
    return true;
  }
  return false;
};

const CTA = ({ data }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/app/asset_strategy");
  };

  return (
    <Stack
      spacing="20px"
      direction="row"
      justifyContent="center"
      sx={{ marginBottom: "20px", width: "100%" }}
    >
      {data && (
        <Pill
          styles={{
            padding: "20px",
            height: "auto",
            background:
              "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
          }}
        >
          <Typography sx={{ color: white, fontSize: "16px", fontWeight: 700 }}>
            Full Potential Value
          </Typography>
          <Stack
            direction="row"
            sx={{
              color: white,
            }}
          >
            {data.map((detail, index) => {
              return (
                <Stack
                  direction="row"
                  key={index}
                  sx={{
                    borderRadius: "15px",
                    padding: `10px 10px 10px ${
                      isOptimal(detail.display_name) ? "30px" : "0"
                    }`,
                    justifyContent: "space-between",
                    flex: "1 1 auto",
                    background: isOptimal(detail.display_name)
                      ? "#252933"
                      : "transparent",
                  }}
                >
                  <Stack>
                    <Typography sx={{ fontSize: "14px" }}>
                      {detail.display_name}
                    </Typography>
                    <Stack direction="row" sx={{ color: white }}>
                      <Typography sx={{ fontSize: "35px", fontWeight: 700 }}>
                        {detail.display_uom}
                      </Typography>
                      <Typography sx={{ fontSize: "35px", fontWeight: 700 }}>
                        {abbreviateNumber(detail.attribute_value, 1, true)}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: getStatusColor(
                          statusMessages[detail.attribute_name].status
                        ).color,
                      }}
                    >
                      {statusMessages[detail.attribute_name].status_message}
                    </Typography>
                  </Stack>
                  {isOptimal(detail.display_name) && (
                    <Stack
                      direction="row"
                      onClick={handleClick}
                      sx={{
                        marginLeft: "30px",
                        border: "1px solid #464959",
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                        borderRadius: "10px",
                        padding: "0 20px",
                        alignItems: "center",
                        cursor: "pointer",
                        background:
                          "linear-gradient(180deg, #5C5F72 0%, rgba(41, 43, 55, 0) 154.27%)",
                        "&:hover": {
                          background: drawerHover,
                        },
                        "&:active": {
                          background: drawerActive,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          paddingRight: "10px",
                        }}
                      >
                        <DownArrow />
                      </Box>
                      <Typography
                        sx={{ color: white, fontSize: "16px", fontWeight: 700 }}
                      >
                        Assess Value
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Pill>
      )}
    </Stack>
  );
};

export default CTA;
