import React, { createContext, useContext, useState } from 'react';

const defaultVal = null;
// const defaultVal = new Date().toISOString();

export const TimestampContext = createContext(defaultVal);
export const useTimestampContext = () => useContext(TimestampContext);

const TimestampProvider = ({ children }) => {
  const [timestamp, setTimestamp] = useState(defaultVal);
  const [timestampCount, setTimestampCount] = useState(1);

  const updateTimestamp = () => {
    setTimestampCount((prev) => prev + 1);
  }

  const value = {
    timestampCount,
    timestamp,
    setTimestamp,
    updateTimestamp
  }

  return (
    <TimestampContext.Provider value={value}>{children && children}</TimestampContext.Provider>
  )
}


export default TimestampProvider;